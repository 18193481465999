<template>
    <div class="mx-auto col-12">
        <h1 class="mt-5 display-4 text-uppercase text-muted">404</h1>
        <p class="text-muted">Looks like this page doesn't exist</p>
        <p><router-link to="/" class="dark-green">back to the dashboard</router-link></p>
    </div>
</template>
<script>
export default {
    name: 'FourOhFour',

    data: function () {
        return {};
    },
    beforeRouteEnter: function (to, from, next) {
        next((vm) => {
            if (vm.$sentry) {
                vm.$sentry.captureException(
                    new Error(
                        `404 Not Found when transitioning from: '${from.path}' to: '${to.path}'`
                    )
                );
            }
        });
    },
};
</script>
<style lang="scss" scoped>
.dark-green {
    color: $dark-green;
}
</style>
