<template>
    <div class="page_main_col col-12 offset-lg-1 col-lg-10">
        <div id="reload-button" class="mx-auto">
            <b-button variant="primary" @click="reloadPending">
                Reload Pending Content Overrides
            </b-button>
        </div>
        <b-container
            v-for="override in pending"
            v-if="override.doneLoading"
            :key="override.pk"
            class="paper content-override">
            <b-row>
                <b-col cols="9">
                    <b-row>
                        <b-col sm="4">
                            <p><strong>Domain:</strong> {{ override.domain }}</p>
                            <p>
                                <strong>tags:</strong>
                                <b-badge
                                    v-for="tag in override.tags"
                                    :key="tag"
                                    class="tag"
                                    variant="primary">
                                    {{ tag }}
                                </b-badge>
                            </p>
                        </b-col>
                        <b-col>
                            <p><strong>Agent:</strong> {{ override.created_by }}</p>
                            <p>
                                <strong>Timestamp:</strong>
                                {{ formatDate(override.created_at) }}
                                ({{ fromNow(override.created_at) }} ago)
                            </p>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col class="right">
                    <b-button
                        :href="`https://${override.domain}?editing&previewPending=true`"
                        target="_blank"
                        variant="outline-secondary">
                        Preview
                    </b-button>
                </b-col>
            </b-row>
            <hr />
            <b-row v-if="isBanner(override)">
                <b-col sm="12" class="center margin-top-small">
                    <h5>Current Banner Image</h5>
                    <b-img
                        id="current-banner-image"
                        :src="override.currentImage"
                        fluid-grow
                        height="153"
                        width="714" />
                </b-col>
                <b-col sm="12" class="center margin-top-small">
                    <h5>New Banner Image</h5>
                    <b-img id="new-banner-image" :src="override.value" fluid-grow />
                </b-col>
            </b-row>
            <b-row v-else>
                <b-col sm="4" class="center">
                    <h5>{{ override.amitImage ? '' : 'No ' }}AMIT Photo</h5>
                    <b-img
                        v-if="override.amitImage"
                        :src="override.amitImage"
                        fluid-grow
                        width="150"
                        height="200"
                        @error.native="override.amitImage = false" />
                </b-col>
                <b-col sm="4" class="center">
                    <h5>{{ override.currentImage ? '' : 'No ' }}Current Photo</h5>
                    <b-img
                        v-if="override.currentImage"
                        :src="override.currentImage"
                        fluid-grow
                        height="200"
                        width="150"
                        @error.native="override.currentImage = false" />
                </b-col>
                <b-col sm="4" class="center">
                    <h5>New Photo</h5>
                    <b-img :src="override.value" fluid-grow />
                </b-col>
            </b-row>
            <b-row class="right options">
                <b-col>
                    <b-input-group>
                        <b-input v-model="message" class="message" placeholder="optional message" />
                        <b-input-group-append>
                            <b-button variant="primary" @click="process(override.pk, 'approved')">
                                Approve
                            </b-button>
                            <b-button variant="danger" @click="process(override.pk, 'denied')">
                                Deny
                            </b-button>
                        </b-input-group-append>
                    </b-input-group>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
import _ from 'lodash';
import axios from 'axios';
import { format, formatDistance } from 'date-fns';
import { indexOf } from 'lodash';

import { IMAGE_BUCKET_HOST, EPHEMERA_HOST } from '@/utils/constants.js';

export default {
    name: 'ApprovalQueue',

    data() {
        return {
            bread: [
                { text: 'Dashboard', to: '/' },
                { text: 'Admin', to: '/admin' },
                { text: 'Approval Queue', to: '/admin/approvalqueue', active: true },
            ],
            message: '',
            pending: [],
        };
    },
    mounted() {
        this.reloadPending();
    },
    methods: {
        formatDate(date) {
            return format(Date.parse(date), 'MM/dd/yyyy hh:mmb');
        },
        fromNow(then) {
            return formatDistance(Date.parse(then), new Date());
        },
        getAid(contentOverride) {
            return new Promise((resolve, reject) => {
                if (
                    contentOverride.tags.includes('agent') ||
                    contentOverride.tags.includes('banner')
                ) {
                    axios.get(`/backend/api/frontend/queue/${contentOverride.pk}/`).then((r) => {
                        resolve(r.data.aid);
                    });
                } else {
                    resolve(contentOverride.subkey.split('.')[0]);
                }
            });
        },
        getCurrentImage(pendingCO) {
            const { pk, domain, key, branch } = pendingCO;

            return axios.get(`/backend/api/frontend/overrides/${domain}/`).then((resp) => {
                for (let co of _.get(resp.data, branch, [])) {
                    if (co.status === 'approved' && co.key === key) {
                        return co.value;
                    }
                }
                return null;
            });
        },
        isBanner(co) {
            return indexOf(co.tags, 'banner') > -1;
        },
        process(pk, status) {
            const reason = this.message === '' ? null : this.message;
            const data = { status, reason };

            axios
                .put(`/backend/api/frontend/queue/${pk}/`, data)
                .then((r) => {
                    this.$store.commit('add_alert', {
                        message: 'Pending Content Override processed',
                        varient: 'success',
                    });
                    this.reloadPending();
                })
                .catch((err) => {
                    this.$store.commit('add_alert', {
                        message: 'there was a problem processing your request',
                        varient: 'danger',
                    });
                });
        },
        reloadPending() {
            axios.get(`/backend/api/frontend/queue`).then((resp) => {
                const contentOverrides = resp.data.map((contentOverride) => {
                    return this.getCurrentImage(contentOverride).then((currentUrl) => {
                        contentOverride.currentImage = currentUrl;
                        let isBanner = this.isBanner(contentOverride);
                        if (isBanner && currentUrl === null) {
                            contentOverride.currentImage = `https://${contentOverride.domain}/map/header`;
                        }
                        this.getAid(contentOverride).then((aid) => {
                            const path = `amit_images/${aid}/AgentFormalColorPhoto.jpg`;
                            const url = `${IMAGE_BUCKET_HOST}/${path}`;
                            const width = 200;
                            contentOverride.amitImage = `${EPHEMERA_HOST}/imgr/${width}x0/${url}`;

                            if (contentOverride.currentImage === null) {
                                contentOverride.currentImage = contentOverride.amitImage;
                            }
                            this.$set(contentOverride, 'doneLoading', true);
                        });
                        return contentOverride;
                    });
                });
                Promise.all(contentOverrides).then((cos) => (this.pending = cos));
            });
        },
    },
};
</script>

<style lang="scss" scoped>
#reload-button {
    width: 300px;
    margin-bottom: 20px;

    button {
        width: 300px;
    }
}

p {
    margin-bottom: 0em;
}

.content-override {
    padding: 15px;
    margin-bottom: 20px;

    .right {
        text-align: right;
    }

    .center {
        text-align: center;
    }

    .margin-top-small {
        margin-top: 10px;
    }
}

.options {
    margin-top: 15px;
}

.page_main_col {
    padding: 15px;
    text-align: left;
}

.tag {
    margin: 1px;
}
</style>
