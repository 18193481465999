<template>
    <div>
        <div class="container">
            <h3>Link Generator for Tracking Codes/Campaign IDs</h3>
            <p>
                This section allows you to generate a new tracking link which you can use in your
                online marketing campaigns.
            </p>

            <div class="row">
                <div class="col-xs-12 col-sm-6 col-md-3">
                    <label for="domain-name-select">Domain Name</label>
                    <b-form-select
                        id="domain-name-select"
                        v-model="select_domain"
                        :options="available_domains">
                    </b-form-select>
                </div>
                <div class="col-xs-12 col-sm-6 col-md-3">
                    <label for="landing-page-select">Landing Page</label>
                    <b-form-select id="landing-page-select" v-model="select_traffic">
                        <option :value="null">Select a Landing Page</option>
                        <option value="/homepage">EM Homepage</option>
                        <option value="/auto">Auto QLP</option>
                        <option value="/home">Home QLP</option>
                        <option value="/tenants">Tenants QLP</option>
                        <option value="/condo">Condo QLP</option>
                    </b-form-select>
                </div>
                <div class="col-xs-12 col-sm-6 col-md-3">
                    <label for="traffic-source-select">Traffic Source</label>
                    <b-form-select id="traffic-source-select" v-model="select_source">
                        <option :value="null">Select a Traffic Source</option>
                        <option value="AT">Auto Trader</option>
                        <option value="BD">Bing/Yahoo Ads Desktop</option>
                        <option value="BM">Bing/Yahoo Ads Mobile</option>
                        <option value="CG">Car Gurus</option>
                        <option value="DD">Desktop Display</option>
                        <option value="ET">Email and Email Template</option>
                        <option value="FD">Facebook Desktop</option>
                        <option value="FM">Facebook Mobile</option>
                        <option value="FN">Facebook Newsfeed</option>
                        <option value="FP">Facebook Promoted Post</option>
                        <option value="GD">Google Adwords Desktop</option>
                        <option value="GM">Google Adwords Mobile</option>
                        <option value="HU">Hulu</option>
                        <option value="EM">In-Book Email Marketing</option>
                        <option value="IY">Internet Yellow Pages Ads</option>
                        <option value="KJ">Kijiji</option>
                        <option value="LD">LinkedIn Desktop</option>
                        <option value="LM">LinkedIn Mobile</option>
                        <option value="LC">Local Campaigns</option>
                        <option value="ML">Metroland Media</option>
                        <option value="MD">Mobile Display</option>
                        <option value="PD">Pandora</option>
                        <option value="SP">Spotify</option>
                        <option value="TD">Twitter Desktop</option>
                        <option value="TM">Twitter Mobile</option>
                        <option value="WN">Weathernetwork.ca</option>
                        <option value="YD">Yelp (Desktop)</option>
                        <option value="YM">Yelp (Mobile)</option>
                        <option value="YT">YouTube</option>
                        <option value="U1">Other/Undefined 1</option>
                        <option value="U2">Other/Undefined 2</option>
                        <option value="U3">Other/Undefined 3</option>
                        <option value="U4">Other/Undefined 4</option>
                        <option value="U5">Other/Undefined 5</option>
                    </b-form-select>
                </div>
                <div class="col-xs-12 col-sm-6 col-md-3">
                    <label for="campaign-code-input">Campaign Code</label>
                    <input
                        id="campaign-code-input"
                        v-model="select_code"
                        type="number"
                        class="form-control"
                        maxlength="3"
                        minlength="3"
                        placeholder="001 - 699"
                        pattern="\d{3}"
                        min="001"
                        max="699"
                        required />
                </div>
            </div>

            <div class="row my-3 justify-content-end">
                <div class="col-12 col-sm-8 col-lg-10">
                    <label for="copy">Generated Link</label>
                    <b-form-input id="copy" class="qlp-link" :value="fullUrlString" readonly>
                    </b-form-input>
                </div>
                <div class="col-xs-12 col-sm-4 col-lg-2 mt-auto">
                    <b-button variant="primary" block @click="copyToClipboard()"
                        >Copy&nbsp;Link</b-button
                    >
                </div>
            </div>
        </div>
        <div class="mt-4 mb-3 mx-3">
            <a
                v-b-toggle.collapse-1
                href="javascript:void(0)"
                class="d-flex w-100 justify-content-between"
                >What is a Link Generator?<Icon :i="collapse_1 ? 'chevron-down' : 'chevron-up'" />
            </a>
            <b-collapse id="collapse-1" class="mt-2" @input="collapse_1 = !collapse_1">
                <b-card>
                    <b-card-text>
                        Agents and their digital advertising vendors are highly encouraged to use
                        campaign IDs for their EM domain and/or Quote Landing page URLs in order to
                        track different digital advertising campaigns and tactics. If campaign IDs
                        are not set up and attached to the URL properly, detailed campaign reporting
                        cannot be provided.
                    </b-card-text>
                    <b-card-text>
                        There are two options available for you to use to generate a campaign ID
                        (aka tracking code) for your EM domain/Quote Landing page URL.
                    </b-card-text>

                    <b-card-text>
                        Use the Generate a Tracking Link tool above, or use the Desjardins EM/QLP
                        Reference and Campaign ID Set-Up Guide.
                    </b-card-text>

                    <b-card-text>
                        Once you have the domain URL with the campaign ID code generated, use it to
                        link to your ad/marketing campaign and provide it to your marketing vendor
                        or save the URL somewhere until you are ready to use it. (If you are using
                        the Link Generator tool above, the URL with campaign ID code will be
                        displayed at the bottom of the Link Generator tool.)
                    </b-card-text>
                </b-card>
            </b-collapse>
        </div>
        <div class="mx-3">
            <a
                v-b-toggle.collapse-2
                href="javascript:void(0)"
                class="d-flex w-100 justify-content-between"
                >What do I do with the link I've generated?
                <Icon :i="collapse_2 ? 'chevron-down' : 'chevron-up'" />
            </a>
            <b-collapse id="collapse-2" class="mt-2" @input="collapse_2 = !collapse_2">
                <b-card>
                    <b-card-text>
                        You can now incorporate the completed URL into your digital advertising
                        campaigns. The metrics, available to you on
                        <a href="https://mydadomain.com/campaigns">www.mydadomain.com/campaigns</a>,
                        will help you track how well your digital marketing efforts are performing.
                    </b-card-text>
                </b-card>
            </b-collapse>
        </div>
    </div>
</template>
<script>
export default {
    name: 'CampaignTools',
    data() {
        return {
            select_domain: null,
            select_traffic: null,
            select_source: null,
            select_code: null,

            collapse_1: true,
            collapse_2: true,
        };
    },
    computed: {
        available_domains() {
            let options = [
                {
                    value: null,
                    text: 'Select a Domain',
                },
            ];

            if (this.$store.getters.domains) {
                this.$store.getters.domains.forEach((domain) => options.push(domain.name));
            }
            return options;
        },
        fullUrlString() {
            let domainDisplay = this.select_domain ? this.select_domain : '[One of your Domains]';

            let landingDisplay = '';
            if (this.select_traffic === null) {
                landingDisplay = '/quote' + '/[Landing Page]';
            } else if (this.select_traffic === '/homepage') {
                landingDisplay = '';
            } else {
                landingDisplay = '/quote' + this.select_traffic;
            }

            let trafficDisplay = this.select_source ? this.select_source : '[ Traffic Source ]';
            let campaignCodeDisplay = this.select_code ? this.select_code : '001';

            return `https://${domainDisplay}${landingDisplay}/?cmpid=${trafficDisplay}${campaignCodeDisplay}`;
        },
    },
    methods: {
        copyToClipboard() {
            let copyText = document.getElementById('copy');
            copyText.focus();
            copyText.select();
            document.execCommand('copy');
        },
    },
};
</script>
<style></style>
