export default {
    get: [
        {
            path: '/',
            domain_name: 'roundbacon.com',
            cmpid: '123',
            visits: 58,
            page_views: 258,
            quotestarts: 0,
            phone_clicks: 0,
            sms_clicks: 0,
            map_clicks: 0,
            google_review_clicks: 0,
            contactforms: 0,
        },
        {
            path: '/',
            domain_name: 'myauroraagent.com',
            cmpid: '123',
            visits: 42,
            page_views: 94,
            quotestarts: 1,
            phone_clicks: 0,
            sms_clicks: 0,
            map_clicks: 0,
            google_review_clicks: 0,
            contactforms: 0,
        },
        {
            path: '/',
            domain_name: 'insurance-man.com',
            cmpid: '123',
            visits: 8,
            page_views: 8,
            quotestarts: 0,
            phone_clicks: 0,
            sms_clicks: 0,
            map_clicks: 0,
            google_review_clicks: 0,
            contactforms: 0,
        },
        {
            path: '/testScript/',
            domain_name: 'roundbacon.com',
            cmpid: '123',
            visits: 2,
            page_views: 2,
            quotestarts: 0,
            phone_clicks: 0,
            sms_clicks: 0,
            map_clicks: 0,
            google_review_clicks: 0,
            contactforms: 0,
        },
        {
            path: '/',
            domain_name: 'roundbacon2.com',
            cmpid: '123',
            visits: 1,
            page_views: 1,
            quotestarts: 0,
            phone_clicks: 0,
            sms_clicks: 0,
            map_clicks: 0,
            google_review_clicks: 0,
            contactforms: 0,
        },
        {
            path: '/quote/auto/',
            domain_name: 'roundbacon.com',
            cmpid: '123',
            visits: 2,
            page_views: 5,
            quotestarts: 0,
            phone_clicks: 0,
            sms_clicks: 0,
            map_clicks: 0,
            google_review_clicks: 0,
            contactforms: 0,
        },
    ],
};
