<template>
    <div class="c-card-header" :class="{ is_default: card.is_default, error: error }">
        <p v-if="error" class="medium-text text-danger">{{ error }}</p>

        <div class="form-row justify-content-between align-items-baseline">
            <div class="form-group px-2 address">
                <p>
                    <span class="small-text">{{ card.billing_address || 'Address not set' }}</span>
                </p>
                <p v-if="card.billing_address_2">{{ card.billing_address_2 }}</p>
            </div>
            <b-badge v-if="card.is_default" href="#" variant="success">Default</b-badge>
        </div>
        <div class="form-row px-2">
            <p class="p1 mt-2">
                <span class="small-text">
                    {{ card.billing_city || 'City not set' }},
                    {{ card.billing_state || 'State not set,' }}
                    {{ card.billing_zip || 'Zip code not set' }}
                </span>
            </p>
        </div>
        <div class="form-row px-2">
            <div class="form-group1">
                <span class="large-text" v-if="card.masked_card_number">{{
                    card.masked_card_number
                }}</span>
                <span class="large-text" v-else>
                    <i>no card number on file</i>
                </span>
                <span class="large-text" v-if="card.card_type in prefixes">
                    {{ ' ' }}<Icon :i="prefixes[card.card_type]" prefix="fab" />
                </span>
            </div>
        </div>
        <div class="form-row px-2">
            <div class="form-group2">
                <p>
                    <span class="medium-text">
                        {{ card.first_name || 'First name not set' }}
                        {{ card.last_name || 'Last name not set' }}
                    </span>
                </p>
            </div>
            <div class="form-group2 ml-auto">
                <p>
                    <span class="medium-text"
                        >{{ card.expiration_month }}/{{ card.expiration_year }}</span
                    >
                </p>
            </div>
        </div>

        <div v-if="saving" class="d-flex justify-content-end align-items-center px-3">
            <Icon i="spinner" spin />&nbsp;Saving
        </div>
        <div v-else class="btn-group d-flex justify-content-end">
            <b-button variant="outline-secondary" @click="$emit('edit', card.id)">Edit</b-button>

            <b-button @click="setDefault" variant="outline-secondary" :disabled="card.is_default"
                >{{ card.is_default ? '' : 'Set as ' }}Default</b-button
            >
            <b-button variant="outline-secondary" @click="showModal" :disabled="deleting">
                <Icon v-if="deleting" i="spinner" spin />
                {{ deleting ? '' : 'Delete' }}
            </b-button>
            <b-modal
                centered
                v-model="modalShow"
                title="Delete card"
                :ok-disabled="card.is_default"
            >
                <div v-if="!card.is_default" class="modal-card-details">
                    <p class="small-text">{{ card.billing_address || 'Address not set' }}</p>
                    <p v-if="card.billing_address_2">{{ card.billing_address_2 }}</p>
                    <p class="small-text">
                        {{ card.billing_city || 'City not set' }},
                        {{ card.billing_state || 'State not set,' }}
                        {{ card.billing_zip || 'Zip code not set' }}
                    </p>
                    <p class="medium-text">
                        {{ card.first_name || 'First name not set' }}
                        {{ card.last_name || 'Last name not set' }}
                    </p>

                    <p class="medium-text">{{ card.masked_card_number }}</p>
                </div>

                <p v-else class="medium-text">
                    This is your default payment method. Please select or create a different default
                    payment method before deleting this one.
                </p>

                <div slot="modal-backdrop" class="modal-backdrop"></div>
                <div slot="modal-footer" class="modal-footer">
                    <p v-if="!card.is_default">Delete this card?</p>
                    <b-button
                        variant="secondary"
                        size="sm"
                        v-if="!card.is_default"
                        @click="hideModal"
                        style="margin-left: auto"
                        >Cancel</b-button
                    >
                    <b-button
                        :variant="card.is_default ? 'primary' : 'danger'"
                        size="sm"
                        @click="card.is_default ? hideModal() : handleOk()"
                        >{{ card.is_default ? 'Ok' : 'Delete' }}</b-button
                    >
                </div>
            </b-modal>
        </div>
    </div>
</template>
<script>
import axios from 'axios';

export default {
    name: 'CreditCard',

    props: {
        card: Object,
        reload: Function,
    },
    data() {
        return {
            show_info: false,
            show_editor: false,
            saving: false,
            deleting: false,
            error: false,
            ask_delete: false,
            modalShow: false,
            prefixes: {
                visa: 'cc-visa',
                master: 'cc-mastercard',
                discover: 'cc-discover',
                american_express: 'cc-amex',
                diners_club: 'cc-diners-club',
                jcb: 'cc-jcb',
            },
        };
    },
    computed: {},
    methods: {
        setDefault() {
            const account_number = this.$store.state.current_account.account_number;
            this.saving = true;
            this.show_info = false;
            this.show_editor = false;
            axios
                .put(`/backend/api/frontend/accounts/${account_number}/payment/${this.card.id}/`, {
                    card: { make_default: true },
                })
                .then((resp) => {
                    return this.reload();
                })
                .then(() => {
                    this.saving = false;
                });
        },
        showModal() {
            this.modalShow = true;
        },
        hideModal() {
            this.modalShow = false;
        },
        handleOk() {
            // Trigger submit handler
            this.onDelete();
        },
        onDelete() {
            const account_number = this.$store.state.current_account.account_number;
            this.deleting = true;
            this.modalShow = false;
            axios
                .delete(`/backend/api/frontend/accounts/${account_number}/payment/${this.card.id}/`)
                .then((resp) => {
                    return this.reload();
                })
                .catch((error) => {
                    console.error(error);
                    this.deleting = false;
                    this.error = 'There was a problem deleting this card. Please try again later.';
                });
        },
    },
};
</script>

<style lang="scss" scoped>
.c-card-header {
    position: relative;
    border-radius: 25px;
    max-width: 435px;
    width: 100%;
    height: auto;
    background-color: $background-base;
    vertical-align: top;
    display: flex;
    flex-direction: column;
    min-height: 239px;
    margin: 1rem;
    padding: 1rem 1.5rem;
    align-self: center;

    @media screen and (min-width: 1200px) {
        width: calc(50% - 2rem);
    }
}
.paper {
    z-index: 3;
    width: 100%;
    align-items: center;
}
.is_default {
    border: 1px solid $primary;
}
.error {
    border: 1px solid $danger;
}
.form-row1 {
    position: relative;
}
.form-group {
    padding-top: 5px;
    margin-bottom: 0;
}
.form-group1 {
}
.form-group2 {
}
.address {
    p {
        margin: 0;
    }
}
.del-def {
    height: max-content;
    width: 315px;
    text-align: center;
}
.small-text {
    font-size: 1rem;
}
.medium-text {
    font-size: 1.125rem;
}
.large-text {
    font-size: 1.8rem;
}
.modal-card-details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.modal-backdrop {
    opacity: 0.5;
}
.modal-footer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
    padding: 0;
    border: none;
}
</style>
