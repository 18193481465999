import { startOfDay, subDays } from 'date-fns';

import UserActive from './users/active';
import AccountId from './accounts/id';
import AccountBillingHistory from './accounts/id/billing/history';
import AccountStatementsStatus from './accounts/id/statements/status';
import AccountNotifications from './accounts/id/notifications';
import AccountPayment from './accounts/id/payment';
import AccountStats from './accounts/id/stats';
import AccountCampaignStats from './accounts/id/campaignStats';
import AccountTeam from './accounts/id/team';

const today = startOfDay(new Date());
const start = subDays(today, 30);

const dashboardUrl = `/backend/api/frontend/accounts/468/stats/?graph=dashboard&start=${start
    .toISOString()
    .slice(0, 10)}&end=${today.toISOString().slice(0, 10)}`;
const indepthUrl = `/backend/api/frontend/accounts/468/stats/?graph=indepth&target=roundbacon.com&start=${start
    .toISOString()
    .slice(0, 10)}&end=${today.toISOString().slice(0, 10)}`;
const camppaignsUrl = `/backend/api/frontend/accounts/468/stats/?graph=campaigns_view&start=${start
    .toISOString()
    .slice(0, 10)}&end=${today.toISOString().slice(0, 10)}`;

const mockApi = {
    '/backend/api/frontend/accounts/468/': AccountId,
    '/backend/api/frontend/accounts/468/billing/history/?page=1': AccountBillingHistory,
    '/backend/api/frontend/accounts/468/notifications/': AccountNotifications,
    '/backend/api/frontend/accounts/468/payment/': AccountPayment,
    '/backend/api/frontend/accounts/468/statements/status/': AccountStatementsStatus,
    '/backend/api/frontend/accounts/468/team/': AccountTeam,
    '/backend/api/frontend/users/active/': UserActive,
};
mockApi[dashboardUrl] = AccountStats;
mockApi[indepthUrl] = AccountStats;
mockApi[camppaignsUrl] = AccountCampaignStats;

export default process.env.VUE_APP_PA11Y === 'true' ? mockApi : {};
