<template>
    <div>
        <div v-if="loading" class="paper text-left p-3 col-12 offset-lg-1 col-lg-10">
            <div class="text-center my-2"><Icon i="spinner" spin /> Loading Account Details</div>
        </div>
        <div v-else-if="account.active" class="paper text-left p-3 col-12 offset-lg-1 col-lg-10">
            <h1>Search Engine Marketing (SEM)</h1>
            <div>
                <p><b>Your account is ready to go!</b></p>
                <hr class="my-3" />
                <h4>Coming Soon: Ads Statistics</h4>
                <p>Check back in November for a detailed breakdown of your Ads stats</p>
            </div>
        </div>
        <div v-else class="paper text-left p-3 col-12 offset-lg-1 col-lg-10">
            <h1>
                Search Engine Marketing (SEM) for Your Agency<br />
                <span class="text-muted small"
                    >Google Ads, Pay Per Click, and other SEM campaigns.</span
                >
            </h1>

            <hr class="my-3" />

            <div class="p-3 my-3">
                <h4>Google is our Partner; you can be, too!</h4>
                <div>
                    As a Google Partner, we have the experience needed to deliver results for your
                    business.
                </div>
                <div>Our account managers are certified by Google.</div>
                <div>
                    We work with corporate to adjust your SEM campaigns for compliance and best
                    practices.
                </div>
            </div>

            <hr class="my-3" />

            <div class="p-3 my-3">
                <h4>Mirus gives you:</h4>
                <ul>
                    <li>
                        <strong>Access to professionals</strong>. Our account managers are
                        experienced and certified by Google.
                    </li>
                    <li>
                        <strong>Precise data</strong> on the performance and cost of your campaigns.
                    </li>
                    <li><strong>No contracts</strong>, and a fixed monthly management fee.</li>
                    <li><strong>Automatic budget rollover</strong>.</li>
                </ul>
            </div>

            <hr class="my-3" />

            <div class="p-3 my-3">
                <h4>It's About Transparency</h4>
                <p>
                    <b>Our management fee is $40 a month.</b><br />
                    Budgeting $200 or $1,000? Our fee is <b>$40 a month.</b><br />
                    Calling us every hour or once a year — still <b>$40 a month.</b><br />
                    Change your ad campaigns more than you change your socks? <b>$40 a month.</b
                    ><br />
                    Other ad agencies think we're crazy by not taking a cut of your ad spend, but we
                    don't feel like we're "leaving money on the table".
                    <b>We're leaving it in your budget!</b><br />
                </p>
            </div>

            <hr class="my-3" />

            <div class="p-3 my-3">
                <h4>Why Should You Care?</h4>
                <p>
                    <b>It's your money!</b> Why pay for SEM campaign management that doesn't
                    translate to more clicks? You should know how much of your online Ad spend goes
                    to Google, and how much goes towards vendor management fees.
                </p>
                <p>
                    If you budget more money for online ads, you should get more ads rather than
                    more management costs.
                </p>
                <p>
                    Some vendors say flat-fee or fixed-rate pricing reduces the incentive to see
                    results. That's nonsensical. Our approach is to start you off with a moderate
                    budget and depending on the results, make recommendations on where to go with
                    your budget. That means sometimes budgets go down, and we tell you to keep the
                    difference. Imagine that.
                </p>
            </div>

            <div class="adwords-cta">
                <h2>Ready to get started?</h2>
                <b-button
                    variant="primary"
                    size="lg"
                    href="mailto:support@mydadomain.com?subject=SEM+Inquiry&body=I+would+like+more+information+on+setting+up+an+SEM+Campaign+with+Mirus."
                    target="_blank"
                    >Email Us Today</b-button
                >
            </div>
        </div>
    </div>
</template>
<script>
import PageContainer from '@/components/PageContainer.vue';
import Loading from '@/components/elements/Loading.vue';
export default {
    name: 'AdwordsPage',
    components: {
        PageContainer,
        Loading,
    },
    mounted() {
        this.$store.dispatch('get_adwords_bundle');
    },
    computed: {
        loading() {
            return !this.$store.getters.adwords;
        },
        account() {
            return this.$store.getters.adwords;
        },
    },
};
</script>
<style lang="scss" scoped>
.adwords-cta {
    text-align: center;
    h2 {
        margin-bottom: 2rem;
    }
}
</style>
