<template>
    <div>
        <!-- New Redirect -->
        <div class="mb-3">
            <div class="d-flex">
                <p class="mb-0 align-self-center pr-4">
                    Here are the email redirects for
                    <strong>{{ domain.name }}</strong
                    >. Make changes to them below.
                    <span v-if="this.domain.domain_type == 'parked'">
                        Since this domain is
                        <strong>parked</strong>, each email address you add will be billed at a rate
                        of $1/month. Enhanced Microsites include <em>unlimited</em> email redirects.
                        You can
                        <a :href="`settings`"
                            >upgrade to an Enhanced Microsite on the Settings Tab</a
                        >.
                    </span>
                </p>
                <b-button-group class="ml-auto align-self-center">
                    <b-button v-if="!forms.create" variant="primary" @click="toggle_new"
                        >Add A New Redirect</b-button
                    >
                </b-button-group>
            </div>

            <div v-if="forms.create" class="mt-4">
                <EmailRedirectEditForm
                    :domain="domain"
                    :form="forms.create"
                    :save_disabled="
                        forms.create.real_address === '' ||
                        forms.create.redirect_address_base === ''
                    "
                    :create="true"
                    :on_complete="toggle_new"
                    :load="load" />
            </div>
        </div>

        <!-- Existing Redirects -->
        <b-table :items="ordered_emails" :fields="fields" stacked="md">
            <template slot="cell(redirect_address)" slot-scope="row">
                <div v-if="!row.detailsShowing" class="pt-1">
                    {{ row.item.redirect_address }}
                </div>
            </template>

            <template slot="cell(redirects_to)" slot-scope="row">
                <div v-if="!row.detailsShowing" class="pt-1">
                    <i class="text-muted font-weight-light">redirects to</i>
                </div>
            </template>

            <template slot="cell(real_address)" slot-scope="row">
                <div v-if="!row.detailsShowing" class="text-left pt-1">
                    {{ row.item.real_address }}
                </div>
            </template>

            <template slot="cell(actions)" slot-scope="row">
                <b-button-group v-if="!row.detailsShowing" class="flex-wrap">
                    <b-button variant="outline-secondary" @click.stop="send_test_email(row)">
                        Test
                        <Icon v-if="row.item.testRunning" i="spinner" spin />
                        <Icon v-else-if="row.item.testSucceeded === true" i="check" />
                        <Icon v-else-if="row.item.testSucceeded === false" i="times" />
                    </b-button>
                    <b-button
                        v-if="isAdmin"
                        variant="outline-secondary"
                        @click="gotoLogs(row.item.redirect_address)"
                        >Logs</b-button
                    >
                    <b-button variant="outline-secondary" @click.stop="start_row_edit(row)"
                        >Edit</b-button
                    >
                    <b-button variant="outline-danger" @click.stop="start_row_edit(row, true)"
                        >Delete</b-button
                    >
                </b-button-group>
            </template>

            <template slot="row-details" slot-scope="row">
                <EmailRedirectEditForm
                    :domain="domain"
                    v-bind:form="forms[row.index]"
                    :save_disabled="
                        forms[row.index].redirect_address_base ===
                            redirect_address_base(row.item.redirect_address) &&
                        forms[row.index].real_address === row.item.real_address
                    "
                    :create="false"
                    :on_complete="cancel_row_edit(row)"
                    :load="load" />
            </template>
        </b-table>
    </div>
</template>

<script>
import EmailRedirectEditForm from '@/components/EmailRedirectEditForm.vue';
import { merge } from 'lodash';
import axios from 'axios';
import { mapGetters } from 'vuex';

export default {
    name: 'EmailRedirects',
    components: { EmailRedirectEditForm },
    props: {
        domain: Object,
        load: Function,
    },
    data() {
        return {
            fields: [
                { key: 'redirect_address', label: 'Redirect Address' },
                { key: 'redirects_to', label: '' },
                { key: 'real_address', label: 'Destination Address' },
                { key: 'actions', label: 'Actions' },
            ],
            saving: false,
            forms: {},
        };
    },
    computed: {
        ordered_emails() {
            return _.orderBy(this.domain.emails, 'real_address', 'asc');
        },
        user() {
            return this.$store.state.user;
        },
        ...mapGetters(['isAdmin']),
    },
    methods: {
        gotoLogs(email) {
            const encoded = encodeURIComponent(email);
            this.$router.push(`/admin/emaillogs/${encoded}`);
        },
        redirect_address(form) {
            return `${form.redirect_address_base}@${this.domain.name}`;
        },
        redirect_address_base(address) {
            return address.split('@')[0];
        },
        toggle_new() {
            if (this.forms.create) {
                this.$delete(this.forms, 'create');
            } else {
                this.$set(this.forms, 'create', {
                    redirect_address_base: '',
                    real_address: '',
                    saving: false,
                    deleting: false,
                });
            }
        },
        cancel_row_edit(row) {
            return () => {
                this.$delete(this.forms, row.index);
                return row.toggleDetails();
            };
        },
        start_row_edit(row, deleting = false) {
            this.$set(
                this.forms,
                row.index,
                merge(
                    {
                        redirect_address_base: row.item.redirect_address.split('@')[0],
                        saving: false,
                        deleting: deleting,
                    },
                    row.item
                )
            );
            return row.toggleDetails();
        },
        send_test_email(row) {
            this.update_email(row.item, { testRunning: true });

            const { account_number } = this.$store.getters;
            const redirect = row.item.redirect_address;
            const payload = { redirect };

            axios
                .post(`/backend/api/frontend/accounts/${account_number}/emails/test/`, payload)
                .then((r) => this.update_email(row.item, { testSucceeded: r.data.success }))
                .catch(() => this.update_email(row.item, { testSucceeded: false }))
                .finally(() => {
                    this.update_email(row.item, { testRunning: false });
                });
        },
        update_email(item, update) {
            const index = this.domain.emails.indexOf(item);
            const merged = merge(item, update);
            this.$set(this.domain.emails, index, merged);
        },
    },
};
</script>

<style></style>
