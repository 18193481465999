<template>
    <div class="paper p-2">
        <h3>Charges</h3>
        <b-table :items="charges" :fields="charge_fields">
            <template slot="cell(Date)" slot-scope="data">
                <div>{{ data.item.date }}<br />{{ data.item.time }}</div>
            </template>
        </b-table>

        <h3>Transactions</h3>
        <b-table :items="payments" :fields="payment_fields">
            <template slot="cell(Date)" slot-scope="data">
                <div>{{ data.item.date }}<br />{{ data.item.time }}</div>
            </template>

            <template slot="cell(Status)" slot-scope="data">
                <span v-if="data.item.success === true">Success</span>
                <span v-else-if="data.item.success === false">Failed</span>
                <span v-else></span>
            </template>
        </b-table>

        <h3 v-if="statement.events.length">Timeline</h3>
        <b-table v-if="statement.events.length" :items="events" :fields="event_fields">
            <template slot="cell(Date)" slot-scope="data">
                <div>{{ data.item.date }}<br />{{ data.item.time }}</div>
            </template>

            <template slot="cell(Status)" slot-scope="data">
                <span v-if="data.item.success === true">Success</span>
                <span v-else-if="data.item.success === false">Failed</span>
                <span v-else></span>
            </template>
        </b-table>
    </div>
</template>

<script>
import { parse, format } from 'date-fns';

const payment_types = {
    // look at the type/kind to differentiate payments and credits
};

export default {
    props: {
        statement: Object,
    },
    data() {
        return {
            charge_fields: [
                'Date',
                { key: 'memo', label: 'Detail' },
                { key: 'amount', label: 'Charge' },
            ],
            payment_fields: [
                'Date',
                { key: 'type', label: 'Type' },
                { key: 'memo', label: 'Detail' },
                { key: 'amount', label: 'Amount' },
                'Status',
            ],
            event_fields: ['Date', { key: 'message', label: 'Event' }],
        };
    },
    computed: {
        created() {
            return format(
                parse(this.statement.date_created, 'yyyy-MM-ddTHH:mm:ssZ', new Date()),
                'dd/MM/yyyy',
            );
        },
        charges() {
            return this.statement.charges.map((t) => {
                const date_created = parse(t.created_at, "yyyy-MM-dd'T'HH:mm:ssXXX", new Date());
                const date_string = format(date_created, 'yyyy-MM-dd');
                const time_string = format(date_created, 'h:mm a');
                return {
                    date: date_string,
                    time: time_string,
                    memo: t.memo,
                    success: t.success,
                    amount: Number(t.amount_in_cents / 100.0).toFixed(2),
                };
            });
        },
        payments() {
            return this.statement.payments.map((t) => {
                const date_created = parse(t.created_at, "yyyy-MM-dd'T'HH:mm:ssXXX", new Date());
                const date_string = format(date_created, 'yyyy-MM-dd');
                const time_string = format(date_created, 'h:mm a');
                return {
                    date: date_string,
                    time: time_string,
                    memo: t.memo,
                    success: t.success,
                    type: t.type,
                    amount: Number(t.amount_in_cents / 100.0).toFixed(2),
                    card: t.card_number,
                };
            });
        },
        events() {
            return this.statement.events.map((t) => {
                const date_created = parse(t.created_at, "yyyy-MM-dd'T'HH:mm:ssXXX", new Date());
                const date_string = format(date_created, 'yyyy-MM-dd');
                const time_string = format(date_created, 'h:mm a');
                return {
                    date: date_string,
                    time: time_string,
                    message: t.message,
                };
            });
        },
    },
};
</script>

<style></style>
