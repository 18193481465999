var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.loading)?_c('div',{staticClass:"paper text-left p-3 col-12 offset-lg-1 col-lg-10"},[_c('div',{staticClass:"text-center my-2"},[_c('Icon',{attrs:{"i":"spinner","spin":""}}),_vm._v(" Loading Account Details")],1)]):(_vm.account.active)?_c('div',{staticClass:"paper text-left p-3 col-12 offset-lg-1 col-lg-10"},[_c('h1',[_vm._v("Search Engine Marketing (SEM)")]),_vm._m(0)]):_c('div',{staticClass:"paper text-left p-3 col-12 offset-lg-1 col-lg-10"},[_vm._m(1),_c('hr',{staticClass:"my-3"}),_vm._m(2),_c('hr',{staticClass:"my-3"}),_vm._m(3),_c('hr',{staticClass:"my-3"}),_vm._m(4),_c('hr',{staticClass:"my-3"}),_vm._m(5),_c('div',{staticClass:"adwords-cta"},[_c('h2',[_vm._v("Ready to get started?")]),_c('b-button',{attrs:{"variant":"primary","size":"lg","href":"mailto:support@mydadomain.com?subject=SEM+Inquiry&body=I+would+like+more+information+on+setting+up+an+SEM+Campaign+with+Mirus.","target":"_blank"}},[_vm._v("Email Us Today")])],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('p',[_c('b',[_vm._v("Your account is ready to go!")])]),_c('hr',{staticClass:"my-3"}),_c('h4',[_vm._v("Coming Soon: Ads Statistics")]),_c('p',[_vm._v("Check back in November for a detailed breakdown of your Ads stats")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h1',[_vm._v(" Search Engine Marketing (SEM) for Your Agency"),_c('br'),_c('span',{staticClass:"text-muted small"},[_vm._v("Google Ads, Pay Per Click, and other SEM campaigns.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"p-3 my-3"},[_c('h4',[_vm._v("Google is our Partner; you can be, too!")]),_c('div',[_vm._v(" As a Google Partner, we have the experience needed to deliver results for your business. ")]),_c('div',[_vm._v("Our account managers are certified by Google.")]),_c('div',[_vm._v(" We work with corporate to adjust your SEM campaigns for compliance and best practices. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"p-3 my-3"},[_c('h4',[_vm._v("Mirus gives you:")]),_c('ul',[_c('li',[_c('strong',[_vm._v("Access to professionals")]),_vm._v(". Our account managers are experienced and certified by Google. ")]),_c('li',[_c('strong',[_vm._v("Precise data")]),_vm._v(" on the performance and cost of your campaigns. ")]),_c('li',[_c('strong',[_vm._v("No contracts")]),_vm._v(", and a fixed monthly management fee.")]),_c('li',[_c('strong',[_vm._v("Automatic budget rollover")]),_vm._v(".")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"p-3 my-3"},[_c('h4',[_vm._v("It's About Transparency")]),_c('p',[_c('b',[_vm._v("Our management fee is $40 a month.")]),_c('br'),_vm._v(" Budgeting $200 or $1,000? Our fee is "),_c('b',[_vm._v("$40 a month.")]),_c('br'),_vm._v(" Calling us every hour or once a year — still "),_c('b',[_vm._v("$40 a month.")]),_c('br'),_vm._v(" Change your ad campaigns more than you change your socks? "),_c('b',[_vm._v("$40 a month.")]),_c('br'),_vm._v(" Other ad agencies think we're crazy by not taking a cut of your ad spend, but we don't feel like we're \"leaving money on the table\". "),_c('b',[_vm._v("We're leaving it in your budget!")]),_c('br')])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"p-3 my-3"},[_c('h4',[_vm._v("Why Should You Care?")]),_c('p',[_c('b',[_vm._v("It's your money!")]),_vm._v(" Why pay for SEM campaign management that doesn't translate to more clicks? You should know how much of your online Ad spend goes to Google, and how much goes towards vendor management fees. ")]),_c('p',[_vm._v(" If you budget more money for online ads, you should get more ads rather than more management costs. ")]),_c('p',[_vm._v(" Some vendors say flat-fee or fixed-rate pricing reduces the incentive to see results. That's nonsensical. Our approach is to start you off with a moderate budget and depending on the results, make recommendations on where to go with your budget. That means sometimes budgets go down, and we tell you to keep the difference. Imagine that. ")])])
}]

export { render, staticRenderFns }