<template>
    <div v-if="!dumb && no_account_available" class="main_div">
        <CustomerHeader restricted :user="user" />
        <div class="container-fluid">
            <div class="main_container">
                <div class="row main_row">
                    <div class="paper col-12 offset-lg-1 col-lg-10 mb-2 text-left">
                        <h1>Welcome to MyDADomain!</h1>
                        <p>
                            <span>You have successfully logged in as:</span><br />
                            <strong class="text-monospace">
                            {{ user.email_address }}
                            </strong>
                            </br>
                            <strong>MVT ID: {{ user.user_id }}</strong>
                        </p>
                        <p>
                            We have detected that you are an employee of Desjardins, but we are not
                            able to associate your user account with any particular agency. You're
                            welcome to browse the site, but most features on our site require you to
                            be an Agent or Agent Team Member for Desjardins Insurance.
                        </p>
                        <p>
                            If you are an Agency Intern, please call us at 1-877-772-1982 so we can set up your secure access.
                        </p>
                        <p v-if="is_marketing_admin">
                            We see that you have been granted the
                            <strong>Marketing Admin Role</strong>, which means that you will likely
                            want to head on over to our Global Stats page:
                        </p>
                        <p v-if="is_marketing_admin" class="text-center">
                            <b-button variant="primary" to="/admin/globalstats"
                                >Global Stats</b-button
                            >
                        </p>
                        <p>
                            If you feel you've reached this page in error, or if you were expecting
                            to see some functionality here which you do not see, please contact
                            Support with the information below...
                        </p>
                        <div class="mx-2 mb-3"><SupportContactCard /></div>
                    </div>
                </div>
            </div>
        </div>
        <LogoutModal :show="show_logout_modal" />
    </div>

    <div v-else-if="login" class="main_div">
        <CustomerHeader restricted :user="user" />
        <div class="container-fluid">
            <div class="main_container">
                <div class="row main_row">
                    <slot></slot>
                </div>
            </div>
        </div>
    </div>

    <div v-else-if="ready" class="main_div">
        <Alerts />
        <CustomerHeader
            v-if="account || dumb"
            :account="account"
            :restricted="restricted"
            :user="user"
        />
        <Announcements v-if="!dumb" />
        <div class="container-fluid">
            <div class="main_container">
                <div class="row main_row">
                    <div
                        v-if="!hideBreadcrumbs"
                        class="col-12 offset-lg-1 col-lg-10 d-none d-md-block"
                    >
                        <b-breadcrumb :items="breadcrumbs"></b-breadcrumb>
                    </div>
                    <div v-else class="mb-4 col-12 d-none d-md-block"></div>
                    <slot></slot>
                </div>
            </div>
        </div>
        <LogoutModal :show="show_logout_modal" />
    </div>
    <div v-else-if="show_account_selector" class="main_div">
        <CustomerHeader restricted :user="user" />
        <div class="container-fluid">
            <div class="main_container">
                <div class="row main_row">
                    <AccountSelector />
                </div>
            </div>
        </div>
        <LogoutModal :show="show_logout_modal" />
    </div>
    <div v-else>
        <CustomerHeader restricted />
        <div class="container-fluid">
            <div class="main_container">
                <div class="row main_row">
                    <div class="mx-auto">
                        <Loading />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import has from 'lodash';
import Loading from '@/components/elements/Loading.vue';
import Alerts from '@/components/elements/Alerts.vue';
import Announcements from '@/components/Announcements.vue';
import CustomerHeader from '@/components/CustomerHeader.vue';
import LogoutModal from '@/components/LogoutModal.vue';
import AccountSelector from '@/components/AccountSelector.vue';
import SupportContactCard from '@/components/SupportContactCard.vue';

export default {
    name: 'PageContainer',
    components: {
        Alerts,
        Announcements,
        Loading,
        CustomerHeader,
        LogoutModal,
        AccountSelector,
        SupportContactCard,
    },

    data() {
        return {
            breadcrumbs: [],
            hideBreadcrumbs: true,
            restricted: false,
            dumb: false,
            login: false,
        };
    },
    mounted() {
        this.updateBreadcrumbs();
        // don't fire this off from the login/logout page,
        // it may cause a redirect loop
        if (!this.ready && !this.login) {
            this.$store.dispatch('get_user_bundle');
        }
    },

    methods: {
        updateBreadcrumbs() {
            const { matched, meta, params } = this.$route;
            const crumbs = matched
                .filter(({ meta }) => !meta.ignoreBreadcrumb)
                .map((r) => {
                    const { name, path, meta } = r;
                    const to = meta.paramName ? params[meta.paramName] : path;
                    const text = meta.paramName ? params[meta.paramName] : meta.name;
                    return {
                        text,
                        to,
                        disabled: meta.disabled,
                    };
                });
            if (this.$route.fullPath !== '/') {
                crumbs.unshift({
                    text: 'Dashboard',
                    to: '/',
                });
            }
            this.breadcrumbs = crumbs;
            this.hideBreadcrumbs = meta.hideBreadcrumbs;
            this.restricted = meta.restricted || false;
            this.dumb = meta.dumb || false;
            this.login = meta.login || false;
        },
    },

    computed: {
        account() {
            return this.$store.state.current_account;
        },
        user() {
            return this.$store.state.user;
        },
        no_account_available() {
            if (this.$store.state.user) {
                return this.$store.state.user.num_available_accounts === 0;
            }
            return false;
        },
        is_marketing_admin() {
            const user = this.$store.state.user;

            return user?.user_type === 'MirusEmployee' || user?.groups.includes('Marketing Admin');
        },
        show_account_selector() {
            return !this.dumb && this.$store.state.user && !this.$store.state.current_account;
        },
        ready() {
            return Boolean(
                this.$store.state.user && (this.$store.state.current_account || this.dumb),
            );
        },
        show_logout_modal() {
            return this.$store.state.showLogoutModel;
        },
    },
    watch: {
        $route() {
            this.updateBreadcrumbs();
        },
        ready: {
            handler(val, old_val) {
                if (val && !old_val) {
                    this.$emit('ready');
                }
            },
            immediate: true,
        },
    },
};
</script>
<style lang="scss" scoped>
.main_container {
    padding: 15px 0 0 0;
    min-height: 65vh;
    @include media('>=sm') {
        padding: 15px 15px 0 15px;
    }
    z-index: -100;
    background-color: $background-base;
    display: flex;
    justify-content: space-around;
}

a {
    color: $dark-green;
}

.main_row {
    // keep max width here
    // otherwise responsive tables will break at small widths in firefox
    max-width: 1200px;
    flex-grow: 1;
    width: 100%;
    align-content: flex-start;
}

.list-enter-active,
.list-leave-active {
    transition: all 1s;
}
.list-enter, .list-leave-to /* .list-leave-active below version 2.1.8 */ {
    opacity: 0;
    transform: translateX(30px);
}

.maintenance-window {
    padding: 2rem;
}
</style>
