<template>
    <div class="paper text-left p-3 col-12 offset-lg-1 col-lg-10">
        <KeywordContent :keyword="this.$route.params.keyword" />
        <div class="d-flex justify-content-end">
            <b-button to="/glossary">
                Back To Glossary Index
            </b-button>
        </div>
    </div>
</template>
<script>
import KeywordContent from '@/components/glossary/KeywordContent';
export default {
    name: 'GlossaryDetail',
    components: { KeywordContent },
};
</script>
<style scoped></style>
