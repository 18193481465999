<template>
    <div>
        <h1 class="mt-5 display-4 text-uppercase text-muted">loading</h1>
        <p class="text-muted"><Icon i="spinner" spin />&nbsp;one moment...</p>
    </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
