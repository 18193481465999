import { render, staticRenderFns } from "./Glossary.vue?vue&type=template&id=65d54201&scoped=true&"
import script from "./Glossary.vue?vue&type=script&lang=js&"
export * from "./Glossary.vue?vue&type=script&lang=js&"
import style0 from "./Glossary.vue?vue&type=style&index=0&id=65d54201&prod&scoped=true&module=true&lang=scss&"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "65d54201",
  null
  
)

export default component.exports