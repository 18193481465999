<template>
    <div class="tab">
        <CreditCard
            v-for="card in cards"
            :card="card"
            :key="card.id"
            :reload="reload"
            @edit="bubble"
        />
        <div class="wrapper">
            <b-button class="button" @click="$emit('edit', 'new')">
                <div class="form-row my-auto">
                    <span class="span1 my-auto mx-auto">+</span>
                    <span class="span2 my-auto pr-5">Add Card</span>
                </div>
            </b-button>
        </div>
    </div>
</template>

<script>
import CreditCard from '@/components/CreditCard.vue';

export default {
    name: 'PaymentList',
    props: {
        cards: Array,
        loading: Boolean,
        reload: Function,
    },

    components: {
        CreditCard,
    },
    methods: {
        bubble(card_id) {
            // vue doesn't let events "bubble" up to parent
            // do so manually
            this.$emit('edit', card_id);
        },
    },
};
</script>

<style lang="scss" scoped>
.outer {
    padding: 10px 10px;
}
.tab {
    padding: 10px 10px;
    display: flex;

    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;

    @media screen and (min-width: 1200px) {
        flex-direction: row;
        align-items: stretch;
    }
}
.loading-spinner {
    width: 100%;
}
.wrapper {
    height: auto;
    max-width: 425px;
    width: 100%;
    margin: 1rem 1rem;
}
.button {
    height: 100%;
    width: 100%;
    border: none;
    border-radius: 25px;
    color: $primary;
    background-color: $background-base;
    align-content: center;
    display: flex;
    justify-content: center;
    outline: none;
    margin-bottom: 20px;
}
.button:hover {
    background-color: $primary;
    border: none;
    outline: none;
    color: $white;
}
.span1 {
    font-size: 125px;
}
.span2 {
    font-size: 40px;
}
</style>
