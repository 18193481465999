<template>
    <div>
        <div class="paper text-left p-3 col-12 offset-lg-1 col-lg-10">
            <div>
                <h1>Glossary</h1>

                <ul>
                    <li v-for="(keyword, key) in this.keywords" :key="key">
                        <router-link :to="`/glossary/${key}/`">
                            {{ keyword.title }}
                        </router-link>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import keywords from '@/components/glossary/keyWordList';
export default {
    name: 'Glossary',
    data: function () {
        return {
            keywords,
        };
    },
    components: {},
    methods: {
        async goBack() {
            await this.$router.go(-1);
        },
    },
};
</script>

<style scoped module lang="scss">
.tab {
    min-width: 10px;
}
</style>
