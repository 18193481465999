import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import Axios from 'axios';
import VeeValidate from 'vee-validate';
import MockAPI from '../test/pa11y';

const VeeValidateConfig = {
    aria: true,
    delay: 0,
    errorBagName: 'veeErrors', // change if property conflicts
    events: 'input|blur',
    fieldsBagName: 'veeFields',
    i18n: null, // the vue-i18n plugin instance
    i18nRootKey: 'validations', // the nested key under which the validation messages will be located
    inject: true,
    locale: 'en',
    mode: 'aggressive',
};
Vue.use(VeeValidate, VeeValidateConfig);

import Icon from '@/components/Icons.vue';
import BootstrapVue from 'bootstrap-vue';
import Highcharts from 'highcharts';
import HighchartsAcccessibility from 'highcharts/modules/accessibility';
import VueHighcharts from 'vue-highcharts';

import Sentry from '@mirus-private/vue-utils/plugins/Sentry';
import NewVersionChecker from './NewVersionChecker';

NewVersionChecker.on('updated', () => store.commit('new_version_found', true));

if (process.env.NODE_ENV !== 'development') {
    console.log('Loading Sentry. . .');
    Vue.use(Sentry, {
        dsn: 'https://5295ee91deb446e596066e664ff90cd0@sentry.io/1326125',
    });
}

Axios.interceptors.response.use(
    (response) => response,
    (error) => {
        console.log('got an error', error);
        if (error?.response?.status == 401) {
            console.log('not logged in!');
            return store.dispatch('smart_logout');
        }
        return Promise.reject(error);
    }
);
Axios.defaults.xsrfHeaderName = 'X-CSRFToken';
Axios.defaults.xsrfCookieName = 'csrftoken';

// Axios interceptor for local dev pointing at the production backend
// provide some insulation by only allowing GET calls and a narrow selection of
// other special cases.
// See the readme.md under Local Dev for details
if (
    process.env.VUE_APP_DANGEROUS_BACKEND_URL === 'true' &&
    process.env.VUE_APP_ALLOW_DANGEROUS_METHODS !== 'true'
) {
    Axios.interceptors.request.use((req) => {
        // allow all GET's assuming that they're read-only because we
        // ALWAYS honor the intent of the method and there will be
        // no side effects.  Right?
        if (req.method.toLowerCase() === 'get') {
            return req;
        }
        // allow AccountSelector changes even during local dev
        if (
            req.method.toLowerCase() === 'put' &&
            /\/backend\/api\/frontend\/users\/\d{1,100}\//.test(req.url)
        ) {
            return req;
        }

        // allow login/authentication
        if (req.method.toLowerCase() === 'post' && /\/backend\/authenticate\//.test(req.url)) {
            return req;
        }

        // all other requests are denied and throw an error
        const msg = 'Axios GET methods only while using non-local caboto backends';
        console.error(`\n--------------------------------------------------------
            \n  ${msg}
            \n  Attempted ${req.method.toUpperCase()} ${req.url}
            \n--------------------------------------------------------`);
        throw new Axios.Cancel(msg);
    });
}

if (process.env.VUE_APP_PA11Y === 'true') {
    Axios.interceptors.request.use((req) => {
        const mockEndpoint = MockAPI[req.url];
        if (mockEndpoint && req.method in mockEndpoint) {
            req.adapter = function (config) {
                return new Promise((resolve) => {
                    return resolve({
                        status: 200,
                        data: mockEndpoint[req.method],
                        config,
                    });
                });
            };
        }

        return req;
    });
}

Vue.use(BootstrapVue);
HighchartsAcccessibility(Highcharts);
Vue.use(VueHighcharts);
Vue.use(BootstrapVue);
Vue.component('Icon', Icon);

Vue.config.productionTip = false;

new Vue({
    store,
    router,
    render: (h) => h(App),
}).$mount('#app');
