<template>
    <div>
        <div v-if="loading" class="w-100 d-flex justify-content-center">
            <Loading />
        </div>
        <div v-else-if="show_welcome" class="w-100">
            <div class="paper text-left p-3 col-12 offset-lg-1 col-lg-10">
                <h1>Welcome to MyDADomain.com</h1>
                <h4>Online Services for Desjardins Insurance Agents</h4>

                <p>Hi there! You look new here.</p>

                <p>
                    We're MyDADomain, and for over 10 years, we've been in the business of helping
                    insurance agents establish a presence online. We're ready to help you get
                    started. First things first, let's get you a domain name.
                </p>

                <p class="text-center">
                    <b-button to="/domains/purchase" size="lg" variant="primary"
                        >New Domain</b-button
                    >
                </p>

                <p>
                    Most agents need to start out by registering a
                    <strong>new</strong> domain name. But some agents have already done that with a
                    different hosting company. If you already have a domain, we can help you
                    <strong>transfer</strong> a domain which you registered elsewhere.
                </p>

                <div class="text-center">
                    <b-button to="/domains/transfer" variant="outline-secondary"
                        >Transfer Domain</b-button
                    >
                </div>

                <b-alert variant="warning" :show="isIntern" class="intern-message">
                    It looks like you're an
                    <b>Agency Intern</b>. Please start by using the buttons above to either transfer
                    your existing domain or secure a new one. Then we can activate your Enhanced
                    Microsite as soon as you appoint!
                </b-alert>
            </div>
        </div>
        <div v-else class="w-100">
            <Domains :domain_stats="this.overview_stats.domains">
                <div
                    v-if="!isIntern"
                    class="my-4 d-flex justify-content-between align-items-center flex-wrap my-3"
                >
                    <h5>
                        Domain Stats
                        <br />
                        <small>last 30 days</small>
                    </h5>
                    <div
                        class="d-flex flex-grow-1 justify-content-between align-items-center flex-wrap"
                    >
                        <div
                            v-for="(stat, title) in overview_stats.domain"
                            :key="title"
                            class="stats_item"
                        >
                            <small class="text-nowrap">{{ title }}</small>
                            <h3 v-if="stat || stat === 0">{{ stat }}</h3>
                            <div v-else>
                                <Icon i="spinner" spin />
                            </div>
                        </div>
                    </div>
                </div>
            </Domains>

            <div v-if="!isIntern" class="paper page_main_col col-12 offset-lg-1 col-lg-10 mb-3">
                <div class="d-flex justify-content-between align-items-start flex-wrap mb-4">
                    <h3>Campaigns</h3>
                    <b-button-group>
                        <b-button :disabled="isIntern" variant="primary" to="/campaigns"
                            >Stats
                        </b-button>
                        <b-button
                            variant="outline-secondary"
                            :disabled="isIntern"
                            to="/campaigns/tools"
                            >Link Generator</b-button
                        >
                    </b-button-group>
                </div>

                <div>
                    Click <b>Link Generator</b> to generate a URL with a campaign ID code for your
                    marketing campaigns. <br />Click <b>Stats</b> to view detailed stats and
                    campaign data for your EM domain(s) and Quote Landing Page(s).
                </div>
            </div>
        </div>
        <Admin v-if="!loading">
            <template slot="header">Agency Contact Info</template>
            <template slot="content">
                <div v-if="account">
                    <div>
                        <strong>Name:</strong>
                        {{ account.full_name }}
                    </div>
                    <div>
                        <strong>Legal Name:</strong>
                        {{ account.legal_full_name }}
                    </div>
                    <div>
                        <strong>Address:</strong>
                        {{ account.agency.office.address.address_street }}
                        {{ account.agency.office.address.address_street2 || '' }},
                        {{ account.agency.office.address.address_municipality }}
                        {{ account.agency.office.address.address_province }},
                        {{ account.agency.office.address.address_postal_code }}
                    </div>
                    <div>
                        <strong>Email:</strong>
                        <a :href="`mailto:${account.agency.agent.email_address}`">{{
                            account.agency.agent.email_address
                        }}</a>
                    </div>
                    <div v-if="account.agency.office.phone_numbers !== null">
                        <div
                            v-for="number in account.agency.office.phone_numbers"
                            :key="number.fullNumber"
                        >
                            <strong>
                                {{ number.contactPreferenceUsage }}
                                {{ number.type }} Number:
                            </strong>
                            {{ number.fullNumber }}
                            <span v-if="number.preferenceIndicator">(Preferred)</span>
                        </div>
                    </div>
                    <!-- phone_number is depricated and will be removed, then this line and if statement can also  -->
                    <div v-else>
                        <strong>Phone Number:</strong>
                        {{ account.agency.office.phone_number }}
                    </div>
                </div>
            </template>
        </Admin>
    </div>
</template>

<script>
import axios from 'axios';
import { subDays } from 'date-fns';
import { mapGetters } from 'vuex';

import Admin from '@/components/Admin.vue';
import Domains from '@/components/Domains';
import cache from '@/stats_cache';
import Loading from '@/components/elements/Loading.vue';

export default {
    name: 'Dashboard',
    components: {
        Admin,
        Loading,
        Domains,
    },
    data: () => {
        return {
            stats: null,
        };
    },
    computed: {
        ...mapGetters(['adwords', 'domains', 'account', 'isIntern']),
        overview_stats() {
            const { stats, domain_counts } = this.stats || { stats: {}, domain_counts: {} };

            return {
                domain: {
                    'Page Views': stats.page_views,
                    Visits: stats.visits,
                    'Quote Starts': stats.quotestarts,
                    'Phone Clicks': stats.phone_clicks,
                    'Map & Directions Clicks': stats.map_clicks,
                },
                domains: domain_counts,
            };
        },
        loading() {
            return this.domains === null;
        },
        show_welcome() {
            const domains = this.domains || [];
            const num = domains.filter((item) => {
                return item.domain_type === 'c1' && item.active;
            }).length;
            return num === 0;
        },
        num_c1_domains() {
            const domains = this.domains || [];
            return domains.filter((item) => {
                return item.domain_type === 'c1' && item.active;
            }).length;
        },
        active_adwords() {
            const awac = this.adwords;
            if (!awac) {
                return null;
            }
            return awac.active;
        },
    },
    mounted() {
        this.$store.dispatch('get_account_bundle');
        if (cache.retrieve('overview2')) {
            this.stats = cache.retrieve('overview2');
        } else {
            const account_number = this.$store.state.current_account.account_number;
            const end_string = new Date().toISOString().slice(0, 10);
            const start_string = subDays(new Date(), 30).toISOString().slice(0, 10);
            axios
                .get(
                    `/backend/api/frontend/accounts/${account_number}/stats/?graph=dashboard&start=${start_string}&end=${end_string}`,
                )
                .then((resp) => {
                    this.stats = resp.data;
                    cache.cache('overview2', null, resp.data, 1);
                });
        }
    },
};
</script>
<style lang="scss" scoped>
.page_main_col {
    padding: 15px;
    text-align: left;
}

.qlp_section {
    // padding: 15px;
    text-align: left;

    @include media('>=md') {
        padding-left: 0;
    }
    @include media('<md') {
        padding: 0;
    }
}

.adwords_section {
    // padding: 15px;
    text-align: left;
    @include media('>=md') {
        padding-right: 0;
    }
    @include media('<md') {
        padding: 0;
    }
}

.domain_info_header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.stats_item {
    text-align: center;
    flex-grow: 1;
    flex-basis: 0;
    padding: 0 5px;
}

.intern-message {
    margin-top: 20px;
}
</style>
