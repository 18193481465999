<template>
    <div>
        <div class="d-flex justify-content-between align-items-center">
            <div>
                <h3 class="pb-1 mb-0">Campaigns</h3>
                <small>{{ current_range }}</small>
            </div>
            <TimeRange @change="onNewDate" />
        </div>
        <div class="d-flex justify-content-start align-middle flex-wrap my-4">
            <GlossaryLink />
        </div>

        <div class="mt-3">
            <ul class="nav nav-pills nav-fill">
                <li v-for="tab in tabs" :key="tab.label" class="nav-item">
                    <a
                        :class="['nav-link', { active: tab.key == selected_tab }]"
                        href="javascript:void(0)"
                        @click="selected_tab = tab.key"
                        >{{ tab.label }}</a
                    >
                </li>
            </ul>
            <div
                v-if="loading"
                class="placeholder d-flex flex-column justify-content-around align-items-center"
            >
                <strong><Icon i="spinner" spin />&nbsp;Loading Stats</strong>
            </div>
            <div
                v-else-if="!usable_rows.length"
                class="placeholder d-flex flex-column justify-content-around align-items-center"
            >
                <strong>No campaign stats in this time range</strong>
            </div>
            <highcharts v-else :options="graph"></highcharts>
        </div>

        <h3 class="my-3">Detailed Stats</h3>

        <p>
            Please contact your third party vendor for questions regarding your campaign code
            statistics. Other than the data displayed, Mirus does not have any specific information
            about your marketing campaign activities.
        </p>
        <div class="d-flex justify-content-between align-middle flex-wrap my-4">
            <b-dropdown
                id="domain-filter-dropdow"
                text="Filter by Domains"
                ref="dropdown"
                variant="outline-secondary"
            >
                <b-dropdown-form class="m-2">
                    <b-form-group
                        label="Select Domains"
                        label-for="domain-list"
                        @submit.stop.prevent
                    >
                        <b-form-checkbox-group id="domain-list" v-model="domain_filter">
                            <b-form-checkbox
                                v-for="domain in available_domains"
                                :value="domain"
                                :key="domain"
                                >{{ domain }}</b-form-checkbox
                            >
                        </b-form-checkbox-group>
                    </b-form-group>
                    <b-dropdown-divider />
                    <b-dropdown-item-button @click="domain_filter = available_domains"
                        >Select All</b-dropdown-item-button
                    >
                    <b-dropdown-item-button @click="domain_filter = []"
                        >Clear</b-dropdown-item-button
                    >
                </b-dropdown-form>
            </b-dropdown>

            <b-form-checkbox v-model="show_uncampaigned" switch
                >Show Stats with No Campaign IDs ({{ num_hidden }} hidden)</b-form-checkbox
            >
        </div>
        <div class="table-responsive">
            <table class="table table-bordered table-striped table-hover">
                <thead>
                    <tr>
                        <th
                            v-for="header in table_field_names"
                            :key="header.key"
                            class="align-middle"
                        >
                            <div
                                class="d-flex align-items-center"
                                @click="onSort(header.key, header.sortable)"
                            >
                                <template v-if="header.sortable">
                                    <Icon v-if="sort_field != header.key" i="sort" class="mr-1" />
                                    <Icon v-else-if="sort_desc" i="sort-down" class="mr-1" />
                                    <Icon v-else i="sort-up" class="mr-1" />
                                </template>
                                <span>{{ header.label }}</span>
                                <InfoLink
                                    v-if="header.glossary_key"
                                    :keyword="header.glossary_key"
                                />
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-if="current_page_rows.length == 0" role="row" class="b-table-empty-row">
                        <td :colspan="table_field_names.length" role="cell" class="">
                            <div role="alert" aria-live="polite">
                                <div
                                    v-if="loading"
                                    class="d-flex justify-content-around align-items-center"
                                >
                                    <span><Icon i="spinner" spin />&nbsp;Loading Stats</span>
                                </div>
                                <div
                                    v-else-if="!current_page_rows.length"
                                    class="d-flex justify-content-around align-items-center"
                                >
                                    <span
                                        >No stats in this time range
                                        <span v-if="num_hidden > 0"
                                            >({{ num_hidden }} rows hidden)</span
                                        ></span
                                    >
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr v-for="(row, i) in current_page_rows" :key="i">
                        <td v-for="col in table_field_names" :key="col.key">
                            {{ row[col.key] }}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <b-pagination :total-rows="num_rows" v-model="page" :per-page="per_page" align="center" />
    </div>
</template>
<script>
import TimeRange from '@/components/TimeRange.vue';
import { format } from 'date-fns';
import _ from 'lodash';
import axios from 'axios';
import cache from '@/stats_cache';
import InfoLink from '@/components/glossary/InfoLink';
import GlossaryLink from '@/components/glossary/GlossaryLink';

export default {
    name: 'CampaignStats',
    components: {
        TimeRange,
        InfoLink,
        GlossaryLink,
    },
    data: function () {
        return {
            start_date: null,
            end_date: null,

            tabs: [
                {
                    label: 'Domains',
                    key: 'domain_name',
                },
                {
                    label: 'Landing Pages',
                    key: 'path',
                },
                {
                    label: 'Campaign IDs',
                    key: 'cmpid',
                },
            ],
            selected_tab: 'domain_name',

            show_uncampaigned: false,
            domain_filter: [],
            sort_field: 'visits',
            sort_desc: true,

            page: 1,
            per_page: 5,

            detail_rows: null,

            table_field_names: [
                { key: 'domain_name', sortable: true, label: 'Domain' },
                {
                    key: 'path',
                    sortable: true,
                    label: 'Landing Page',
                },
                { key: 'cmpid', sortable: true, label: 'Campaign IDs', glossary_key: 'cmpid' },
                { key: 'visits', sortable: true, label: 'Visits', glossary_key: 'visits' },
                {
                    key: 'page_views',
                    sortable: true,
                    label: 'Page Views',
                    glossary_key: 'page_view',
                },
                {
                    key: 'quotestarts',
                    sortable: true,
                    label: 'Quote Starts',
                    glossary_key: 'quote_starts',
                },
                {
                    key: 'phone_clicks',
                    sortable: true,
                    label: 'Phone Clicks',
                    glossary_key: 'phone_clicks',
                },
                {
                    key: 'contactforms',
                    sortable: true,
                    label: 'Contact Forms Sent',
                    glossary_key: 'contact_forms_sent',
                },
            ],
        };
    },
    computed: {
        current_range() {
            if (!this.start_date || !this.end_date) {
                return '';
            }
            const start_string = format(this.start_date, 'MMM d, yyyy');
            const end_string = format(this.end_date, 'MMM d, yyyy');
            return `${start_string} to ${end_string}`;
        },
        available_domains() {
            if (this.$store.getters.domains) {
                return this.$store.getters.domains.map((domain) => domain.name);
            }
            return [];
        },
        usable_rows() {
            if (!this.detail_rows) {
                return [];
            }
            return this.detail_rows
                .filter((row) => {
                    const in_domain_list =
                        this.domain_filter.length === 0 ||
                        this.domain_filter.indexOf(row.domain_name) >= 0;

                    const has_cmpid = this.show_uncampaigned || !!row.cmpid;
                    return in_domain_list && has_cmpid;
                })
                .map((row) => {
                    return { ...row, cmpid: row.cmpid || 'No Campaign ID' };
                });
        },
        current_page_rows() {
            return _(this.usable_rows)
                .orderBy([this.sort_field], [this.sort_desc ? 'desc' : 'asc'])
                .slice((this.page - 1) * this.per_page, this.page * this.per_page)
                .value();
        },
        num_rows() {
            return this.usable_rows.length;
        },
        num_hidden() {
            if (!this.detail_rows) {
                return 0;
            }
            return (
                this.detail_rows.length -
                this.detail_rows.filter((row) => {
                    return !!row.cmpid;
                }).length
            );
        },
        loading() {
            return this.detail_rows === null;
        },
        graph() {
            let starts = [];
            let visits = [];
            let categories = [];

            if (this.usable_rows) {
                let bundle = {};
                this.usable_rows.map((item) => {
                    const category = item[this.selected_tab] || 'None';
                    let existing = bundle[category] || { visits: 0, starts: 0 };
                    existing.visits += item.visits;
                    existing.starts += item.quotestarts;
                    bundle[category] = existing;
                });
                bundle = _(bundle)
                    .map((val, key) => {
                        return { category: key, ...val, rank: val.visits + val.starts };
                    })
                    .orderBy(['rank'], ['desc'])
                    .value();

                bundle.forEach((item, index) => {
                    categories.push(item.category);
                    visits.push({
                        x: index,
                        y: item.visits,
                    });
                    starts.push({
                        x: index,
                        y: item.starts,
                    });
                });
            }

            return {
                chart: {
                    type: 'bar',
                    height: '300px',
                },
                title: {
                    text: null,
                },
                xAxis: {
                    categories: categories,
                    title: {
                        text: null,
                    },
                },
                yAxis: {
                    allowDecimals: false,
                },
                plotOptions: {
                    bar: {
                        dataLabels: {
                            enabled: true,
                        },
                    },
                },
                credits: {
                    enabled: false,
                },
                series: [
                    {
                        name: 'Page Views',
                        color: 'slategrey',
                        dataLabels: {
                            enabled: false,
                        },
                        data: visits,
                    },
                    {
                        name: 'Quote Starts',
                        color: 'green',
                        dataLabels: {
                            enabled: false,
                        },
                        data: starts,
                    },
                ],
            };
        },
    },
    methods: {
        onSort(key, can_sort) {
            if (!can_sort) {
                return;
            }
            if (this.sort_field == key) {
                this.sort_desc = !this.sort_desc;
            } else {
                this.sort_field = key;
                this.sort_desc = true;
            }
        },
        onNewDate(start, end, title) {
            this.start_date = start;
            this.end_date = end;
            const is_initial = title === 'Last 30 Days';

            this.detail_rows = null;
            const account_number = this.$store.state.current_account.account_number;
            axios
                .get(
                    `/backend/api/frontend/accounts/${account_number}/stats/?graph=campaigns_view&start=${start
                        .toISOString()
                        .slice(0, 10)}&end=${end.toISOString().slice(0, 10)}`,
                )
                .then((resp) => {
                    this.detail_rows = resp.data;
                    if (is_initial) {
                        cache.cache('qlp_stats', null, resp.data, 1);
                    }
                })
                .catch((err) => {
                    this.$store.commit('add_alert', {
                        message: 'there was a problem loading stats, please try again later',
                        varient: 'danger',
                    });
                    console.log(err);
                });
        },
    },
};
</script>
<style lang="scss" scoped>
.qlp-link {
    font-size: 1.2em;
    font-weight: bold;
    text-align: center;
}
.qlp-box {
    margin-top: 0cm;
}

.placeholder {
    width: 100%;
    height: 300px - 15px;
    margin-top: 15px;
    border-radius: 10px;

    background-color: lighten($gray-400, 40%);
}
</style>
