<template>
    <div id="app">
        <PageContainer>
            <router-view class="content" />
        </PageContainer>
        <Footer />
    </div>
</template>

<script>
import Footer from '@/components/Footer.vue';
import PageContainer from '@/components/PageContainer';
export default {
    name: 'App',
    components: { PageContainer, Footer },
};
</script>

<style lang="scss">
#app {
    font-family: 'Avenir', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: $text-color;
    display: flex;
    flex-direction: column;
}

html,
body,
#app {
    min-height: 100%;
    height: 100%;
    background-color: $background-base;
    max-width: 100%;
    width: 100%;
}

.content {
    flex: 1;
    max-width: 100%;
}
</style>
