<template>
    <div>
        <h1>
            {{ this.selected_keyword.title }}
        </h1>
        <p v-html="this.selected_keyword.description"></p>
        <div
            v-for="example in this.selected_keyword.examples"
            :key="example"
            class="alert alert-info"
            role="alert"
        >
            <Icon i="lightbulb-on" /> Example: <span v-html="example"> </span>
        </div>
        <div
            v-for="note in this.selected_keyword.notes"
            :key="note"
            class="alert alert-warning"
            role="alert"
        >
            <Icon i="info-circle" /> Note: <span v-html="note"> </span>
        </div>
    </div>
</template>

<script>
import keywords from '@/components/glossary/keyWordList';
export default {
    name: 'KeywordContent',
    props: {
        keyword: { type: String, required: true },
    },
    computed: {
        selected_keyword() {
            return keywords[this.keyword];
        },
    },
};
</script>

<style></style>
