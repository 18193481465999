<template>
    <router-link to="/glossary">
        <span><Icon i="file"></Icon> Click for Glossary of Terms</span>
    </router-link>
</template>

<script>
export default {
    name: 'GlossaryLink',
    props: {},
};
</script>

<style scoped>
.link {
    color: rgb(106, 5, 208);
    font-size: 0.875rem;
    display: flex;
    align-items: center;
    i {
        margin-right: 5px;
        font-size: 1.25em;
        color: inherit;
    }
}
</style>
