export default {
    get: [
        {
            id: 19666966,
            first_name: 'kldfjslkdfjkl',
            last_name: 'kldjsfklsj',
            masked_card_number: 'XXXX-XXXX-XXXX-1111',
            expiration_month: '08',
            expiration_year: '2019',
            billing_address: '908328409 Street',
            billing_city: 'city name',
            billing_state: 'lksdafjsdkl',
            billing_zip: '023948',
            billing_country: null,
            billing_address_2: '',
            is_default: false,
            card_type: 'visa',
        },
        {
            id: 21087962,
            first_name: 'a',
            last_name: 'i',
            masked_card_number: 'XXXX-XXXX-XXXX-4444',
            expiration_month: '01',
            expiration_year: '2020',
            billing_address: 'a',
            billing_city: 'aoeu',
            billing_state: 'a',
            billing_zip: 'a',
            billing_country: null,
            billing_address_2: 'a',
            is_default: false,
            card_type: 'master',
        },
        {
            id: 19644471,
            first_name: 'name',
            last_name: 'last',
            masked_card_number: 'XXXX-XXXX-XXXX-5904',
            expiration_month: '07',
            expiration_year: '2020',
            billing_address: 'sdaf',
            billing_city: 'Winnepeg',
            billing_state: 'Ontario',
            billing_zip: '6666',
            billing_country: 'CA',
            billing_address_2: 'werwe',
            is_default: false,
            card_type: 'diners_club',
        },
        {
            id: 19718292,
            first_name: 'FirstName',
            last_name: 'LastName',
            masked_card_number: 'XXXX-XXXX-XXXX-0005',
            expiration_month: '08',
            expiration_year: '2020',
            billing_address: '123 sesame street',
            billing_city: 'Normal',
            billing_state: 'Illinois',
            billing_zip: '61761',
            billing_country: null,
            billing_address_2: '',
            is_default: false,
            card_type: 'american_express',
        },
        {
            id: 17193023,
            first_name: 'Aoeu',
            last_name: 'Ueoa',
            masked_card_number: 'XXXX-XXXX-XXXX-1117',
            expiration_month: '11',
            expiration_year: '2020',
            billing_address: '1123 Fake St',
            billing_city: 'Gridley',
            billing_state: 'Il',
            billing_zip: '61744',
            billing_country: 'CA',
            billing_address_2: null,
            is_default: false,
            card_type: 'discover',
        },
        {
            id: 17187836,
            first_name: 'auiuoeua',
            last_name: 'aoeueu',
            masked_card_number: 'XXXX-XXXX-XXXX-0000',
            expiration_month: '12',
            expiration_year: '2020',
            billing_address: '123 fake st',
            billing_city: 'Gridley',
            billing_state: 'Illinois',
            billing_zip: '61744',
            billing_country: null,
            billing_address_2: null,
            is_default: false,
            card_type: 'jcb',
        },
        {
            id: 19659190,
            first_name: '9999lkjdsklfjsd',
            last_name: 'joiud90u09sdfu',
            masked_card_number: 'XXXX-XXXX-XXXX-1111',
            expiration_month: '12',
            expiration_year: '2020',
            billing_address: 'asdfsd',
            billing_city: 'kljkldjsklfj',
            billing_state: 'qkljkljfsd',
            billing_zip: 'lkjdsfk',
            billing_country: null,
            billing_address_2: 'klsdjfkl',
            is_default: false,
            card_type: 'visa',
        },
        {
            id: 19659197,
            first_name: 'dsflk;jfoklsdjfkls',
            last_name: 'kljkldsfjskldfj',
            masked_card_number: 'XXXX-XXXX-XXXX-1111',
            expiration_month: '12',
            expiration_year: '2020',
            billing_address: 'l;dkjfklsdj',
            billing_city: 'kljdklfgjkl',
            billing_state: 'kldsjfsd',
            billing_zip: '098sdf',
            billing_country: null,
            billing_address_2: 'lkjsdklfj',
            is_default: false,
            card_type: 'visa',
        },
        {
            id: 25099142,
            first_name: 'Fake',
            last_name: 'McFakeFace',
            masked_card_number: 'XXXX-XXXX-XXXX-1111',
            expiration_month: '05',
            expiration_year: '2025',
            billing_address: '1234 Fake St',
            billing_city: 'Ottawa',
            billing_state: 'ON',
            billing_zip: 'K2C 0C7',
            billing_country: null,
            billing_address_2: null,
            is_default: false,
            card_type: 'visa',
        },
        {
            id: 19644164,
            first_name: 'Jean ',
            last_name: 'Dupont',
            masked_card_number: 'XXXX-XXXX-XXXX-1111',
            expiration_month: '07',
            expiration_year: '2025',
            billing_address: null,
            billing_city: null,
            billing_state: null,
            billing_zip: 'None',
            billing_country: 'CA',
            billing_address_2: null,
            is_default: true,
            card_type: 'visa',
        },
        {
            id: 16514161,
            first_name: 'Aoeu',
            last_name: 'Htns',
            masked_card_number: 'XXXX-XXXX-XXXX-1111',
            expiration_month: '09',
            expiration_year: '2037',
            billing_address: '',
            billing_city: '',
            billing_state: '',
            billing_zip: '',
            billing_country: '',
            billing_address_2: '',
            is_default: false,
            card_type: 'visa',
        },
    ],
};
