<template>
    <div class="page_main_col col-12 offset-lg-1 col-lg-10">
        <div class="paper" v-if="newRegex">
            <b-form class="regex-edit-form">
                <b-form-group id="inputGroup1" label="Name:" label-for="nameInput">
                    <b-form-input
                        id="nameInput"
                        type="text"
                        v-model="newRegexForm.name"
                        required
                        placeholder="compliance check name"
                    >
                    </b-form-input>
                </b-form-group>
                <b-form-group id="inputGroup2" label="Regex:" label-for="regexInput">
                    <b-form-input
                        id="regexInput"
                        type="text"
                        v-model="newRegexForm.regex"
                        required
                        placeholder="compliance check regex"
                    >
                    </b-form-input>
                </b-form-group>
                <b-form-group id="inputGroup3" label="Description:" label-for="descriptionInput">
                    <b-form-input
                        id="descriptionInput"
                        type="text"
                        v-model="newRegexForm.error_description"
                        required
                        placeholder="compliance check description"
                    >
                    </b-form-input>
                </b-form-group>

                <b-button :disabled="saving['new']" variant="primary" @click="submitNewRegex()">
                    {{ saving['new'] ? 'Saving...' : 'Submit' }}
                </b-button>
                <b-button :disabled="saving['new']" variant="danger" @click="newRegex = false">
                    Cancel
                </b-button>
            </b-form>
        </div>

        <b-form inline class="top-form">
            <b-input
                class="search-bar mr-sm-2 mb-sm-0"
                id="searchInput"
                v-model="filter"
                placeholder="Type to Search"
            />
            <b-button variant="primary" @click="newRegex = true" v-if="!newRegex">
                {{ newRegex ? 'Cancel' : 'Create New Regex' }}
            </b-button>
        </b-form>

        <div class="paper">
            <b-table
                class="regexes-table"
                small
                hover
                fixed
                stacked="md"
                :fields="fields"
                :items="regexes"
                :current-page="currentPage"
                :per-page="perPage"
                :filter="filter"
                @filtered="onFiltered"
            >
                <template slot="cell(regex)" slot-scope="data">
                    /{{ data.item.regex }}/
                </template>
                <template slot="cell(actions)" slot-scope="row">
                    <b-button
                        class="action-button"
                        :variant="row.detailsShowing ? 'danger' : 'outline-primary'"
                        @click.stop="toggleEdit(row)"
                    >
                        {{ row.detailsShowing ? 'Cancel' : 'Edit' }}
                    </b-button>
                </template>
                <template slot="row-details" slot-scope="row">
                    <b-card>
                        <b-form class="regex-edit-form">
                            <b-form-group id="inputGroup1" label="Name:" label-for="nameInput">
                                <b-form-input
                                    id="nameInput"
                                    type="text"
                                    v-model="form.name"
                                    required
                                    placeholder="compliance check name"
                                >
                                </b-form-input>
                            </b-form-group>
                            <b-form-group id="inputGroup2" label="Regex:" label-for="regexInput">
                                <b-form-input
                                    id="regexInput"
                                    type="text"
                                    v-model="form.regex"
                                    required
                                    placeholder="compliance check regex"
                                >
                                </b-form-input>
                            </b-form-group>
                            <b-form-group
                                id="inputGroup3"
                                label="Description:"
                                label-for="descriptionInput"
                            >
                                <b-form-input
                                    id="descriptionInput"
                                    type="text"
                                    v-model="form.error_description"
                                    required
                                    placeholder="compliance check description"
                                >
                                </b-form-input>
                            </b-form-group>
                            <b-button
                                :disabled="saving[row.item.pk]"
                                variant="primary"
                                @click="submitEdit()"
                            >
                                {{ saving[row.item.pk] ? 'Saving...' : 'Submit' }}
                            </b-button>
                        </b-form>
                    </b-card>
                </template>
            </b-table>
        </div>
        <b-row>
            <b-col md="12" class="my-1">
                <b-pagination
                    align="center"
                    :total-rows="totalRows"
                    :per-page="perPage"
                    v-model="currentPage"
                />
            </b-col>
        </b-row>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    name: 'ApprovalQueue',

    data() {
        return {
            filter: null,
            fields: [
                { key: 'name', label: 'Name', sortable: true },
                { key: 'regex', label: 'Regex', sortable: true },
                { key: 'actions', label: 'Actions' },
            ],
            regexes: [],
            currentPage: 1,
            perPage: 30,
            form: null,
            totalRows: 0,
            newRegex: false,
            newRegexForm: {
                name: null,
                regex: null,
                error_decription: null,
            },
            saving: {},
        };
    },
    created() {
        this.reloadRegexes();
    },
    methods: {
        reloadRegexes() {
            axios
                .get(`/backend/api/frontend/compliance/regexes`)
                .then((resp) => {
                    this.regexes = resp.data;
                    this.totalRows = this.regexes.length;
                })
                .catch((err) => {
                    this.$store.commit('add_alert', {
                        message: 'there was a problem fetching the compliance regexes',
                        varient: 'danger',
                    });
                    console.log(err);
                });
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },
        toggleEdit(row) {
            if (row.item._showDetails) {
                this.form = null;
            } else {
                this.form = row.item;
            }

            return row.toggleDetails();
        },
        submitEdit() {
            const payload = {
                name: this.form.name,
                regex: this.form.regex,
                description: this.form.error_description,
            };
            const pk = this.form.pk;
            this.$set(this.saving, pk, true);
            axios
                .put(`/backend/api/frontend/compliance/regexes/${pk}/`, payload)
                .then((resp) => {
                    this.$store.commit('add_alert', {
                        message: 'regex updated',
                        varient: 'success',
                    });
                    this.$set(this.saving, pk, undefined);
                    this.reloadRegexes();
                })
                .catch((err) => {
                    this.$store.commit('add_alert', {
                        message: 'there was a problem processing your edit',
                        varient: 'danger',
                    });
                    this.$set(this.saving, pk, undefined);
                    console.log(err);
                });
        },
        submitNewRegex() {
            const payload = {
                name: this.newRegexForm.name,
                regex: this.newRegexForm.regex,
                description: this.newRegexForm.error_description,
                type: 'regex',
            };
            this.$set(this.saving, 'new', true);
            axios
                .post(`/backend/api/frontend/compliance/regexes/`, payload)
                .then((resp) => {
                    this.$store.commit('add_alert', {
                        message: 'regex updated',
                        varient: 'success',
                    });
                    this.$set(this.saving, 'new', undefined);
                    this.newRegex = false;
                    this.reloadRegexes();
                })
                .catch((err) => {
                    this.$store.commit('add_alert', {
                        message: 'there was a problem processing your edit',
                        varient: 'danger',
                    });
                    this.$set(this.saving, 'new', undefined);
                    console.log(err);
                });
        },
    },
};
</script>

<style lang="scss" scoped>
.regex-edit-form {
    text-align: left;
    padding: 10px;
    margin-bottom: 10px;
}
.top-form {
    margin-bottom: 10px;

    .search-bar {
        flex-grow: 2;
    }
}
</style>
