<template>
    <div v-if="isAdmin" class="paper p-3 admin-section page_main_col col-12 offset-lg-1 col-lg-10">
        <b-container fluid class="headers">
            <b-row>
                <b-col>
                    <h3>
                        <slot name="header">Admin</slot>
                    </h3>
                    <hr />
                </b-col>
                <b-col class="admin-note">
                    <!-- <strong>Note: this card is only visible to admins</strong> -->
                    <b-img src="/img/icons/mirus_logo_header.png" alt="Thumbnail" />
                </b-col>
            </b-row>
        </b-container>
        <div>
            <slot name="content">nothing here :(</slot>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'Admin',
    computed: mapGetters(['isAdmin']),
};
</script>

<style lang="scss" scoped>
.admin-section {
    margin-bottom: 15px;
    border: 2px solid $yellow; /* rgb(245, 129, 32); */
    .headers {
        .col {
            padding: 0px;
        }
        .admin-note {
            text-align: right;
            color: rgb(245, 129, 32);
        }
    }
}
</style>
