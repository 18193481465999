<template>
    <div>
        <div class="paper text-left p-3 col-12 offset-lg-1 col-lg-10">
            <div class="row">
                <div class="col-xs-12 col-sm-6">
                    <h1>Manage Team Permissions</h1>

                    <p>
                        Use this page to grant your Agency Team Members (ATMs) access to your
                        MyDADomain dashboard. This level of access is deactivated by default.
                    </p>

                    <p>
                        Once activated, an ATM is able to act on behalf of the agent. Just like the
                        agent, they'll be allowed to:
                    </p>

                    <ul>
                        <li>Purchase Domains</li>
                        <li>Manage email redirects</li>
                        <li>Update payment information</li>
                        <li>Update and manage Enhanced Microsites</li>
                        <li>Grant access to other ATMs, using this same page</li>
                    </ul>

                    <p>
                        These permissions can be removed at any time by the agent or an ATM with
                        these permissions active. If the ATM stops working at the agent's office,
                        their account permissions will be deactivated automatically within 24-48
                        hours.
                    </p>

                    <p>
                        Check the box next to the name of any ATM below to allow or revoke
                        permissions. Click "Save" when you're satisfied.
                    </p>
                </div>
                <div class="col-xs-12 col-sm-6">
                    <p><b>Configure Team Settings</b></p>

                    <div v-if="!team">
                        <div><Icon i="spinner" spin /> Loading Team Members, One Moment...</div>
                    </div>
                    <template v-if="team">
                        <div v-for="member in team" :key="member.mvt_id">
                            <div class="custom-control custom-checkbox">
                                <input
                                    :id="member.mvt_id"
                                    :checked="member.enabled"
                                    :disabled="saving"
                                    type="checkbox"
                                    class="custom-control-input"
                                    @click="onSelect(member.mvt_id)" />
                                <label class="custom-control-label" :for="member.mvt_id">
                                    <b>{{ member.full_name }}</b>
                                    <br />
                                    <span>{{ member.email }}</span>
                                </label>
                            </div>
                        </div>
                        <div class="d-flex justify-content-end mt-3">
                            <b-button
                                variant="primary"
                                :disabled="!can_save || saving"
                                @click="onSave">
                                Save
                            </b-button>
                        </div>
                    </template>
                    <div v-if="saving">
                        <div><Icon i="spinner" spin /> Loading Team Members, One Moment...</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios';
import { mapGetters } from 'vuex';

export default {
    name: 'TeamSettings',
    data: function () {
        return {
            saving: false,
            team: null,
        };
    },
    computed: {
        ...mapGetters(['account_number']),
        can_save() {
            return (
                this.team.filter((item) => {
                    return item.enabled != item.old_state;
                }).length > 0
            );
        },
    },
    mounted() {
        this.loadTeam();
    },
    methods: {
        onSelect(mvt_id) {
            this.team = this.team.map((item) => {
                if (mvt_id == item.mvt_id) {
                    item.enabled = !item.enabled;
                }
                return item;
            });
        },

        loadTeam() {
            const account_number = this.account_number;
            return axios
                .get(`/backend/api/frontend/accounts/${account_number}/team/`)
                .then((resp) => {
                    this.team = resp.data.map((item) => {
                        return { ...item, old_state: item.enabled };
                    });
                })
                .catch(() => {
                    this.$store.commit('add_alert', {
                        message: `there was a problem loading the team data`,
                        varient: 'danger',
                    });
                });
        },

        onSave() {
            this.saving = true;
            const account_number = this.account_number;
            const promises = this.team
                .filter((item) => {
                    return item.enabled != item.old_state;
                })
                .map((item) => {
                    return axios.put(
                        `/backend/api/frontend/accounts/${account_number}/team/${item.mvt_id}/`,
                        { enabled: item.enabled }
                    );
                });

            Promise.all(promises)
                .then(() => {
                    return this.loadTeam();
                })
                .then(() => {
                    this.saving = false;
                })
                .catch((err) => {
                    this.$store.commit('add_alert', {
                        message:
                            'There was a problem saving ATM permissions, please try again later.',
                        varient: 'danger',
                    });
                    console.log(err);
                });
        },
    },
};
</script>
<style lang="scss" scoped></style>
