export default {
    get: {
        user: {
            username: 'MirusEmployee_117175589368933023754',
            user_type: 'MirusEmployee',
            user_id: '117175589368933023754',
            is_owner: false,
            email_address: 'ryan@mirusresearch.com',
            num_available_accounts: 562,
            can_view_overview: true,
            groups: [],
        },
        account: {
            account_number: 468,
            full_name: 'Jean Dupont',
            legal_full_name: 'Jean Dupont',
            agency_identifier: '100000000000',
            party_id: '100000000000',
            agent_code: '100001',
            agency_type: 'Primary',
            last_aem_import: '2021-03-24T19:16:03.767811Z',
            agency: {
                office: {
                    address: {
                        address_street: '1911 Baseline Rd Suite 200',
                        address_street2: null,
                        address_country: 'CA',
                        address_province: 'ON',
                        address_municipality: 'Ottawa',
                        address_postal_code: 'K2C 0C7',
                    },
                    phone_number: '613-225-5777',
                    phone_numbers: [
                        {
                            type: 'Phone',
                            fullNumber: '613-225-4777',
                            preferenceIndicator: true,
                            contactPreferenceUsage: 'Business',
                        },
                        {
                            type: 'Cellular',
                            fullNumber: '613-720-7215',
                            preferenceIndicator: false,
                            contactPreferenceUsage: 'Business',
                        },
                        {
                            type: 'Fax',
                            fullNumber: '613-225-5777',
                            preferenceIndicator: false,
                            contactPreferenceUsage: 'Business',
                        },
                    ],
                },
                agent: {
                    mvt_id: 'ABC0000',
                    full_name: 'Jean Dupont',
                    email_address: 'jeandupont@mirusresearch.com',
                    first_name: 'Jean',
                    middle_name: 'M',
                    last_name: 'Dupont',
                    legal_full_name: 'Jean Dupont',
                    designations: [
                        'Certified Financial Planner (CFP®)',
                        'Chartered Life Underwriter (CLU)',
                        'Chartered Financial Consultant  (ChFC)',
                    ],
                    products: [
                        'Auto',
                        'Life And Health',
                        'Property And Liability',
                        'Savings And Investment',
                    ],
                },
                team: [
                    {
                        mvt_id: 'ABC0001',
                        full_name: 'Jennifer Pigeon',
                        email_address: 'jennifer.m.pigeon.i5vm@statefarm.com',
                        first_name: 'Jennifer',
                        middle_name: 'M',
                        last_name: 'Pigeon',
                        legal_full_name: 'Jennifer Pigeon',
                        designations: [],
                        products: ['Auto', 'Life And Health', 'Property And Liability'],
                        active: true,
                    },
                    {
                        mvt_id: 'ABC0002',
                        full_name: 'Ray Monteiro',
                        email_address: 'ray.monteiro.eo7n@statefarm.com',
                        first_name: 'Ray',
                        middle_name: null,
                        last_name: 'Monteiro',
                        legal_full_name: 'Raymond Monteiro',
                        designations: [],
                        products: ['Auto', 'Property And Liability'],
                        active: true,
                    },
                    {
                        mvt_id: 'ABC0003',
                        full_name: 'Sandy McKnight',
                        email_address: 'sandy.mcknight.qpaa@statefarm.com',
                        first_name: 'Sandy',
                        middle_name: null,
                        last_name: 'McKnight',
                        legal_full_name: 'Sandra McKnight',
                        designations: [],
                        products: [
                            'Auto',
                            'Life And Health',
                            'Property And Liability',
                            'Savings And Investment',
                        ],
                        active: true,
                    },
                    {
                        mvt_id: 'ABC0004',
                        full_name: 'John Richard',
                        email_address: 'john.richard.i35k@statefarm.com',
                        first_name: 'John',
                        middle_name: null,
                        last_name: 'Richard',
                        legal_full_name: 'John Richard',
                        designations: [],
                        products: ['Auto', 'Life And Health', 'Property And Liability'],
                        active: true,
                    },
                ],
            },
        },
    },
};
