<template>
    <b-alert variant="warning" :show="isIntern">
        As an Agent Intern, you have the power to secure domains and establish your email redirect!
        Your domains will redirect to
        <a target="_blank" href="https://www.desjardinsagents.com">www.desjardinsagents.com</a>
        until your agency goes live. Upon appointment, we will
        <i>automatically upgrade</i> your first domain to an Enhanced Microsite within a few
        business days of your appointment date. Please feel free to contact mydadomain support with
        any questions you may have. We look forward to working with you!
    </b-alert>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    computed: {
        ...mapGetters(['isIntern']),
    },
};
</script>
