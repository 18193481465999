<template>
    <div>
        <div v-if="loading" class="w-100 d-flex justify-content-center">
            <Loading />
        </div>
        <div v-else class="w-100 text-left">
            <b-container class="page_main_col col-12 offset-lg-1 col-lg-10 mb-2">
                <b-row>
                    <b-col md="6" class="px-1">
                        <div id="contacts" class="paper p-3 h-100">
                            <h3>Contacts</h3>
                            <hr />
                            <div>
                                <strong>Name:</strong>
                                <span id="name">{{ account.full_name }}</span>
                            </div>
                            <div>
                                <strong>Legal Name:</strong>
                                <span id="legal-name">{{ account.legal_full_name }}</span>
                            </div>
                            <div>
                                <strong>Address:</strong>
                                <span id="address">
                                    {{
                                        `${address.address_street}${
                                            address.address_street2
                                                ? ` ${address.address_street2}`
                                                : ''
                                        }, ${address.address_municipality} ${
                                            address.address_province
                                        }, ${address.address_postal_code}`
                                    }}
                                </span>
                            </div>
                            <div>
                                <strong>Email:</strong>
                                <a
                                    id="email"
                                    :href="`mailto:${account.agency.agent.email_address}`"
                                    >{{ account.agency.agent.email_address }}</a
                                >
                            </div>
                            <div>
                                <strong>Default Microsite:</strong>
                                <a
                                    :href="
                                        `https://www.desjardinsagents.com/agent/_/_/_/${account.agent_code}`
                                    "
                                    >Click Here</a
                                >
                            </div>
                            <div v-if="account.agency.office.phone_numbers !== null">
                                <div
                                    v-for="number in account.agency.office.phone_numbers"
                                    :key="number.fullNumber"
                                >
                                    <strong>
                                        {{
                                            `${number.contactPreferenceUsage} ${number.type} Number:`
                                        }}
                                    </strong>
                                    <span class="phone-numbers">{{ number.fullNumber }}</span>
                                    <span v-if="number.preferenceIndicator">(Preferred)</span>
                                </div>
                            </div>
                            <div v-else>
                                <strong>Phone Number:</strong>
                                <span id="phone-number">
                                    {{ account.agency.office.phone_number }}
                                </span>
                            </div>
                        </div>
                    </b-col>
                    <b-col md="6" class="px-1">
                        <div id="agent-products" class="paper p-3 h-100">
                            <h3>Products & Authorizations</h3>
                            <hr />
                            <div>
                                <strong>Products:</strong>
                                <span v-if="isEmpty(account.agency.agent.products)">None</span>
                                <span
                                    v-else
                                    v-for="product in account.agency.agent.products"
                                    :key="product"
                                    >{{ product }},</span
                                >
                            </div>
                            <div>
                                <strong>Designations:</strong>
                                <span v-if="isEmpty(account.agency.agent.designations)">None</span>
                                <span
                                    v-else
                                    v-for="designation in account.agency.agent.designations"
                                    :key="designation"
                                    >{{ designation }},</span
                                >
                            </div>
                        </div>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col id="team-products" class="paper p-3 my-3 text-left">
                        <h3 class="pb-2">Team Members</h3>
                        <b-table :items="active_team" :fields="team_fields" hover responsive small>
                            <template slot="cell(email_address)" slot-scope="data">
                                <a :href="`mailto:${data.item.email_address}`">{{
                                    data.item.email_address
                                }}</a>
                            </template>
                            <template slot="cell(products)" slot-scope="data">
                                <span v-for="product in data.item.products" :key="product"
                                    >{{ product }},</span
                                >
                            </template>
                            <!-- <template slot="cell(designations)" slot-scope="data">
                                 <span v-for="designation in data.item.designations">
                                 {{ designation  }},
                                 </span>
                            </template>-->
                        </b-table>
                    </b-col>
                </b-row>
            </b-container>
            <Admin>
                <template slot="header"
                    >EAM</template
                >
                <template slot="content">
                    <div>
                        <strong>Identifier:</strong>
                        {{ account.agency_identifier }}
                    </div>
                    <div>
                        <strong>Last Import:</strong>
                        {{ format(parseISO(account.last_aem_import), 'MM/dd/yyyy') }}
                    </div>
                    <div>
                        <strong>Agency bundle:</strong>
                        <a
                            :href="
                                `/backend/api/singularity/consumers/${account.agency_identifier}/`
                            "
                            target="_blank"
                            rel="noopener noreferrer"
                            >Click Here</a
                        >
                    </div>
                    <div>
                        <strong>Domain Anthology Bundles:</strong>
                        <a
                            v-for="domain in domains"
                            :key="domain.name"
                            :href="`/backend/api/singularity/consumers/${domain.name}/`"
                            target="_blank"
                            rel="noopener noreferrer"
                            >{{ domain.name }},</a
                        >
                    </div>
                </template>
            </Admin>
        </div>
    </div>
</template>

<script>
import { format, parseISO } from 'date-fns';
import { mapGetters } from 'vuex';
import { isEmpty } from 'lodash';
import Admin from '@/components/Admin.vue';
import PageContainer from '@/components/PageContainer.vue';
import Loading from '@/components/elements/Loading.vue';

export default {
    name: 'Profile',
    components: { Admin, PageContainer, Loading },
    data: () => {
        return {
            stats: null,
            team_fields: [
                'full_name',
                'legal_full_name',
                'email_address',
                'products',
                /* 'designations',*/
            ],
        };
    },
    computed: {
        ...mapGetters(['domains', 'account']),
        loading() {
            return this.domains == null;
        },
        active_team() {
            return (this.account.agency.team || []).filter(({ active }) => {
                return active === true;
            });
        },
        address() {
            return this.account.agency.office.address;
        },
    },
    methods: {
        format,
        isEmpty,
        parseISO,
    },
};
</script>

<style></style>
