<template>
    <div>
        <BillingStatus v-if="isAdmin" />
        <div class="paper text-left p-3 col-12 offset-lg-1 col-lg-10">
            <h1>Billing History</h1>
            <div v-if="loading" class="font-weight-bold p-3">
                <Icon i="spinner" spin />&nbsp;Loading History...
            </div>
            <!-- This will be displayed if the server returns an error status code -->
            <div v-else-if="history_error">
                We are currently unable to display any billing history records for your account.
            </div>
            <div v-else>
                <b-table :items="table_rows" :fields="fields" :current-page="1" show-empty>
                    <template slot="empty">
                        <p>No results found.</p>
                    </template>
                    <template slot="cell(amount)" slot-scope="row">${{ row.item.amount }}</template>
                    <template slot="cell(details)" slot-scope="row">
                        <a target="_blank" :href="row.item.link">
                            <Icon i="external-link" />&nbsp;View details (PDF)
                        </a>
                    </template>
                </b-table>
                <b-pagination
                    align="center"
                    :value="current_page"
                    :total-rows="table_row_len"
                    :per-page="per_page"
                    @change="onPageChange" />
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import Statement from '@/components/Statement.vue';
import BillingStatus from '@/components/BillingStatus.vue';

export default {
    name: 'BillingHistory',
    components: {
        Statement,
        BillingStatus,
    },
    data: function () {
        return {
            loading: true,
            bread: [
                { text: 'Dashboard', to: '/' },
                { text: 'Billing History', to: '/billing' },
            ],
            history: null,
            history_error: null,
            fields: [
                { key: 'created', label: 'Date', sortable: true },
                { key: 'id', label: 'Statement', sortable: true },
                { key: 'amount', label: 'Charge', sortable: false },
                { key: 'details', label: 'Details', sortable: false },
            ],
            per_page: 20,
            current_page: 1,
            total_pages: 1,
        };
    },
    computed: {
        ...mapGetters(['account_number', 'isAdmin']),
        table_rows() {
            if (this.history) {
                const account_number = this.account_number;
                let items = [];
                this.history.forEach((s) => {
                    items.push({
                        id: `#${s.customer_facing_id}`,
                        created: s.customer_facing_created,
                        link: `/backend/api/frontend/accounts/${account_number}/billing/${s.id}/pdf_v2/`,
                        amount: `${Number(s.amount).toFixed(2)}`,
                        status: s.status,
                        statement: s,
                        history_type: 'current',
                        _showDetails: false,
                    });
                });

                return items;
            }
            return [];
        },
        table_row_len() {
            if (this.history) {
                return this.total_pages * this.per_page;
            }
            return 0;
        },
        // If there's an error retrieving the user's account we return a spoofed
        // subscription record with the history set to `null`
        history_null() {
            return this.history === null;
        },
    },
    mounted() {
        this.getHistoryPage(this.current_page);
    },

    methods: {
        onPageChange: function (page_number) {
            this.getHistoryPage(page_number);
            this.history = [];
        },
        getHistoryPage: function (page_number) {
            const account_number = this.account_number;

            this.loading = true;
            this.current_page = page_number;
            axios
                .get(
                    `/backend/api/frontend/accounts/${account_number}/billing/history/?page=${page_number}`
                )
                .then((resp) => {
                    const { statements, page, page_size, total_pages } = resp.data;
                    this.history = statements;
                    this.total_pages = total_pages;
                    this.per_page = page_size;
                })
                .catch((err) => {
                    this.history_error = err;
                    this.$store.commit('add_alert', {
                        message: 'there was a problem getting your billing history',
                        varient: 'danger',
                    });
                })
                .finally(() => (this.loading = false));
        },
    },
};
</script>

<style lang="scss" scoped></style>
