<template>
    <div>
        <div class="paper text-left p-3 mb-5 col-12 offset-lg-1 col-lg-10">
            <div class="d-flex justify-content-between align-items-center">
                <div>
                    <h3>Landing Page Stats</h3>
                    <small>{{ current_range }}</small>
                </div>
                <TimeRange @change="onNewDate" />
            </div>

            <div class="row my-4 align-items-center">
                <div class="col-xs-12 col-sm-8 padding-left">
                    <h3>Aggregate Stats</h3>
                    <div class="qlp-box">
                        <h5>Most Viewed Campaign Codes (cmpid)</h5>
                        Quote Landing Page Breakdown
                    </div>
                    <br />
                    <span class="text-center">
                        <GlossaryLink />
                    </span>
                </div>

                <div
                    v-if="header_stats"
                    class="col-xs-12 col-sm-4 d-flex flex-grow-1 justify-content-around align-items-center flex-wrap px-5"
                >
                    <div class="stats_item">
                        <small class="text-nowrap">Page Views</small>
                        <h3 class="text-center">{{ header_stats.visits }}</h3>
                        <InfoLink keyword="page_view"></InfoLink>
                    </div>
                    <div class="stats_item">
                        <small class="text-nowrap">Quote Starts</small>
                        <h3 class="text-center">{{ header_stats.starts }}</h3>
                        <InfoLink keyword="quote_starts"></InfoLink>
                    </div>
                </div>
                <div v-else>
                    <div><Icon i="spinner" spin /> Loading Stats, One Moment...</div>
                </div>
            </div>

            <div class="mt-3">
                <ul class="nav nav-pills nav-fill">
                    <li v-for="tab in tabs" :key="tab.label" class="nav-item">
                        <a
                            :class="['nav-link', { active: tab.key == selected_tab }]"
                            href="#"
                            @click="selected_tab = tab.key"
                            >{{ tab.label }}</a
                        >
                    </li>
                </ul>
                <highcharts :options="graph"></highcharts>
            </div>

            <h3 class="my-3">Detailed Stats</h3>
            <p>
                Please contact your third party vendor for questions regarding your campaign code
                statistics. Other than the data displayed, Mirus does not have any specific
                information about your marketing campaign activities.
            </p>
            <b-table
                responsive
                striped
                hover
                :items="detail_rows"
                :fields="table_field_names"
                :per-page="5"
                :current-page="page"
            />
            <b-pagination v-model="page" :total-rows="num_rows" :per-page="5" align="center" />
        </div>

        <div class="paper text-left p-3 mb-5 col-12 offset-lg-1 col-lg-10">
            <div class="container">
                <h3>Tracking Code/Campaign ID Link Generator</h3>

                <!-- <h5>Generate a Tracking Link</h5> -->
                <p>
                    This section allows you to generate a new tracking link which you can use in
                    your online marketing campaigns.
                </p>

                <div class="row">
                    <div class="col-xs-12 col-sm-6 col-md-3">
                        <label>Domain Name</label>
                        <b-form-select v-model="select_domain" :options="available_domains">
                        </b-form-select>
                    </div>
                    <div class="col-xs-12 col-sm-6 col-md-3">
                        <label>Landing Page</label>
                        <b-form-select v-model="select_traffic">
                            <option :value="null">Select a Landing Page</option>
                            <option value="/homepage">EM Homepage</option>
                            <option value="/auto">Auto QLP</option>
                            <option value="/home">Home QLP</option>
                            <option value="/tenants">Tenants QLP</option>
                            <option value="/condo">Condo QLP</option>
                        </b-form-select>
                    </div>
                    <div class="col-xs-12 col-sm-6 col-md-3">
                        <label>Traffic Source</label>
                        <b-form-select v-model="select_source">
                            <option :value="null">Select a Traffic Source</option>
                            <option value="AT">Auto Trader</option>
                            <option value="BD">Bing/Yahoo Ads Desktop</option>
                            <option value="BM">Bing/Yahoo Ads Mobile</option>
                            <option value="CG">Car Gurus</option>
                            <option value="DD">Desktop Display</option>
                            <option value="ET">Email and Email Template</option>
                            <option value="FD">Facebook Desktop</option>
                            <option value="FM">Facebook Mobile</option>
                            <option value="FN">Facebook Newsfeed</option>
                            <option value="FP">Facebook Promoted Post</option>
                            <option value="GD">Google Adwords Desktop</option>
                            <option value="GM">Google Adwords Mobile</option>
                            <option value="HU">Hulu</option>
                            <option value="EM">In-Book Email Marketing</option>
                            <option value="IY">Internet Yellow Pages Ads</option>
                            <option value="KJ">Kijiji</option>
                            <option value="LD">LinkedIn Desktop</option>
                            <option value="LM">LinkedIn Mobile</option>
                            <option value="LC">Local Campaigns</option>
                            <option value="ML">Metroland Media</option>
                            <option value="MD">Mobile Display</option>
                            <option value="PD">Pandora</option>
                            <option value="SP">Spotify</option>
                            <option value="TD">Twitter Desktop</option>
                            <option value="TM">Twitter Mobile</option>
                            <option value="WN">Weathernetwork.ca</option>
                            <option value="YD">Yelp (Desktop)</option>
                            <option value="YM">Yelp (Mobile)</option>
                            <option value="YT">YouTube</option>
                            <option value="U1">Other/Undefined 1</option>
                            <option value="U2">Other/Undefined 2</option>
                            <option value="U3">Other/Undefined 3</option>
                            <option value="U4">Other/Undefined 4</option>
                            <option value="U5">Other/Undefined 5</option>
                        </b-form-select>
                    </div>
                    <div class="col-xs-12 col-sm-6 col-md-3">
                        <label>Campaign Code</label>
                        <input
                            v-model="select_code"
                            type="number"
                            class="form-control"
                            maxlength="3"
                            minlength="3"
                            placeholder="001 - 699"
                            pattern="\d{3}"
                            min="001"
                            max="699"
                            required
                        />
                    </div>
                </div>

                <div class="container my-3">
                    <div class="row align-items-center">
                        <div class="col-10">
                            <b-form-input
                                id="copy"
                                class="qlp-link"
                                :value="fullUrlString"
                                readonly
                            >
                            </b-form-input>
                        </div>
                        <div class="col-2">
                            <b-btn @click="copyToClipboard()">Copy</b-btn>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mt-4 mb-3 mx-3">
                <a
                    v-b-toggle.collapse-1
                    href="javascript:void(0)"
                    class="d-flex w-100 justify-content-between"
                    >What is a Link Generator?<Icon
                        :i="collapse_1 ? 'chevron-down' : 'chevron-up'"
                    />
                </a>
                <b-collapse id="collapse-1" class="mt-2" @input="collapse_1 = !collapse_1">
                    <b-card>
                        <p>
                            Agents and their digital advertising vendors are highly encouraged to
                            use campaign IDs for their EM domain and/or Quote Landing page URLs in
                            order to track different digital advertising campaigns and tactics. If
                            campaign IDs are not set up and attached to the URL properly, detailed
                            campaign reporting cannot be provided.
                        </p>
                        <p>
                            There are two options available for you to use to generate a campaign ID
                            (aka tracking code) for your EM domain/Quote Landing page URL.
                        </p>

                        <p>
                            Use the Generate a Tracking Link tool above, or use the Desjardins
                            EM/QLP Reference and Campaign ID Set-Up Guide.
                        </p>

                        <p>
                            Once you have the domain URL with the campaign ID code generated, use it
                            to link to your ad/marketing campaign and provide it to your marketing
                            vendor or save the URL somewhere until you are ready to use it. (If you
                            are using the Link Generator tool above, the URL with campaign ID code
                            will be displayed in bold at the bottom of the Link Generator tool.)
                        </p>
                    </b-card>
                </b-collapse>
            </div>
            <div class="mx-3">
                <a
                    v-b-toggle.collapse-2
                    href="javascript:void(0)"
                    class="d-flex w-100 justify-content-between"
                    >What do I do with the link I've generated?
                    <Icon :i="collapse_2 ? 'chevron-down' : 'chevron-up'" />
                </a>
                <b-collapse id="collapse-2" class="mt-2" @input="collapse_2 = !collapse_2">
                    <b-card>
                        <p>
                            You can now incorporate the completed URL into your digital advertising
                            campaigns. The metrics, available to you on
                            www.mydadomain.com/campaigns, will help you track how well your digital
                            marketing efforts are performing.
                        </p>
                    </b-card>
                </b-collapse>
            </div>
        </div>
    </div>
</template>
<script>
import TimeRange from '@/components/TimeRange.vue';
import { format } from 'date-fns';
import _ from 'lodash';
import axios from 'axios';
import cache from '@/stats_cache';
import InfoLink from '@/components/glossary/InfoLink';
import GlossaryLink from '@/components/glossary/GlossaryLink';

export default {
    name: 'QLPStats',
    components: {
        TimeRange,
        InfoLink,
        GlossaryLink,
    },
    data: function () {
        return {
            start_date: null,
            end_date: null,

            collapse_1: true,
            collapse_2: true,

            tabs: [
                {
                    label: 'Campaign Codes',
                    key: 'Campaign_Code',
                },
                {
                    label: 'Traffic Sources',
                    key: 'Traffic_Source',
                },
                {
                    label: 'Tracking Codes',
                    key: 'Landing_Page',
                },
            ],
            selected_tab: 'Campaign_Code',

            page: 1,

            select_domain: null,
            select_traffic: null,
            select_source: null,
            select_code: null,

            header_stats: null,
            detail_rows: [],

            table_field_names: [
                { key: 'Traffic_Source', sortable: true, label: 'Traffic Source' },
                { key: 'Landing_Page', sortable: true, label: 'Landing Page' },
                { key: 'Domain', sortable: true, label: 'Domain' },
                { key: 'Campaign_Code', sortable: true, label: 'Campaign Id' },
                { key: 'Views', sortable: true, label: 'Views' },
                { key: 'Quotes', sortable: true, label: 'Quote Starts' },
            ],
        };
    },
    computed: {
        fullUrlString() {
            let domainDisplay = this.select_domain ? this.select_domain : '[One of your Domains]';

            let landingDisplay = '';
            if (this.select_traffic === null) {
                landingDisplay = '/quote' + '/[Landing Page]';
            } else if (this.select_traffic === '/homepage') {
                landingDisplay = '';
            } else {
                landingDisplay = '/quote' + this.select_traffic;
            }

            let trafficDisplay = this.select_source ? this.select_source : '[ Traffic Source ]';
            let campaignCodeDisplay = this.select_code ? this.select_code : '001';

            return `https://${domainDisplay}${landingDisplay}/?cmpid=${trafficDisplay}${campaignCodeDisplay}`;
        },
        current_range() {
            if (!this.start_date || !this.end_date) {
                return '';
            }
            const start_string = format(this.start_date, 'MMM d, yyyy');
            const end_string = format(this.end_date, 'MMM d, yyyy');
            return `${start_string} to ${end_string}`;
        },
        num_rows() {
            return this.detail_rows.length;
        },
        available_domains() {
            let options = [
                {
                    value: null,
                    text: 'Select a Domain',
                },
            ];

            if (this.$store.getters.domains) {
                this.$store.getters.domains.forEach((domain) => options.push(domain.name));
            }
            return options;
        },
        graph() {
            let starts = [];
            let visits = [];
            let categories = [];

            if (this.detail_rows) {
                let bundle = {};
                this.detail_rows.map((item) => {
                    const category = item[this.selected_tab];
                    let existing = bundle[category] || { visits: 0, starts: 0 };
                    existing.visits += item.Views;
                    existing.starts += item.Quotes;
                    bundle[category] = existing;
                });
                bundle = _(bundle)
                    .map((val, key) => {
                        return { category: key, ...val, rank: val.visits + val.starts };
                    })
                    .orderBy(['rank'], ['desc'])
                    .value();

                bundle.forEach((item, index) => {
                    categories.push(item.category);
                    visits.push({
                        x: index,
                        y: item.visits,
                    });
                    starts.push({
                        x: index,
                        y: item.starts,
                    });
                });
            }

            return {
                chart: {
                    type: 'bar',
                    height: '300px',
                },
                title: {
                    text: null,
                },
                xAxis: {
                    categories: categories,
                    title: {
                        text: null,
                    },
                },
                yAxis: {},
                plotOptions: {
                    series: {
                        stacking: 'normal',
                    },
                },
                credits: {
                    enabled: false,
                },
                series: [
                    {
                        name: 'Quote Starts',
                        color: 'green',
                        data: starts,
                    },
                    {
                        name: 'Page Views',
                        color: 'slategrey',
                        data: visits,
                    },
                ],
            };
        },
    },
    methods: {
        copyToClipboard() {
            let copyText = document.getElementById('copy');
            copyText.focus();
            copyText.select();
            document.execCommand('copy');
        },
        onNewDate(start, end, title) {
            this.start_date = start;
            this.end_date = end;
            const is_initial = title === 'Last 30 Days';

            this.onStatsData(null);
            const qlp_stash = cache.retrieve('qlp_stats');
            if (is_initial && qlp_stash) {
                return this.onStatsData(qlp_stash);
            }
            const account_number = this.$store.state.current_account.account_number;
            axios
                .get(
                    `/backend/api/frontend/accounts/${account_number}/stats/?graph=qlp&start=${start
                        .toISOString()
                        .slice(0, 10)}&end=${end.toISOString().slice(0, 10)}`,
                )
                .then((resp) => {
                    this.onStatsData(resp.data);
                    if (is_initial) {
                        cache.cache('qlp_stats', null, resp.data, 12);
                    }
                })
                .catch((err) => {
                    this.$store.commit('add_alert', {
                        message: 'there was a problem loading stats, please try again later',
                        varient: 'danger',
                    });
                    console.log(err);
                });
        },
        onStatsData(stats_bundle) {
            if (stats_bundle) {
                let total_visits = 0;
                let total_starts = 0;

                this.detail_rows = stats_bundle;

                stats_bundle.forEach((element) => {
                    total_visits += element.Views;
                    total_starts += element.Quotes;
                });
                this.header_stats = { visits: total_visits, starts: total_starts };
            } else {
                this.header_stats = null;
                this.detail_rows = [];
            }
        },
    },
};
</script>
<style lang="scss" scoped>
.qlp-link {
    font-size: 1.2em;
    font-weight: bold;
    text-align: center;
}
.qlp-box {
    margin-top: 0cm;
}
</style>
