import Axios from 'axios';
import Vue from 'vue';
import Vuex from 'vuex';
import { find, includes, isNull } from 'lodash';
import cache from '@/stats_cache';
import router from './router';
import NewVersionChecker from './NewVersionChecker';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        // agency: null,
        alerts: [],
        announcements: [],
        user: null,
        current_account: null,
        showLogoutModel: false,

        domains_bundle: null,
        adwords_bundle: null,
        team_bundle: null,
        payment_bundle: null,
        newVersion: false,
    },
    mutations: {
        on_user_info(state, data) {
            state.user = data;
        },
        on_current_account(state, data) {
            state.current_account = data;
        },
        on_logout(state) {
            state.showLogoutModel = true;
        },
        clear_user_state(state) {
            state.user = null;
            state.current_account = null;
            state.domains_bundle = null;
            state.adwords_bundle = null;
            state.payment_bundle = null;
        },
        clear_current_account(state) {
            state.current_account = null;
            state.domains_bundle = null;
            state.adwords_bundle = null;
            state.payment_bundle = null;
            cache.clear();
        },
        add_alert(state, payload) {
            state.alerts.push({ show: 5, ...payload });
        },
        remove_alert(state, index) {
            state.alerts[index].show = false;
            // state.alerts.splice(index, 1);
        },
        on_domain_bundle(state, bundle) {
            state.domains_bundle = bundle;
        },
        clear_domains(state) {
            state.domains_bundle = null;
        },
        on_adwords_bundle(state, bundle) {
            state.adwords_bundle = bundle;
        },
        on_team_bundle(state, bundle) {
            state.team_bundle = bundle;
        },
        on_payment_bundle(state, bundle) {
            state.payment_bundle = bundle;
        },
        on_global_announcements(state, bundle) {
            state.announcements = bundle;
        },
        new_version_found(state, newVersionFound) {
            state.newVersion = newVersionFound;
        },
        update_app(state) {
            state.newVersion = false;
            NewVersionChecker.update();
        },
    },
    actions: {
        async get_announcements({ commit, getters }) {
            const number = getters.account_number;
            if (isNull(number)) {
                return;
            }
            Axios.get(`/backend/api/frontend/accounts/${number}/notifications/`)
                .then((resp) => {
                    commit('on_global_announcements', resp.data.global);
                })
                .catch((err) => {
                    console.log('caught an error');
                    console.log(err);
                });
        },
        async get_user_bundle({ commit, dispatch }) {
            Axios.get(`/backend/api/frontend/users/active/`)
                .then((resp) => {
                    commit('on_user_info', resp.data.user);
                    commit('on_current_account', resp.data.account);

                    if (resp.data.account) {
                        dispatch('get_account_bundle');
                    }
                })
                .catch((err) => {
                    console.log('caught an error');
                    console.log(err);
                });
        },
        async get_account_bundle({ commit, getters }) {
            const number = getters.account_number;
            const timestamp = { timestamp: new Date() };
            return Axios.get(`/backend/api/frontend/accounts/${number}/`)
                .then((resp) => {
                    commit('on_domain_bundle', { domains: resp.data.domains, ...timestamp });
                    let bundle = { adwords: { active: false }, ...timestamp };
                    if (resp.data.adwords_accounts && resp.data.adwords_accounts.length > 0) {
                        bundle = find(resp.data.adwords_accounts, (account) => {
                            return account.active;
                        }) || { adwords: resp.data.adwords_accounts[0], ...timestamp };
                    }
                    commit('on_adwords_bundle', {
                        adwords: bundle,
                        ...timestamp,
                    });
                    commit('on_team_bundle', { team: {}, ...timestamp });
                })
                .catch(() => {
                    commit('add_alert', {
                        message:
                            "Something went wrong, we couldn't load your data. Please try again later",
                        varient: 'danger',
                    });
                });
        },

        async get_domains({ commit, getters }) {
            const number = getters.account_number;
            const timestamp = { timestamp: new Date() };
            return Axios.get(`/backend/api/frontend/accounts/${number}/domains/`)
                .then((resp) => {
                    commit('on_domain_bundle', { domains: resp.data, ...timestamp });
                })
                .catch(() => {
                    commit('add_alert', {
                        message:
                            "Something went wrong, we couldn't load your data. Please try again later",
                        varient: 'danger',
                    });
                });
        },

        async get_adwords_bundle({ commit, getters }) {
            const number = getters.account_number;
            const timestamp = { timestamp: new Date() };
            return Axios.get(`/backend/api/frontend/accounts/${number}/adwords/`)
                .then((resp) => {
                    if (resp.data.length) {
                        commit('on_adwords_bundle', { adwords: resp.data.adwords, ...timestamp });
                    } else {
                        commit('on_adwords_bundle', { adwords: { active: false }, ...timestamp });
                    }
                    commit('on_domain_bundle', { domains: resp.data, ...timestamp });

                    let bundle = { adwords: { active: false }, ...timestamp };
                    if (resp.data.length > 0) {
                        bundle = find(resp.data, (account) => {
                            return account.active;
                        }) || { adwords: resp.data[0], ...timestamp };
                    }
                    commit('on_adwords_bundle', {
                        adwords: bundle,
                        ...timestamp,
                    });
                })
                .catch(() => {
                    commit('add_alert', {
                        message:
                            "Something went wrong, we couldn't load your data. Please try again later",
                        varient: 'danger',
                    });
                });
        },

        async invalidate_domains({ commit, dispatch }) {
            commit('clear_domains');
            return dispatch('get_domains');
        },

        smart_logout({ state, commit }) {
            if (state.current_account || state.user) {
                console.log('session timed out');
                commit('on_logout');
            } else {
                commit('clear_user_state');
                window.localStorage.destination_url = window.location.href;
                router.push('/login/');
            }
        },
        select_user({ commit }) {
            commit('clear_current_account');
        },
    },
    getters: {
        user: (state) => {
            return function () {
                return state.user;
            };
        },
        isIntern: (state) => {
            return state.current_account && state.current_account.agency_type === 'Mirus Intern';
        },
        isAdmin: (state) => {
            return state.user && state.user.user_type === 'MirusEmployee';
        },
        isMarketingAdmin: (state) => {
            return state.user && includes(state.user.groups, 'Marketing Admin');
        },
        account_number: (state) => {
            if (!state.current_account) {
                return null;
            }
            return state.current_account.account_number;
        },
        account_agency_type: (state) => {
            return function () {
                if (!state.current_account) {
                    return null;
                }
                return state.current_account.agency_type;
            };
        },
        account: (state) => {
            if (!state.current_account) {
                return null;
            }
            return state.current_account;
        },

        domains: (state) => {
            if (!state.domains_bundle) {
                return null;
            }
            return state.domains_bundle.domains;
        },

        domain: (state) => (name) => {
            if (!state.domains_bundle) {
                return null;
            }
            return (
                find(state.domains_bundle.domains, (domain) => {
                    return domain.name == name;
                }) || null
            );
        },
        adwords: (state) => {
            if (!state.adwords_bundle) {
                return null;
            }
            return state.adwords_bundle.adwords;
        },
        announcements: (state) => state.announcements,
        newVersion: (state) => state.newVersion,
    },
});
