<template>
    <div>
        <div class="paper page_main_col col-12 offset-lg-1 col-lg-10">
            <div class="stats-header">
                <div>
                    <h3>Global Site Stats</h3>

                    <span class="text-center">
                        <GlossaryLink />
                    </span>
                </div>
            </div>
            <b-table hover small :fields="fields" :items="globalStats">
                <template slot="value" slot-scope="data">
                    <Icon v-if="isNil(data.value)" i="spinner" spin />
                    <span v-else>{{ data.value }}</span>
                </template>
            </b-table>
        </div>

        <div class="paper page_main_col col-12 offset-lg-1 col-lg-10">
            <div class="stats-header">
                <div>
                    <h3>All Domain Stats</h3>
                    <small>{{ current_range }}</small>
                </div>
                <TimeRange @change="onNewDate" />
            </div>
            <b-table hover small :fields="fields" :items="domainStats">
                <template slot="value" slot-scope="data">
                    <Icon v-if="isNil(data.value)" i="spinner" spin />
                    <span v-else>{{ data.value }}</span>
                </template>
            </b-table>
            <hr />
            <highcharts
                :options="
                    graphConfig('Page Views by Device Type', 'Device_Type', device_typeStats)
                " />
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import { isNil } from 'lodash';
import { format } from 'date-fns';
import GlossaryLink from '@/components/glossary/GlossaryLink';
import TimeRange from '@/components/TimeRange.vue';

export default {
    name: 'GlobalStats',
    components: {
        TimeRange,
        GlossaryLink,
    },
    data() {
        return {
            bread: [
                { text: 'Dashboard', to: '/' },
                { text: 'Admin', active: true },
                { text: 'Global Stats', to: '/admin/globalstats', active: true },
            ],
            start_date: null,
            end_date: null,
            fields: ['title', 'value'],
            globalStats: [
                { title: 'Active Enhanced Microsites', value: null, key: 'active_em_sites' },
                { title: 'Active Parked Domains', value: null, key: 'active_parked_sites' },
                { title: 'Content Edits', value: null, key: 'content_edits' },
                { title: 'Unique Editors', value: null, key: 'unique_content_editors' },
                { title: '% EM Sites Edited', value: null, key: 'percent_em_sites_edited' },
            ],
            domainStats: [
                { title: 'Total Visits', value: null, key: 'visits' },
                { title: 'Total Page Views', value: null, key: 'page_views' },
                { title: 'Quote Starts', value: null, key: 'quotestarts' },
                { title: 'Contact Forms Sent', value: null, key: 'contactforms' },
                { title: 'Phone Clicks', value: null, key: 'phone_clicks' },
                { title: 'Map & Directions Clicks', value: null, key: 'map_clicks' },
                { title: 'Review Clicks', value: null, key: 'google_review_clicks' },
                { title: 'Total Compliance Checks', value: null, key: 'compliancechecks' },
                {
                    title: 'Total Mobile App Clicks (Apple App Store)',
                    value: null,
                    key: 'mobile_app_apple_clicks',
                },
                {
                    title: 'Total Mobile App Clicks (Android Play Store)',
                    value: null,
                    key: 'mobile_app_android_clicks',
                },
            ],

            device_typeStats: [],
        };
    },
    mounted() {
        this.reloadGlobalStats();
    },
    computed: {
        current_range() {
            if (!this.start_date || !this.end_date) {
                return '';
            }
            const start_string = format(this.start_date, 'MMM d, yyyy');
            const end_string = format(this.end_date, 'MMM d, yyyy');
            return `${start_string} to ${end_string}`;
        },
    },
    methods: {
        isNil: isNil,
        setDomainStatsToNull() {
            const new_stats = this.domainStats.map((s) => _.set(s, 'value', null));
            this.domainStats = new_stats;
        },
        reloadGlobalStats() {
            axios.get(`/backend/api/frontend/admin/globalstats/`).then((resp) => {
                const data = resp.data;
                for (let i = 0; i < this.globalStats.length; i++) {
                    let stat = this.globalStats[i];
                    stat.value = data[stat.key];
                    this.$set(this.globalStats, i, stat);
                }
            });
        },
        reloadAllDomainStats(start, end) {
            axios
                .get(`/backend/api/frontend/admin/alldomainstats/`, {
                    params: {
                        start: this.start_date,
                        end: this.end_date,
                    },
                })
                .then((resp) => {
                    const data = resp.data;
                    for (let i = 0; i < this.domainStats.length; i++) {
                        let stat = this.domainStats[i];
                        stat.value = data[stat.key];
                        this.$set(this.domainStats, i, stat);
                    }

                    const readableDevType = Object.keys(data.device_type).map((name) => {
                        const readableNames = {
                            console: 'Console',
                            smarttv: 'Smart TV',
                            tablet: 'Tablet',
                            mobile: 'Mobile',
                            desktop: 'Desktop',
                            Unknown: 'Unknown',
                        };
                        return {
                            name: readableNames[name],
                            y: data.device_type[name],
                        };
                    });
                    this.device_typeStats = readableDevType;
                });
        },
        reloadVenderStats() {},
        onNewDate(start, end, title) {
            this.start_date = start;
            this.end_date = end;
            this.setDomainStatsToNull();
            this.reloadAllDomainStats(this.start_date, this.end_date);
        },
        graphConfig(title, seriesName, data) {
            return {
                chart: {
                    type: 'pie',
                },
                credits: false,
                title: {
                    text: title,
                },
                tooltip: {
                    pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>',
                },
                plotOptions: {
                    pie: {
                        allowPointSelect: true,
                        cursor: 'pointer',
                        dataLabels: {
                            enabled: true,
                            format: '<b>{point.name}</b>: {point.percentage:.1f} %',
                            style: {
                                color: 'black',
                            },
                        },
                    },
                },
                series: [
                    {
                        name: seriesName,
                        colorByPoint: true,
                        data: data,
                    },
                ],
            };
        },
    },
};
</script>

<style lang="scss" scoped>
.page_main_col {
    padding: 15px;
    text-align: left;
    margin-bottom: 15px;
}

.stats-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 15px;
}
</style>
