export default {
    get: {
        number: 468,
        domains: [
            {
                name: 'roundbacon2.com',
                active: true,
                emails: [],
                account: 468,
                domain_type: 'c1',
                whois_admin_email: 'eb2c477a36f1fb94fe67e3a54e9acf3a-2268436@contact.gandi.net',
                meta_description: '',
                parked_target: null,
                articles_blacklist: [],
                google_domain_verification: '',
                cookie_management_id: '',
            },
            {
                name: 'myauroraagent.com',
                active: true,
                emails: [
                    {
                        pk: 6,
                        real_address: 'andrew@mirusresearch.com',
                        redirect_address: 'andrew@myauroraagent.com',
                        domain: 'myauroraagent.com',
                        account: 468,
                    },
                ],
                account: 468,
                domain_type: 'c1',
                whois_admin_email: null,
                meta_description: "That's so meta!",
                parked_target: null,
                articles_blacklist: [],
                google_domain_verification: '',
                cookie_management_id: 'test-cookie-12345',
            },
            {
                name: 'insurance-man.com',
                active: true,
                emails: [
                    {
                        pk: 2277,
                        real_address: 'jennifer.m.pigeon.i5vm@statefarm.com',
                        redirect_address: 'jennifer@insurance-man.com',
                        domain: 'insurance-man.com',
                        account: 468,
                    },
                ],
                account: 468,
                domain_type: 'c1',
                whois_admin_email: null,
                meta_description: '',
                parked_target: null,
                articles_blacklist: [],
                google_domain_verification: '',
                cookie_management_id: '',
            },
            {
                name: 'roundbacon.com',
                active: true,
                emails: [
                    {
                        pk: 2203,
                        real_address: 'jennifer.m.pigeon.i5vm@statefarm.com',
                        redirect_address: 'test3@roundbacon.com',
                        domain: 'roundbacon.com',
                        account: 468,
                    },
                    {
                        pk: 2202,
                        real_address: 'dan.sims.b3ab@statefarm.com',
                        redirect_address: 'test@roundbacon.com',
                        domain: 'roundbacon.com',
                        account: 468,
                    },
                ],
                account: 468,
                domain_type: 'c1',
                whois_admin_email: 'f3c603ee352934070c6c53a78b22c8db-15320489@contact.gandi.net',
                meta_description: '',
                parked_target: null,
                articles_blacklist: [],
                google_domain_verification: '',
                cookie_management_id: '',
            },
        ],
        adwords_accounts: [],
        active: true,
        billing_status: 'inactive',
    },
};
