<template>
    <div v-if="domain_in_account">
        <b-tabs
            v-model="tabIndex"
            class="paper-tabs page_main_col col-12 offset-lg-1 col-lg-10 mb-5">
            <!-- Stats Tab -->
            <b-tab
                :title-link-class="linkClass(0)"
                class="paper p-3"
                :active="active_tab === 'stats'"
                :disabled="isIntern"
                @click="setActiveTab('stats')">
                <div class="domain_header">
                    <h2>{{ domain_name }}</h2>
                    <b-button-group>
                        <b-button
                            variant="outline-secondary"
                            target="_blank"
                            :href="`https://${domain_name}/`"
                            :disabled="isIntern"
                            >View Site</b-button
                        >
                        <b-button
                            variant="outline-secondary"
                            target="_blank"
                            :href="`https://${domain_name}/?editing`"
                            :disabled="isIntern"
                            >Edit Site</b-button
                        >
                    </b-button-group>
                </div>
                <hr />

                <template slot="title">
                    <div class="title">Stats</div>
                </template>

                <div v-if="loading" class="font-weight-bold p-3">
                    <Icon i="spinner" spin />&nbsp;Loading domain details...
                </div>
                <div v-else>
                    <div v-if="domain_data.domain_type == 'c1'">
                        <div class="d-flex justify-content-between align-items-center">
                            <div class="align-self-start margin-top-7px">
                                <strong>Viewing:</strong>
                                {{ current_range }}
                                <br />
                                <span class="text-center">
                                    <GlossaryLink />
                                </span>
                            </div>
                            <TimeRange :condensed="true" @change="onNewDate" />
                        </div>

                        <div
                            v-if="graphs.overview"
                            class="mt-4 d-flex justify-content-between align-items-center flex-wrap my-3">
                            <h5>Visitor Activity</h5>
                            <div
                                class="d-flex flex-grow-1 justify-content-between align-items-center flex-wrap px-5">
                                <div
                                    v-for="item in graphs.overview"
                                    :key="item.title"
                                    class="stats_item">
                                    <small class="text-nowrap">
                                        <InfoLink :keyword="item.keyword">
                                            {{ item.title }}
                                        </InfoLink>
                                    </small>
                                    <h3 class="text-center">{{ item.value || 0 }}</h3>
                                </div>
                            </div>
                        </div>
                        <div v-else class="mt-4 d-flex justify-content-start my-3">
                            <div><Icon i="spinner" spin />Loading Stats, One Moment...</div>
                        </div>

                        <div class="mt-4 d-flex justify-content-end align-items-center my-3">
                            <!--<span class="h5 mb-0">Campaigns</span>-->
                            <span
                                >Looking for Campaign Stats? Click here to see more details
                                <b-btn variant="outline-secondary" class="ml-2" to="/campaigns"
                                    >Campaigns</b-btn
                                ></span
                            >
                        </div>

                        <div class="text-center mt-4">
                            <span class="h4 text-center">
                                <InfoLink keyword="page_views_over_time">
                                    Page Views Over Time
                                </InfoLink>
                            </span>
                            <highcharts :options="graphs.time"> </highcharts>
                        </div>
                        <div class="row mt-4 d-flex justify-content-around">
                            <div class="">
                                <span class="h4 text-center">
                                    <InfoLink keyword="page_views_by_device_type">
                                        Page Views by Device Type
                                    </InfoLink>
                                </span>
                                <highcharts :options="graphs.device_type" />
                            </div>
                        </div>

                        <div class="row mt-4">
                            <div
                                v-for="table in graphs.tables"
                                :key="table.title"
                                class="col-xs-12 col-md-6 stats-table">
                                <div class="title">
                                    <InfoLink :keyword="table.keyword">
                                        {{ table.title }}
                                    </InfoLink>
                                </div>
                                <hr />
                                <div class="d-flex justify-content-between">
                                    <div>{{ table.fields[0] }}</div>
                                    <div>{{ table.fields[1] }}</div>
                                </div>
                                <hr />
                                <div class="row-wrapper">
                                    <table class="row-table">
                                        <tbody>
                                            <tr
                                                v-for="item in table.items"
                                                :key="item.label + item.hits">
                                                <td class="row-title" :title="item.label || 'None'">
                                                    {{ item.label || 'None' }}
                                                </td>
                                                <td class="row-value">{{ item.hits }}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else>
                        <h1 id="stats" class="mb-0">Stats</h1>
                        <p>Stats are not available for Parked Domains</p>
                    </div>
                </div>
            </b-tab>

            <!-- Email Tab -->
            <b-tab
                :title-link-class="linkClass(1)"
                class="paper p-3"
                :active="active_tab === 'emails'"
                @click="setActiveTab('emails')">
                <InternDomainMessage />
                <div class="domain_header">
                    <h2>{{ domain_name }}</h2>
                    <b-button-group>
                        <b-button
                            variant="outline-secondary"
                            target="_blank"
                            :href="`https://${domain_name}/`"
                            >View Site</b-button
                        >
                        <b-button
                            variant="outline-secondary"
                            target="_blank"
                            :href="`https://${domain_name}/?editing`"
                            >Edit Site</b-button
                        >
                    </b-button-group>
                </div>
                <hr />
                <template slot="title">
                    <div class="title">Emails</div>
                </template>
                <div v-if="loading" class="font-weight-bold p-3">
                    <Icon i="spinner" spin />Loading domain details...
                </div>
                <div v-else>
                    <div class="email-container">
                        <EmailRedirects :domain="domain_data" :load="refresh_domain" />
                    </div>
                </div>
            </b-tab>

            <!-- Settings Tab -->
            <b-tab
                :title-link-class="linkClass(2)"
                class="paper p-3"
                :active="active_tab === 'settings'"
                @click="setActiveTab('settings')">
                <InternDomainMessage />
                <div class="domain_header">
                    <h2>{{ domain_name }}</h2>
                    <b-button-group>
                        <b-button
                            variant="outline-secondary"
                            target="_blank"
                            :href="`https://${domain_name}/`"
                            >View Site</b-button
                        >
                        <b-button
                            variant="outline-secondary"
                            target="_blank"
                            :href="`https://${domain_name}/?editing`"
                            >Edit Site</b-button
                        >
                    </b-button-group>
                </div>
                <hr />
                <template slot="title">
                    <div class="title">Settings</div>
                </template>
                <div v-if="loading" class="font-weight-bold p-3">
                    <Icon i="spinner" spin />Loading domain details...
                </div>
                <div v-else>
                    <!-- meta description -->
                    <b-form-group
                        label="Meta Description"
                        label-size="lg"
                        label-for="meta-description">
                        <b-form-textarea
                            v-model="new_meta_description"
                            id="meta-description"
                            class="form-control meta-description"
                            rows="3" />

                        <p :class="meta_description_length === 156 ? 'description_warning' : ''">
                            {{ meta_description_length }} / 156 characters used
                        </p>
                        <p>
                            Edit your Meta Description to give a hint to Google and other search
                            engines (Bing, Yahoo, DuckDuckGo, etc.) about the purpose of your
                            Enhanced Microsite. This description often shows up on Search Engine
                            Results Pages as a snippet of your website. Make sure this grabs the
                            attention of searchers by writing a concise, relevant, descriptive
                            summary of your website and what searchers can expect when they click
                            through to it. The default meta description is '{Agent Full Name},
                            Desjardins Insurance Agent in {Municipality, Province} | Auto, Home,
                            Life Insurance & more'.
                        </p>
                        <p>This field cannot be longer than 156 characters.</p>
                        <div class="d-flex justify-content-end">
                            <b-button
                                variant="primary"
                                :disabled="Boolean(saving)"
                                @click="onSaveMetadata">
                                <Icon v-if="saving === 'meta'" i="spinner" spin />Save Description
                            </b-button>
                            <!-- <b-button variant="outline-secondary" :disabled="Boolean(saving)" @click="onRevertMetadata">
                                     <Icon v-if="saving==='meta'" i="spinner" spin />
                                     Revert to Default
                            </b-button>-->
                        </div>
                        <hr />
                    </b-form-group>

                    <!-- Parked Target -->
                    <!-- Point this domain at a full Enhanced Micarosite. Visitors to this domain will be redirected to the Parked Target. -->
                    <b-form
                        v-if="parked_domain"
                        class="d-flex justify-content-between w-100"
                        inline>
                        <div class="d-flex">
                            <label for="parkedTaget" class="mr-sm-2">
                                Redirect visitors from this domain to the full Enhanced Microsite
                            </label>
                            <b-form-select
                                id="parkedTarget"
                                v-model="new_parked_target"
                                :options="other_domains"
                                class="mr-sm-2" />
                        </div>
                        <b-button
                            variant="primary"
                            :disabled="Boolean(saving)"
                            @click="onSaveParkedTarget">
                            <Icon v-if="saving === 'target'" i="spinner" spin />Save Target
                        </b-button>
                    </b-form>
                    <hr v-if="parked_domain" />
                    <!-- Google Domain Verification-->
                    <!-- Allows the agent to claim ownership of this domain in Google Search Console. -->
                    <b-form-group v-if="isAdmin" label="Google Domain Verification" label-size="lg">
                        <b-form class="d-flex justify-content-between w-100" inline>
                            <p class="admin-notice">Only visible to Mirus Staff.</p>
                            <b-form-input
                                id="domainVerification"
                                v-model="domainVerification"
                                placeholder="e.g. google-site-verification=ABCDefgh1234...."
                                class="w-100" />
                            <label for="domainVerification">
                                Add a Google Domain Verification Token by pasting the entire record
                                beginning with "google-site-verification"
                            </label>
                            <div class="w-100 justify-content-end mt-3 d-flex">
                                <b-button
                                    variant="primary"
                                    :disabled="Boolean(saving)"
                                    @click="onSaveDomainVerification">
                                    <Icon v-if="saving === 'target'" i="spinner" spin />Save Domain
                                    Verification
                                </b-button>
                            </div>
                        </b-form>
                    </b-form-group>
                    <hr />

                    <!-- Cookie Management ID -->
                    <!-- manually enter Cookie Management ID for this domain -->
                    <b-form-group v-if="isAdmin" label="Cookie Management ID" label-size="lg">
                        <b-form class="d-flex justify-content-between w-100" inline>
                            <p class="admin-notice">Only visible to Mirus Staff.</p>
                            <b-form-input
                                id="cookieManagementID"
                                v-model="cookieManagementID"
                                placeholder="e.g. 328e6043-a669-4412-abc3-8b9a8e415cd0"
                                class="w-100" />
                            <label for="cookieManagementID">
                                Add a Cookie Management ID to this domain.
                            </label>
                            <div class="w-100 justify-content-end mt-3 d-flex">
                                <b-button
                                    variant="primary"
                                    :disabled="Boolean(saving)"
                                    @click="onSaveCookieManagementID">
                                    <Icon v-if="saving === 'target'" i="spinner" spin />Save Cookie
                                    Management ID
                                </b-button>
                            </div>
                        </b-form>
                    </b-form-group>
                    <hr />

                    <!-- Convert to full Domain -->
                    <b-form
                        v-if="parked_domain"
                        class="d-flex justify-content-between w-100"
                        inline>
                        <label class="mr-sm-2">Upgrade this domain to an Enhanced Microsite.</label>
                        <div class="w-100 mt-3 d-flex justify-content-end">
                            <b-button
                                variant="primary"
                                :disabled="Boolean(saving)"
                                @click="ask_transform = true"
                                >Enable Enhanced Microsite</b-button
                            >
                        </div>
                    </b-form>
                    <BillingChange
                        action="enable_full"
                        :emails="transform_emails"
                        :open="ask_transform"
                        @confirm="onFull"
                        @close="ask_transform = false" />
                    <hr v-if="parked_domain" />

                    <!-- Convert to Parked Domain -->
                    <b-form-group v-if="c1_domain" label="Park this Domain" label-size="lg">
                        <p>
                            Do you want to retain control of a domain for current or future
                            marketing purposes? Use a "Parked Domain" to hold onto a domain. All
                            traffic to Parked Domains is redirected to a regular Enhanced Microsite
                            domain (at least one EM domain must be active to hold a Parked Domain).
                        </p>
                        <div class="d-flex justify-content-end">
                            <b-button
                                variant="primary"
                                :disabled="Boolean(saving)"
                                @click="ask_transform = true"
                                >Park Domain</b-button
                            >
                        </div>
                        <BillingChange
                            action="park_domain"
                            :emails="transform_emails"
                            :open="ask_transform"
                            @confirm="onPark"
                            @close="ask_transform = false" />
                        <hr />
                    </b-form-group>

                    <!-- Cancel this domain -->
                    <b-form-group label="Cancel This Domain" label-size="lg">
                        <p>
                            If you no longer need this domain, you may cancel it by clicking the
                            button below. When you cancel a domain, you will no longer be billed for
                            it on future invoices. It will immediately be deactivated. At its next
                            expiration period it will become available for purchase by anyone in the
                            world who is interested in the name.
                        </p>
                        <p>
                            If instead you want to retain control of this domain but discontinue
                            utilizing our services for it, please contact our support staff to
                            transfer the domain to another registrar.
                        </p>
                        <div
                            v-if="ask_cancel"
                            class="d-flex justify-content-end align-items-center">
                            <div class="pr-3">Are you sure?</div>
                            <b-button
                                variant="danger"
                                :disabled="Boolean(saving)"
                                @click="onCancel">
                                <Icon v-if="saving === 'cancel'" i="spinner" spin />Yes
                            </b-button>
                            <b-button
                                variant="secondary-outline"
                                :disabled="Boolean(saving)"
                                @click="ask_cancel = false"
                                >Nevermind</b-button
                            >
                        </div>
                        <div v-else class="d-flex justify-content-end">
                            <b-button
                                variant="danger"
                                :disabled="Boolean(saving)"
                                @click="ask_cancel = true"
                                >Cancel Domain</b-button
                            >
                        </div>
                    </b-form-group>
                </div>
            </b-tab>
        </b-tabs>
    </div>
    <FourOhFour v-else />
</template>
<script>
import PageContainer from '@/components/PageContainer.vue';
import FourOhFour from '@/views/FourOhFour.vue';
import EmailRedirects from '@/components/EmailRedirects.vue';
import TimeRange from '@/components/TimeRange.vue';
import BillingChange from '@/components/BillingChange.vue';
import InternDomainMessage from '@/components/InternDomainMessage.vue';
import GlossaryLink from '@/components/glossary/GlossaryLink';
import InfoLink from '@/components/glossary/InfoLink';

import { format, addDays } from 'date-fns';
import cache from '@/stats_cache';
import axios from 'axios';
import _ from 'lodash';
import { mapGetters } from 'vuex';

export default {
    name: 'DomainDetail',
    components: {
        PageContainer,
        EmailRedirects,
        TimeRange,
        BillingChange,
        FourOhFour,
        InternDomainMessage,
        GlossaryLink,
        InfoLink,
    },
    data() {
        return {
            tabIndex: 0,
            domain_name: this.$route.params.domain_name,
            active_tab: this.$route.params.tab,
            graphs: null,
            emails_open: false,
            domainVerification: null,
            cookieManagementID: null,
            settings_open: false,
            start_date: null,
            end_date: null,
            new_meta_description: null,
            new_parked_target: null,
            saving: false,
            ask_transform: false,
            ask_cancel: false,
        };
    },
    computed: {
        ...mapGetters(['isAdmin', 'isIntern']),
        meta_description_length() {
            return this.new_meta_description ? this.new_meta_description.length : 0;
        },
        bread() {
            return [
                { text: 'Dashboard', to: '/' },
                { text: 'Domains', to: '/domains' },
                { text: this.domain_name, to: '/domains/', active: true },
            ];
        },
        loading() {
            return !this.domain_data;
        },
        current_range() {
            if (!this.start_date || !this.end_date) {
                return '';
            }
            const start_string = format(this.start_date, 'MMM d, yyyy');
            const end_string = format(this.end_date, 'MMM d, yyyy');
            return `${start_string} to ${end_string}`;
        },
        domain_data() {
            return this.$store.getters.domain(this.domain_name);
        },
        other_domains() {
            return this.$store.getters.domains
                .filter((domain) => {
                    return domain.domain_type == 'c1' && !domain.transfer_id;
                })
                .map((domain) => domain.name);
        },
        domain_in_account() {
            return _.some(this.$store.getters.domains, (domain) => {
                return this.domain_name == domain.name;
            });
        },
        account_number() {
            return this.$store.state.current_account.account_number;
        },
        transform_emails() {
            if ((this.domain_data || {}).domain_type == 'parked') {
                return -1 * this.domain_data.emails.length;
            } else if ((this.domain_data || {}).domain_type == 'c1') {
                return this.domain_data.emails.length;
            } else {
                return 0;
            }
        },
        parked_domain() {
            return this.domain_data.domain_type == 'parked';
        },
        c1_domain() {
            return this.domain_data.domain_type == 'c1';
        },
        domain_api_url() {
            return `/backend/api/frontend/accounts/${this.account_number}/domains/${this.domain_name}/`;
        },
    },
    watch: {
        domain_data(new_domain, old_domain) {
            if (!old_domain) {
                this.new_meta_description = new_domain.meta_description;
                this.new_parked_target = new_domain.parked_target;
                this.cookieManagementID = new_domain.cookie_management_id;
                this.domainVerification = new_domain.google_domain_verification;
            }
        },
        new_meta_description(text, old_text) {
            if (text && text.length > 156) {
                if (old_text && old_text.length == 156) {
                    this.new_meta_description = old_text;
                } else {
                    this.new_meta_description = text.slice(0, 156);
                }
            }
        },
    },
    mounted() {
        if (_.indexOf(['stats', 'emails', 'settings'], this.active_tab) < 0) {
            this.setActiveTab('stats');
        }
        this.new_meta_description = this.domain_data ? this.domain_data.meta_description : null;
        this.new_parked_target = this.domain_data ? this.domain_data.parked_target : null;
        this.cookieManagementID = this.domain_data ? this.domain_data.cookie_management_id : null;
        this.domainVerification = this.domain_data
            ? this.domain_data.google_domain_verification
            : null;
    },

    created() {
        this.build_graph(null);
    },
    methods: {
        setActiveTab(tab) {
            this.$router.push(`/domains/${this.domain_name}/${tab}`);
        },
        linkClass(idx) {
            if (this.tabIndex === idx) {
                return ['bg-white', 'text-black', 'margins'];
            } else {
                return ['bg-primary', 'text-white', 'margins'];
            }
        },
        onNewDate(start, end, title) {
            this.start_date = start;
            this.end_date = end;
            const is_initial = title === 'Last 30 Days';

            this.loadStats(
                start.toISOString().slice(0, 10),
                end.toISOString().slice(0, 10),
                is_initial
            );
        },
        toggleEmails() {
            this.emails_open = !this.emails_open;
            this.settings_open = false;
        },
        toggleSettings() {
            this.settings_open = !this.settings_open;
            this.emails_open = false;
        },
        refresh_domain() {
            return this.$store.dispatch('get_domains');
        },

        show_alert(message, varient = 'success') {
            this.$store.commit('add_alert', {
                message,
                varient,
            });
        },

        loadStats(start_string, end_string, is_initial) {
            let cached_data = cache.retrieve('domain_stats2', this.domain_name);
            if (is_initial && cached_data) {
                return this.build_graph(cached_data);
            }

            axios
                .get(
                    `/backend/api/frontend/accounts/${this.account_number}/stats/?graph=indepth&target=${this.domain_name}&start=${start_string}&end=${end_string}`
                )
                .then((resp) => {
                    this.build_graph(resp.data);
                    if (is_initial) {
                        cache.cache('domain_stats2', this.domain_name, resp.data, 1);
                    }
                })
                .catch((err) => {
                    this.show_alert(
                        'There was a problem loading stats, please try again later',
                        'danger'
                    );
                    console.log(err);
                });
        },

        onSaveMetadata() {
            this.saving = 'meta';
            axios
                .patch(this.domain_api_url, {
                    meta_description: this.new_meta_description,
                })
                .then(() => {
                    this.show_alert('Meta description saved successfully.');
                    this.saving = false;
                })
                .catch((err) => {
                    this.show_alert(
                        'There was a problem saving your metadata description.',
                        'danger'
                    );
                    this.saving = false;
                });
        },
        onSaveDomainVerification() {
            this.saving = 'domainVerification';
            axios
                .patch(this.domain_api_url, {
                    google_domain_verification: this.domainVerification,
                })
                .then(() => {
                    this.show_alert('Google Domain Verification record saved successfully.');
                    this.saving = false;
                })
                .catch((err) => {
                    this.show_alert(
                        'There was a problem saving your Google Domain Verification record.',
                        'danger'
                    );
                    this.saving = false;
                });
        },
        onSaveCookieManagementID() {
            this.saving = 'cookieManagementID';
            axios
                .patch(this.domain_api_url, {
                    cookie_management_id: this.cookieManagementID,
                })
                .then(() => {
                    this.show_alert('Cookie Management ID saved successfully.');
                    this.saving = false;
                })
                .catch((err) => {
                    this.show_alert(
                        'There was a problem saving the Cookie Management ID.',
                        'danger'
                    );
                    this.saving = false;
                });
        },
        onRevertMetadata() {
            this.saving = 'meta';
            axios
                .patch(this.domain_api_url, {
                    meta_description: null,
                })
                .then(() => {
                    this.show_alert('Metadata description reverted successfully.');
                    this.saving = false;
                })
                .catch((err) => {
                    this.show_alert(
                        'There was a problem reverting the metadata description.',
                        'danger'
                    );
                    this.saving = false;
                });
        },
        onSaveParkedTarget() {
            this.saving = 'target';
            axios
                .patch(this.domain_api_url, {
                    parked_target: this.new_parked_target,
                })
                .then(() => {
                    this.show_alert('Parked target saved successfully.');
                    this.saving = false;
                })
                .catch((err) => {
                    this.show_alert('There was a problem saving the parked target.', 'danger');
                    this.saving = false;
                });
        },

        onPark() {
            this.saving = 'transform';
            axios
                .patch(this.domain_api_url, {
                    domain_type: 'parked',
                })
                .then((resp) => {
                    return this.$store.dispatch('invalidate_domains');
                })
                .then(() => {
                    this.saving = false;
                    this.$router.push('/domains');
                })
                .catch((err) => {
                    this.show_alert(
                        'Encountered an error while processing your request. Please try again later!',
                        'danger'
                    );
                    console.log(err);
                });
        },
        onFull() {
            this.saving = 'transform';
            axios
                .patch(this.domain_api_url, {
                    domain_type: 'c1',
                })
                .then((resp) => {
                    return this.$store.dispatch('invalidate_domains');
                })
                .then(() => {
                    this.saving = false;
                    this.$router.push('/domains');
                })
                .catch((err) => {
                    this.show_alert(
                        'Encountered an error while processing your request. Please try again later!',
                        'danger'
                    );
                    console.log(err);
                });
        },
        onCancel() {
            this.saving = 'cancel';
            axios
                .delete(this.domain_api_url, {
                    domain_type: 'c1',
                })
                .then((resp) => {
                    return this.$store.dispatch('invalidate_domains');
                })
                .then(() => {
                    this.$router.push('/domains');
                })
                .catch((err) => {
                    this.show_alert(
                        'Encountered an error while canceling your domain. Please try again later!',
                        'danger'
                    );
                    console.log(err);
                })
                .finally(() => {
                    this.saving = false;
                });
        },

        build_graph(stats) {
            this.graphs = {
                time: {
                    chart: {
                        type: 'area',
                        height: '200px',
                    },
                    title: {
                        text: undefined,
                    },
                    credits: false,
                    series: [
                        {
                            name: 'Page Views',
                            data: stats
                                ? stats.graph.map((item, x) => {
                                      return {
                                          x: Number(new Date(item.date)),
                                          y: item.page_views,
                                      };
                                  })
                                : [],
                            fillColor: {
                                linearGradient: [0, 0, 0, 120],
                                stops: [
                                    [0, 'rgba(0, 222, 0, 1)'],
                                    [1, 'rgba(0, 255, 0, 0)'],
                                ],
                            },
                            color: 'rgba(0, 200, 0, 1)',
                        },
                    ],
                    xAxis: {
                        type: 'datetime',
                        dateTimeLabelFormats: {
                            day: '%b %e',
                        },
                    },
                    yAxis: {
                        title: { text: 'Page Views' },
                    },
                },
                device_type: {
                    chart: {
                        type: 'pie',
                        height: '200px',
                    },
                    title: {
                        text: undefined,
                    },
                    credits: false,
                    series: [
                        {
                            data: stats
                                ? _(stats.pies.device_type)
                                      .map((hits, label) => {
                                          const readableNames = {
                                              console: 'Console',
                                              smarttv: 'Smart TV',
                                              tablet: 'Tablet',
                                              mobile: 'Mobile',
                                              desktop: 'Desktop',
                                              Unknown: 'Unknown',
                                          };
                                          return {
                                              name: readableNames[label],
                                              y: hits,
                                          };
                                      })
                                      .shuffle()
                                      .value()
                                : [],
                        },
                    ],
                },
                overview: stats
                    ? [
                          {
                              title: 'Page Views',
                              value: stats.stats.page_views,
                              keyword: 'page_view',
                          },
                          {
                              title: 'Visits',
                              value: stats.stats.visits,
                              keyword: 'visits',
                          },
                          {
                              title: 'Quote Starts',
                              value: stats.stats.quotestarts,
                              keyword: 'quote_starts',
                          },
                          {
                              title: 'Contact Forms Sent',
                              value: stats.stats.contactforms,
                              keyword: 'contact_forms_sent',
                          },
                          {
                              title: 'Phone Clicks',
                              value: stats.stats.phone_clicks,
                              keyword: 'phone_clicks',
                          },
                          {
                              title: 'Map & Directions Clicks',
                              value: stats.stats.map_clicks,
                              keyword: 'map_directions_clicks',
                          },
                          {
                              title: 'Review Clicks',
                              value: stats.stats.google_review_clicks,
                              keyword: 'review_clicks',
                          },
                      ]
                    : null,
                tables: [
                    {
                        title: 'Page Views by Referrer',
                        keyword: 'page_views_by_referrer',
                        fields: ['Domain', 'Page Views'],
                        items: stats
                            ? _(stats.pies.referrer)
                                  .map((value, key) => {
                                      return {
                                          label: key && key.length ? key : 'None',
                                          hits: value,
                                      };
                                  })
                                  .orderBy(['hits'], ['desc'])
                                  .filter((item) => {
                                      return item.hits > 0;
                                  })
                                  .value()
                            : [],
                    },
                    {
                        title: 'Links Clicked to External Sites',
                        keyword: 'links_clicked_to_external_sites',
                        fields: ['Link', 'Clicks'],
                        items: stats
                            ? _(stats.pies.external_links)
                                  .map((value, key) => {
                                      return {
                                          label: key && key.length ? key : 'Unknown',
                                          hits: value,
                                      };
                                  })
                                  .orderBy(['hits'], ['desc'])
                                  .filter((item) => {
                                      return item.hits > 0;
                                  })
                                  .value()
                            : [],
                    },
                    {
                        title: 'Bots and Crawlers Traffic (this data is not included in other sites)',
                        keyword: 'bots_crawlers',

                        fields: ['Type', 'Page Views'],
                        items: stats
                            ? _(stats.pies.bot_browser)
                                  .map((value, key) => {
                                      return {
                                          label: key && key.length ? key : 'Unknown',
                                          hits: value,
                                      };
                                  })
                                  .orderBy(['hits'], ['desc'])
                                  .filter((item) => {
                                      return item.hits > 0;
                                  })
                                  .value()
                            : [],
                    },
                ],
            };
        },
    },
};
</script>
<style lang="scss" scoped>
.description_warning {
    color: $orange;
}

.page_main_col {
    // max-width: 1024px;
    padding: 15px;
    text-align: left;
}

.domain_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.icon-flip {
    transition: all 0.3s ease;
    transform: rotateX(0deg);
    -moz-transform: rotateX(0deg);
}

.flip {
    transform: rotateX(180deg);
    -moz-transform: rotateX(180deg);
}

.even-row {
    margin: 0 15px;
}

.stats-table {
    .title {
        font-size: 18px;
        font-weight: 300;
        text-align: center;
        margin-top: 20px;
    }
    .row-wrapper {
        height: 200px;
        overflow-y: scroll;
        overflow-x: hidden;
        padding: 0 5px;
    }
    .row-table {
        width: 100%;
        table-layout: fixed;
    }
    .row-title {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow-x: hidden;
    }
    .row-value {
        text-align: right;
        width: 4em;
    }
}

.meta-description {
    margin-bottom: 10px;
}

.margin-top-7px {
    margin-top: 7px;
}

.admin-notice {
    padding: 0.2rem;
    border-radius: 2px;
    border: 2px solid $yellow;
}

.stats_item {
    text-align: center;
    flex-grow: 1;
    flex-basis: 0;
    padding: 0 5px;
}
</style>
