<template>
    <b-modal :visible="open" @hide="onClose" title="Review Billing and Confirm Change">
        <div v-if="loading"><Icon i="spinner" spin/> Loading Billing Changes</div>
        <div v-else>
            <p>Charge Today: <b>${{billing_data.prorated}}</b></p>
            <p>New Monthly Rate: <b>${{billing_data.recurring}}</b><br />(every month starting on {{billing_data.next_billing_day}})
            </p>
        </div>

        <div slot="modal-footer">
            <b-button-group>
                <b-button
                    variant="primary"
                    @click="onConfirm"
                    :disabled="billing_data === null"
                >
                    Confirm
                </b-button>
                <b-button variant="outline-secondary" @click="onClose">Nevermind</b-button>
            </b-button-group>
        </div>
    </b-modal>
</template>

<script>
import axios from 'axios';
import { parse, format } from 'date-fns';
export default {
    props: {
        action: String,
        emails: Number,
        open: Boolean,
    },
    data() {
        return {
            billing_data: null,
        };
    },
    computed: {
        loading() {
            return !this.billing_data;
        },
        account_number() {
            return this.$store.state.current_account.account_number;
        },
    },
    watch: {
        open(open, old_open) {
            if (open && !this.billing_data) {
                const emails = this.emails || 0;
                axios
                    .get(
                        `/backend/api/frontend/accounts/${this.account_number}/preview/?action=${
                            this.action
                        }&emails=${emails}`,
                    )
                    .then((resp) => {
                        this.billing_data = {
                            recurring: Number(resp.data.recurring).toFixed(2),
                            prorated: Number(resp.data.prorated).toFixed(2),
                            next_billing_day: format(
                                parse(resp.data.next_billing_day, 'yyyy-MM-dd', new Date()),
                                'MMM do, yyyy',
                            ),
                        };
                    })
                    .catch((err) => {
                        this.$store.commit('add_alert', {
                            message:
                                'Something went wrong while calculating your billing changes. Please try again later!',
                            varient: 'danger',
                        });
                        console.log(err);
                    });
            }
        },
    },
    methods: {
        onConfirm() {
            this.$emit('confirm');
        },
        onClose(e) {
            if (this.open) {
                this.$emit('close');
                e.preventDefault();
                this.billing_data = null;
            }
        },
    },
};
</script>

<style>
</style>
