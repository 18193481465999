<template>
    <div class="paper page_main_col col-12 offset-lg-1 col-lg-10 mb-3">
        <div class="header_row">
            <h3>My Domains</h3>
            <div>
                <b-button to="/domains/purchase" variant="primary">
                    <Icon i="plus" /> Purchase a Domain
                </b-button>
                <b-button to="/domains/transfer" variant="outline-secondary">
                    <Icon i="arrow-right" /> Transfer a Domain
                </b-button>
            </div>
        </div>
        <InternDomainMessage />
        <slot></slot>
        <div class="table-responsive rpt">
            <table class="table domains_table">
                <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Page Views</th>
                    <th scope="col"></th>
                    <th scope="col"></th>
                </tr>
                <tr v-if="microsites.length > 0">
                    <td colspan="4" scope="colgroup"><strong>Enhanced Microsites</strong></td>
                </tr>
                <tr v-for="row in microsites" :key="row.name">
                    <td>
                        <strong>
                            <router-link :to="`/domains/${row.name}/`">
                                {{ row.name }}
                            </router-link>
                        </strong>
                    </td>

                    <td v-if="!domain_stats || typeof domain_stats[row.name] === 'undefined'">
                        <Icon i="spinner" spin />
                    </td>
                    <td v-else>{{ domain_stats[row.name] }}</td>
                    <td>
                        <b-button-group class="buttons">
                            <b-button
                                v-if="row.domain_type === 'c1'"
                                :href="`https://${row.name}?editing`"
                                target="_blank"
                                variant="outline-secondary"
                                :disabled="isIntern"
                                >Edit Site</b-button
                            >
                            <b-button
                                :href="`https://${row.name}`"
                                target="_blank"
                                variant="outline-secondary"
                                :disabled="isIntern"
                                >View Site</b-button
                            >
                        </b-button-group>
                    </td>
                    <td>
                        <b-button-group class="buttons">
                            <b-button
                                :to="`/domains/${row.name}/stats`"
                                variant="outline-secondary"
                                :disabled="isIntern"
                                >Stats</b-button
                            >
                            <b-button
                                :to="`/domains/${row.name}/emails`"
                                variant="outline-secondary"
                                >Emails</b-button
                            >
                            <b-button
                                :to="`/domains/${row.name}/settings`"
                                variant="outline-secondary"
                                >Settings</b-button
                            >
                        </b-button-group>
                    </td>
                </tr>
                <tr v-if="parkedDomains.length > 0">
                    <th colspan="4" scope="colgroup">Parked Domains</th>
                </tr>
                <tr v-for="row in parkedDomains" :key="row.name">
                    <th scope="row">
                        <strong>
                            <router-link :to="`/domains/${row.name}/`">
                                {{ row.name }}
                            </router-link>
                        </strong>
                    </th>
                    <td></td>
                    <td>
                        <b-button-group class="buttons parked">
                            <span
                                v-b-tooltip.hover
                                title="Edit Site is not available for Parked Domains">
                                <b-button
                                    disabled
                                    :to="`/domains/${row.name}/stats`"
                                    variant="outline-secondary"
                                    >Edit Site</b-button
                                >
                            </span>
                            <b-button
                                :href="`https://${row.name}`"
                                target="_blank"
                                variant="outline-secondary"
                                >View Site</b-button
                            >
                        </b-button-group>
                    </td>
                    <td>
                        <b-button-group class="buttons parked">
                            <span
                                v-b-tooltip.hover
                                title="Stats are not available for Parked Domains">
                                <b-button
                                    disabled
                                    :to="`/domains/${row.name}/stats`"
                                    variant="outline-secondary"
                                    >Stats</b-button
                                >
                            </span>
                            <b-button
                                :to="`/domains/${row.name}/emails`"
                                variant="outline-secondary"
                                >Emails</b-button
                            >
                            <b-button
                                :to="`/domains/${row.name}/settings`"
                                variant="outline-secondary"
                                >Settings</b-button
                            >
                        </b-button-group>
                    </td>
                </tr>
                <tr v-if="transferDomains.length > 0">
                    <th colspan="4" scope="colgroup">Pending Transfers</th>
                </tr>

                <tr v-for="row in transferDomains" :key="row.name">
                    <th scope="row" colspan="4" class="green">
                        <strong>{{ row.name }}</strong>
                    </th>
                </tr>
            </table>
            <p v-if="loading" class="px-3"><Icon i="spinner" spin />Loading...</p>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

import InternDomainMessage from '@/components/InternDomainMessage.vue';

export default {
    name: 'Domains',
    components: { InternDomainMessage },
    props: { domain_stats: Object },
    data: () => {
        return {
            type_titles: {
                c1: 'Enhanced Microsite',
                parked: 'Parked Domain',
            },
        };
    },
    computed: {
        ...mapGetters(['domains', 'account', 'isIntern']),
        loading() {
            return !this.domains;
        },

        sortedDomains() {
            return this.domains?.sort((a, b) => a.name.localeCompare(b.name));
        },
        activeDomains() {
            return this.sortedDomains?.filter(
                ({ transfer_id, whois_admin_email }) =>
                    transfer_id == null || this.isMirusWhoisEmail(whois_admin_email)
            );
        },
        microsites() {
            return this.activeDomains?.filter(({ domain_type }) => domain_type === 'c1');
        },
        parkedDomains() {
            return this.activeDomains?.filter(({ domain_type }) => domain_type === 'parked');
        },
        transferDomains() {
            return this.sortedDomains?.filter(
                (domain) => !this.activeDomains.includes(domain) // anything not in active is a transfer
            );
        },
    },
    methods: {
        isMirusWhoisEmail(email) {
            return ['mysfdomain', 'mirusresearch', 'mydadomain'].includes(email);
        },
    },
};
</script>
<style lang="scss" scoped>
.page_main_col {
    // max-width: 1024px;
    padding: 15px;
    text-align: left;
}

.header_row {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 15px;
}

.domains_table {
    th,
    td {
        text-align: left;
        vertical-align: middle;
        &:first-child {
            padding-left: 15px;
        }
        &:nth-child(2) {
            width: 10%;
        }
        &:nth-last-child(2),
        &:last-child {
            width: 20%;
        }
    }
}

.no-pending-transfers {
    width: 100%;
    text-align: center;
}
.buttons {
    width: 100%;
    white-space: nowrap;
    justify-content: flex-end;
    & > * {
        flex: 0 1 auto !important;
    }
    & > span > a {
        width: 100%;
    }
}
.green {
    color: $green;
}
</style>
