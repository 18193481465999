export default {
    get: {
        graph: [
            {
                date: '2023-06-17',
                page_views: 0,
            },
            {
                date: '2023-06-18',
                page_views: 0,
            },
            {
                date: '2023-06-19',
                page_views: 1,
            },
            {
                date: '2023-06-20',
                page_views: 1,
            },
            {
                date: '2023-06-21',
                page_views: 6,
            },
            {
                date: '2023-06-22',
                page_views: 0,
            },
            {
                date: '2023-06-23',
                page_views: 0,
            },
            {
                date: '2023-06-24',
                page_views: 0,
            },
            {
                date: '2023-06-25',
                page_views: 1,
            },
            {
                date: '2023-06-26',
                page_views: 11,
            },
            {
                date: '2023-06-27',
                page_views: 0,
            },
            {
                date: '2023-06-28',
                page_views: 3,
            },
            {
                date: '2023-06-29',
                page_views: 0,
            },
            {
                date: '2023-06-30',
                page_views: 35,
            },
            {
                date: '2023-07-01',
                page_views: 4,
            },
            {
                date: '2023-07-02',
                page_views: 0,
            },
            {
                date: '2023-07-03',
                page_views: 126,
            },
            {
                date: '2023-07-04',
                page_views: 33,
            },
            {
                date: '2023-07-05',
                page_views: 0,
            },
            {
                date: '2023-07-06',
                page_views: 9,
            },
            {
                date: '2023-07-07',
                page_views: 0,
            },
            {
                date: '2023-07-08',
                page_views: 0,
            },
            {
                date: '2023-07-09',
                page_views: 0,
            },
            {
                date: '2023-07-10',
                page_views: 5,
            },
            {
                date: '2023-07-11',
                page_views: 15,
            },
            {
                date: '2023-07-12',
                page_views: 13,
            },
            {
                date: '2023-07-13',
                page_views: 2,
            },
            {
                date: '2023-07-14',
                page_views: 0,
            },
            {
                date: '2023-07-15',
                page_views: 0,
            },
            {
                date: '2023-07-16',
                page_views: 0,
            },
        ],
        stats: {
            visits: 62,
            page_views: 265,
            quotestarts: 0,
            phone_clicks: 0,
            sms_clicks: 0,
            map_clicks: 0,
            google_review_clicks: 0,
            contactforms: 0,
        },
        pies: {
            browser: {
                Firefox: 18,
                'Mobile Safari': 1,
                Chrome: 251,
            },
            referrer: {
                Unknown: 242,
                'c1-ui.local': 26,
                'mydadomain.com': 2,
            },
            os: {
                Unknown: 270,
            },
            device_type: {
                desktop: 269,
                mobile: 1,
            },
            bot_browser: {
                Chrome: 2,
                Other: 68,
            },
            city: {},
            external_links: {},
        },
        domain_counts: {
            'roundbacon.com': 265,
            'myauroraagent.com': 94,
            'insurance-man.com': 7,
            'roundbacon2.com': 1,
        },
    },
};
