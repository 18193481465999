<template>
    <font-awesome-icon class="fa-override" :icon="icon" :spin="spin"></font-awesome-icon>
</template>
<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import {
    faSpinner,
    faUser,
    faPlus,
    faArrowRight,
    faExternalLink,
    faBars,
    faChevronUp,
    faChevronDown,
    faCircle,
    faCheckCircle,
    faLifeRing,
    faSearch,
    faRedo,
    faCog,
    faLock,
    faLockOpen,
    faCheck,
    faTimes,
    faFile,
    faLightbulbOn,
    faInfoCircle,
    faSort,
    faSortUp,
    faSortDown,
} from '@fortawesome/pro-light-svg-icons';
import {
    faCcVisa,
    faCcMastercard,
    faCcDiscover,
    faCcAmex,
    faCcDinersClub,
    faCcJcb,
} from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

library.add(
    faSpinner,
    faUser,
    faPlus,
    faArrowRight,
    faExternalLink,
    faBars,
    faChevronUp,
    faChevronDown,
    faCircle,
    faCheckCircle,
    faLifeRing,
    faSearch,
    faRedo,
    faCog,
    faLock,
    faLockOpen,
    faCheck,
    faTimes,
    faCcVisa,
    faCcMastercard,
    faCcDiscover,
    faCcAmex,
    faCcDinersClub,
    faCcJcb,
    faFile,
    faLightbulbOn,
    faInfoCircle,
    faSort,
    faSortUp,
    faSortDown
);

export default {
    name: 'Icon',
    components: {
        FontAwesomeIcon,
    },
    props: {
        i: { type: String, default: null },
        spin: { type: Boolean, default: false },
        prefix: { type: String, default: 'fal' },
    },
    computed: {
        icon() {
            return [this.prefix, this.i];
        },
    },
};
</script>
<style lang="scss" scoped>
.fa-override {
    margin-top: -0.25em;
}
</style>
