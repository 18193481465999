<template>
    <b-form inline v-if="!show_custom">
        <label for="time-range" class="pr-3">Time Range</label>
        <b-form-select id="time-range" @change="onSelect" :value="selected" :options="timeranges" />
    </b-form>

    <b-form v-else inline>
        <label class="mx-1">Select Time Range</label>
        <b-form-datepicker
            v-model="custom_start"
            calendar-width="20rem"
            placeholder="Start Date"
            :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit' }"
        />
        <b-form-datepicker
            v-model="custom_end"
            calendar-width="20rem"
            placeholder="End Date"
            :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit' }"
        />
        <b-button-group class="mx-1">
            <b-btn variant="outline-secondary" @click="onCustom">Set</b-btn>
            <b-btn variant="outline-secondary" @click="onCancelCustom">Cancel</b-btn>
        </b-button-group>
    </b-form>
</template>

<script>
import { startOfDay, addDays, subDays, subMonths, subYears, format, parse } from 'date-fns';

export default {
    name: 'TimeRange',
    components: {},
    props: {
        condensed: false,
    },
    data() {
        return {
            timeranges: [
                'Last 30 Days',
                'Last 60 Days',
                'Last 90 Days',
                'Last 6 Months',
                'Last Year',
                'Custom',
            ],
            show_start: false,
            show_end: false,

            custom_start: null,
            custom_end: null,

            selected: null,
            previous_selected: null,

            custom_label: null,
            custom_label_start: null,
            custom_label_end: null,

            display_format: 'MMM d, yyyy',
            iso_format: 'yyyy-MM-dd',
            picker_format: 'yyyy/MM/dd',

            show_custom: false,
        };
    },
    computed: {
        custom_start_label() {
            if (!this.custom_start) {
                return 'Start Date';
            }
            return format(this.custom_start, this.picker_format);
        },
        custom_end_label() {
            if (!this.custom_end) {
                return 'End Date';
            }
            return format(this.custom_end, this.picker_format);
        },
        min() {
            if (!this.custom_start) {
                return null;
            }
            return format(
                addDays(parse(this.custom_start, this.iso_format, new Date()), 1),
                this.iso_format,
            );
        },
        max() {
            if (!this.custom_end) {
                return null;
            }
            return format(
                subDays(parse(this.custom_end, this.iso_format, new Date()), 1),
                this.iso_format,
            );
        },
    },
    mounted() {
        this.onSelect(this.timeranges[0]);
    },
    methods: {
        onSelect(label) {
            this.previous_selected = this.selected;
            this.selected = label;
            if (label == this.custom_label) {
                this.selectRange(this.custom_label_start, this.custom_label_end, label);
            } else if (label == 'Custom') {
                this.show_custom = true;
            } else {
                const [start, end] = this.getDateRange(label);
                this.selectRange(start, end, label);
            }
        },

        getDateRange(label) {
            let end = startOfDay(new Date());
            let start = null;

            if (label == 'Last 30 Days') {
                start = subDays(end, 30);
            } else if (label == 'Last 60 Days') {
                start = subDays(end, 60);
            } else if (label == 'Last 90 Days') {
                start = subDays(end, 90);
            } else if (label == 'Last 6 Months') {
                start = subMonths(end, 6);
            } else if (label == 'Last Year') {
                start = subYears(end, 1);
            }
            return [start, end];
        },
        selectRange(start, end, title) {
            this.$emit('change', start, end, title);
        },
        onCancelCustom() {
            this.selected = this.previous_selected;
            this.show_custom = false;
        },
        onCustom() {
            if (!this.custom_start && !this.custom_end) {
                return;
            }
            if (this.timeranges[0] == this.custom_label) {
                this.timeranges.shift();
            }
            const start = parse(this.custom_start, this.iso_format, new Date());
            const end = parse(this.custom_end, this.iso_format, new Date());

            const start_string = format(start, this.display_format);
            const end_string = format(end, this.display_format);

            const label = `${start_string} to ${end_string}`;

            this.timeranges.unshift(label);
            this.selected = label;
            this.custom_label = label;
            this.custom_label_start = start;
            this.custom_label_end = end;
            this.show_custom = false;

            this.selectRange(start, end, label);
        },
    },
};
</script>

<style>
.picker-input {
    max-width: 8em;
    display: inline;
}
.breakent {
    min-width: 22em;
}
</style>
