<template>
    <div class="page_main_col col-12 offset-lg-1 col-lg-10 mb-5">
        <b-tabs class="paper-tabs">
            <b-tab
                v-for="tab in tabs"
                :active="tab.active"
                @click="$router.push(tab.path)"
                :title-link-class="tab.classes"
                :key="tab.title">
                <template slot="title">
                    <div class="title">{{ tab.title }}</div>
                </template>
            </b-tab>
        </b-tabs>
        <div class="paper text-left p-3 mb-5">
            <router-view />
        </div>
    </div>
</template>
<script>
import PageContainer from '@/components/PageContainer.vue';
import TimeRange from '@/components/TimeRange.vue';
import _ from 'lodash';
import GlossaryLink from '@/components/glossary/GlossaryLink';

export default {
    name: 'Campaigns',
    components: {
        PageContainer,
        TimeRange,
        GlossaryLink,
    },
    data: function () {
        return {};
    },
    methods: {},
    computed: {
        tabs() {
            const tabs = [
                {
                    title: 'Stats',
                    path: '/campaigns',
                },
                {
                    title: 'Link Generator',
                    path: '/campaigns/tools',
                },
            ];

            return tabs.map((row) => {
                const active = this.$route.path === row.path;
                return {
                    ...row,
                    active,
                    classes: active
                        ? ['bg-white', 'text-black', 'margins']
                        : ['bg-primary', 'text-white', 'margins'],
                };
            });
        },
    },
};
</script>
<style lang="scss" scoped>
.qlp-link {
    font-size: 1.2em;
    font-weight: bold;
    text-align: center;
}
.qlp-box {
    margin-top: 0cm;
}
</style>
