<template>
    <div>
        <transition-group name="announcement-list" tag="div">
            <b-alert
                v-for="(announcement, index) in announcements"
                v-bind:key="announcement.message"
                v-if="visible(announcement)"
                v-bind:data-index="index"
                v-on:dismissed="dismiss(index)"
                :variant="announcement.variant"
                :dismissible="announcement.dismissable"
                class="border-radius-none side-padding-none"
                show
                fade
            >
                <div class="col-12 offset-lg-1 col-lg-10 text-left">
                    <h4 v-if="announcement.header" class="alert-heading">
                        {{ announcement.header }}
                    </h4>
                    <p class="overflow-y-auto" v-html="announcement.message" />
                </div>
            </b-alert>
        </transition-group>
    </div>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import _ from 'lodash';

export default {
    name: 'Announcements',
    data() {
        return {
            announcement_interval: null,
        };
    },
    mounted() {
        this.$store.dispatch('get_announcements');
        this.announcement_interval = setInterval(() => {
            this.$store.dispatch('get_announcements');
        }, 30000);
    },
    destroyed() {
        clearInterval(this.announcement_interval);
    },
    computed: mapGetters(['account_number', 'announcements']),
    methods: {
        dismiss(index) {
            this.announcements.splice(index, 1);
        },
        visible(announcement) {
            // here we can do time checks
            return _.get(announcement, 'visible', true);
        },
    },
};
</script>

<style lang="scss">
.alert {
    &.side-padding-none {
        padding-left: 0px;
        padding-right: 0px;
    }
}

.border-radius-none {
    border-radius: 0px;
}

.overflow-y-auto {
    overflow-y: auto;
}

.announcement-list-enter-active,
.announcement-list-leave-active {
    transition: all 0.5s;
    animation-timing-function: ease-in-out;
}
.announcement-list-enter,
.announcement-list-leave-to {
    opacity: 0;
    transform: translateY(-150px);
}
</style>
