export default {
    get: {
        subscription: {
            status: 'Active',
            error: null,
            next_billing: '2023-08-15T15:05:52-05:00',
            current_rate: 196.0,
            recent_transactions: [
                {
                    amount: 196.0,
                    created_at: '2023-07-15T15:21:49-05:00',
                    memo: 'Jean Dupont - Enhanced Microsite: Renewal payment',
                    last_four: '1111',
                    card_type: 'visa',
                    amount_refunded: 0.0,
                    success: true,
                },
                {
                    amount: 199.27,
                    created_at: '2023-06-15T15:21:52-05:00',
                    memo: 'Jean Dupont - Enhanced Microsite: Renewal payment',
                    last_four: '1111',
                    card_type: 'visa',
                    amount_refunded: 0.0,
                    success: true,
                },
                {
                    amount: 166.0,
                    created_at: '2023-05-15T15:20:24-05:00',
                    memo: 'Jean Dupont - Enhanced Microsite: Renewal payment',
                    last_four: '1111',
                    card_type: 'visa',
                    amount_refunded: 0.0,
                    success: true,
                },
                {
                    amount: 166.0,
                    created_at: '2023-04-15T15:21:59-05:00',
                    memo: 'Jean Dupont - Enhanced Microsite: Renewal payment',
                    last_four: '1111',
                    card_type: 'visa',
                    amount_refunded: 0.0,
                    success: true,
                },
                {
                    amount: 214.99,
                    created_at: '2023-03-15T15:22:10-05:00',
                    memo: 'Jean Dupont - Enhanced Microsite: Renewal payment',
                    last_four: '1111',
                    card_type: 'visa',
                    amount_refunded: 0.0,
                    success: true,
                },
                {
                    amount: 110.0,
                    created_at: '2023-02-15T14:05:54-06:00',
                    memo: 'Jean Dupont - Enhanced Microsite: Signup payment',
                    last_four: '1111',
                    card_type: 'visa',
                    amount_refunded: 0.0,
                    success: true,
                },
            ],
        },
    },
};
