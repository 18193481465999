export default {
    get: {
        page: 1,
        page_size: 10,
        total_items: 6,
        total_pages: 1,
        statements: [
            {
                id: 436998762,
                customer_facing_id: 6,
                customer_facing_created: '2023-07-15',
                amount: 196.0,
                status: 'settled',
                charges: [
                    {
                        id: 885918977,
                        subscription_id: 62498022,
                        type: 'Charge',
                        kind: 'baseline',
                        transaction_type: 'charge',
                        success: true,
                        amount_in_cents: 4900,
                        memo: 'Enhanced Microsite (07/15/2023 - 08/15/2023)',
                        created_at: '2023-07-15T15:21:47-05:00',
                        starting_balance_in_cents: 0,
                        ending_balance_in_cents: 4900,
                        gateway_used: null,
                        gateway_transaction_id: null,
                        gateway_response_code: null,
                        gateway_order_id: null,
                        payment_id: 885919032,
                        product_id: 4703296,
                        tax_id: null,
                        component_id: null,
                        statement_id: 436998762,
                        customer_id: 23530035,
                        item_name: 'Enhanced Microsite',
                        period_range_start: '2023-07-15',
                        period_range_end: '2023-08-15',
                        currency: 'USD',
                        exchange_rate: 1,
                        component_handle: null,
                        component_price_point_id: null,
                        component_price_point_handle: null,
                        site_gateway_setting_id: null,
                        gateway_handle: null,
                        quantity: '1.0',
                        original_amount_in_cents: 4900,
                        discount_amount_in_cents: 0,
                        taxable_amount_in_cents: null,
                        price_point_id: 370594,
                        price_point_handle: 'uuid:7ededb80-2cdf-0137-2d21-064aab0ed7ee',
                        taxations: [],
                    },
                    {
                        id: 885918978,
                        subscription_id: 62498022,
                        type: 'Charge',
                        kind: 'quantity_based_component',
                        transaction_type: 'charge',
                        success: true,
                        amount_in_cents: 14700,
                        memo: 'Additional Enhanced Microsites: 3 domains',
                        created_at: '2023-07-15T15:21:47-05:00',
                        starting_balance_in_cents: 4900,
                        ending_balance_in_cents: 19600,
                        gateway_used: null,
                        gateway_transaction_id: null,
                        gateway_response_code: null,
                        gateway_order_id: null,
                        payment_id: 885919032,
                        product_id: 4703296,
                        tax_id: null,
                        component_id: 513894,
                        statement_id: 436998762,
                        customer_id: 23530035,
                        item_name: 'Additional Enhanced Microsites',
                        period_range_start: '2023-07-15',
                        period_range_end: '2023-08-15',
                        currency: 'USD',
                        exchange_rate: 1,
                        component_handle: 'additional-c1-domains',
                        component_price_point_id: 273375,
                        component_price_point_handle: 'uuid:e5e07ea0-7656-0136-cbf4-0abfe2d6609e',
                        site_gateway_setting_id: null,
                        gateway_handle: null,
                        quantity: '3.0',
                        original_amount_in_cents: 14700,
                        discount_amount_in_cents: 0,
                        taxable_amount_in_cents: null,
                        price_point_id: 273375,
                        price_point_handle: 'uuid:e5e07ea0-7656-0136-cbf4-0abfe2d6609e',
                        taxations: [],
                    },
                ],
                payments: [
                    {
                        id: 885919032,
                        subscription_id: 62498022,
                        type: 'Payment',
                        kind: null,
                        transaction_type: 'payment',
                        success: true,
                        amount_in_cents: 19600,
                        memo: 'Jean Dupont - Enhanced Microsite: Renewal payment',
                        created_at: '2023-07-15T15:21:49-05:00',
                        starting_balance_in_cents: 19600,
                        ending_balance_in_cents: 0,
                        gateway_used: 'stripe_connect',
                        gateway_transaction_id: 'pi_3NUEyOGJKtzTMHnW0zsIIw5P',
                        gateway_response_code: null,
                        gateway_order_id: null,
                        payment_id: null,
                        product_id: 4703296,
                        tax_id: null,
                        component_id: null,
                        statement_id: 436998762,
                        customer_id: 23530035,
                        item_name: null,
                        period_range_start: null,
                        period_range_end: null,
                        currency: 'USD',
                        exchange_rate: 1,
                        component_handle: null,
                        component_price_point_id: null,
                        component_price_point_handle: null,
                        site_gateway_setting_id: 17153,
                        gateway_handle: null,
                        parent_id: null,
                        role: null,
                        card_number: 'XXXX-XXXX-XXXX-1111',
                        card_expiration: '07/2025',
                        card_type: 'visa',
                        refunded_amount_in_cents: 0,
                        received_on: null,
                    },
                ],
                events: [
                    {
                        id: 3236056152,
                        key: 'renewal_success',
                        message:
                            "Successful renewal for Jean Dupont's subscription to Enhanced Microsite",
                        subscription_id: 62498022,
                        customer_id: 23530035,
                        created_at: '2023-06-15T15:21:53-05:00',
                        event_specific_data: {
                            product_id: 4703296,
                            account_transaction_id: 868652538,
                        },
                    },
                    {
                        id: 3236056159,
                        key: 'payment_success',
                        message:
                            "Successful payment of $199.27 for Jean Dupont's subscription to Enhanced Microsite",
                        subscription_id: 62498022,
                        customer_id: 23530035,
                        created_at: '2023-06-15T15:21:53-05:00',
                        event_specific_data: {
                            product_id: 4703296,
                            account_transaction_id: 868652538,
                        },
                    },
                    {
                        id: 3326075010,
                        key: 'renewal_success',
                        message:
                            "Successful renewal for Jean Dupont's subscription to Enhanced Microsite",
                        subscription_id: 62498022,
                        customer_id: 23530035,
                        created_at: '2023-07-15T15:21:49-05:00',
                        event_specific_data: {
                            product_id: 4703296,
                            account_transaction_id: 885919032,
                        },
                    },
                    {
                        id: 3326075015,
                        key: 'payment_success',
                        message:
                            "Successful payment of $196.00 for Jean Dupont's subscription to Enhanced Microsite",
                        subscription_id: 62498022,
                        customer_id: 23530035,
                        created_at: '2023-07-15T15:21:49-05:00',
                        event_specific_data: {
                            product_id: 4703296,
                            account_transaction_id: 885919032,
                        },
                    },
                ],
            },
            {
                id: 427159981,
                customer_facing_id: 5,
                customer_facing_created: '2023-06-15',
                amount: 199.27,
                status: 'settled',
                charges: [
                    {
                        id: 867307107,
                        subscription_id: 62498022,
                        type: 'Charge',
                        kind: 'quantity_based_component',
                        transaction_type: 'charge',
                        success: true,
                        amount_in_cents: 327,
                        memo: 'Additional Enhanced Microsites: 2 to 3 domains',
                        created_at: '2023-06-13T13:22:38-05:00',
                        starting_balance_in_cents: 0,
                        ending_balance_in_cents: 327,
                        gateway_used: null,
                        gateway_transaction_id: null,
                        gateway_response_code: null,
                        gateway_order_id: null,
                        payment_id: 868652538,
                        product_id: 4703296,
                        tax_id: null,
                        component_id: 513894,
                        statement_id: 427159981,
                        customer_id: 23530035,
                        item_name: 'Additional Enhanced Microsites',
                        period_range_start: '2023-06-13',
                        period_range_end: '2023-06-15',
                        currency: 'USD',
                        exchange_rate: 1,
                        component_handle: 'additional-c1-domains',
                        component_price_point_id: 273375,
                        component_price_point_handle: 'uuid:e5e07ea0-7656-0136-cbf4-0abfe2d6609e',
                        site_gateway_setting_id: null,
                        gateway_handle: null,
                        quantity: '3.0',
                        original_amount_in_cents: 327,
                        discount_amount_in_cents: 0,
                        taxable_amount_in_cents: null,
                        price_point_id: 273375,
                        price_point_handle: 'uuid:e5e07ea0-7656-0136-cbf4-0abfe2d6609e',
                        taxations: [],
                    },
                    {
                        id: 868652458,
                        subscription_id: 62498022,
                        type: 'Charge',
                        kind: 'baseline',
                        transaction_type: 'charge',
                        success: true,
                        amount_in_cents: 4900,
                        memo: 'Enhanced Microsite (06/15/2023 - 07/15/2023)',
                        created_at: '2023-06-15T15:21:50-05:00',
                        starting_balance_in_cents: 327,
                        ending_balance_in_cents: 5227,
                        gateway_used: null,
                        gateway_transaction_id: null,
                        gateway_response_code: null,
                        gateway_order_id: null,
                        payment_id: 868652538,
                        product_id: 4703296,
                        tax_id: null,
                        component_id: null,
                        statement_id: 427159981,
                        customer_id: 23530035,
                        item_name: 'Enhanced Microsite',
                        period_range_start: '2023-06-15',
                        period_range_end: '2023-07-15',
                        currency: 'USD',
                        exchange_rate: 1,
                        component_handle: null,
                        component_price_point_id: null,
                        component_price_point_handle: null,
                        site_gateway_setting_id: null,
                        gateway_handle: null,
                        quantity: '1.0',
                        original_amount_in_cents: 4900,
                        discount_amount_in_cents: 0,
                        taxable_amount_in_cents: null,
                        price_point_id: 370594,
                        price_point_handle: 'uuid:7ededb80-2cdf-0137-2d21-064aab0ed7ee',
                        taxations: [],
                    },
                    {
                        id: 868652459,
                        subscription_id: 62498022,
                        type: 'Charge',
                        kind: 'quantity_based_component',
                        transaction_type: 'charge',
                        success: true,
                        amount_in_cents: 14700,
                        memo: 'Additional Enhanced Microsites: 3 domains',
                        created_at: '2023-06-15T15:21:50-05:00',
                        starting_balance_in_cents: 5227,
                        ending_balance_in_cents: 19927,
                        gateway_used: null,
                        gateway_transaction_id: null,
                        gateway_response_code: null,
                        gateway_order_id: null,
                        payment_id: 868652538,
                        product_id: 4703296,
                        tax_id: null,
                        component_id: 513894,
                        statement_id: 427159981,
                        customer_id: 23530035,
                        item_name: 'Additional Enhanced Microsites',
                        period_range_start: '2023-06-15',
                        period_range_end: '2023-07-15',
                        currency: 'USD',
                        exchange_rate: 1,
                        component_handle: 'additional-c1-domains',
                        component_price_point_id: 273375,
                        component_price_point_handle: 'uuid:e5e07ea0-7656-0136-cbf4-0abfe2d6609e',
                        site_gateway_setting_id: null,
                        gateway_handle: null,
                        quantity: '3.0',
                        original_amount_in_cents: 14700,
                        discount_amount_in_cents: 0,
                        taxable_amount_in_cents: null,
                        price_point_id: 273375,
                        price_point_handle: 'uuid:e5e07ea0-7656-0136-cbf4-0abfe2d6609e',
                        taxations: [],
                    },
                ],
                payments: [
                    {
                        id: 868652538,
                        subscription_id: 62498022,
                        type: 'Payment',
                        kind: null,
                        transaction_type: 'payment',
                        success: true,
                        amount_in_cents: 19927,
                        memo: 'Jean Dupont - Enhanced Microsite: Renewal payment',
                        created_at: '2023-06-15T15:21:52-05:00',
                        starting_balance_in_cents: 19927,
                        ending_balance_in_cents: 0,
                        gateway_used: 'stripe_connect',
                        gateway_transaction_id: 'pi_3NJMfzGJKtzTMHnW0IlrqoG1',
                        gateway_response_code: null,
                        gateway_order_id: null,
                        payment_id: null,
                        product_id: 4703296,
                        tax_id: null,
                        component_id: null,
                        statement_id: 427159981,
                        customer_id: 23530035,
                        item_name: null,
                        period_range_start: null,
                        period_range_end: null,
                        currency: 'USD',
                        exchange_rate: 1,
                        component_handle: null,
                        component_price_point_id: null,
                        component_price_point_handle: null,
                        site_gateway_setting_id: 17153,
                        gateway_handle: null,
                        parent_id: null,
                        role: null,
                        card_number: 'XXXX-XXXX-XXXX-1111',
                        card_expiration: '07/2025',
                        card_type: 'visa',
                        refunded_amount_in_cents: 0,
                        received_on: null,
                    },
                ],
                events: [
                    {
                        id: 3236056152,
                        key: 'renewal_success',
                        message:
                            "Successful renewal for Jean Dupont's subscription to Enhanced Microsite",
                        subscription_id: 62498022,
                        customer_id: 23530035,
                        created_at: '2023-06-15T15:21:53-05:00',
                        event_specific_data: {
                            product_id: 4703296,
                            account_transaction_id: 868652538,
                        },
                    },
                    {
                        id: 3236056159,
                        key: 'payment_success',
                        message:
                            "Successful payment of $199.27 for Jean Dupont's subscription to Enhanced Microsite",
                        subscription_id: 62498022,
                        customer_id: 23530035,
                        created_at: '2023-06-15T15:21:53-05:00',
                        event_specific_data: {
                            product_id: 4703296,
                            account_transaction_id: 868652538,
                        },
                    },
                ],
            },
            {
                id: 418960419,
                customer_facing_id: 4,
                customer_facing_created: '2023-05-15',
                amount: 166.0,
                status: 'settled',
                charges: [
                    {
                        id: 851684058,
                        subscription_id: 62498022,
                        type: 'Charge',
                        kind: 'baseline',
                        transaction_type: 'charge',
                        success: true,
                        amount_in_cents: 4900,
                        memo: 'Enhanced Microsite (05/15/2023 - 06/15/2023)',
                        created_at: '2023-05-15T15:20:22-05:00',
                        starting_balance_in_cents: 0,
                        ending_balance_in_cents: 4900,
                        gateway_used: null,
                        gateway_transaction_id: null,
                        gateway_response_code: null,
                        gateway_order_id: null,
                        payment_id: 851684207,
                        product_id: 4703296,
                        tax_id: null,
                        component_id: null,
                        statement_id: 418960419,
                        customer_id: 23530035,
                        item_name: 'Enhanced Microsite',
                        period_range_start: '2023-05-15',
                        period_range_end: '2023-06-15',
                        currency: 'USD',
                        exchange_rate: 1,
                        component_handle: null,
                        component_price_point_id: null,
                        component_price_point_handle: null,
                        site_gateway_setting_id: null,
                        gateway_handle: null,
                        quantity: '1.0',
                        original_amount_in_cents: 4900,
                        discount_amount_in_cents: 0,
                        taxable_amount_in_cents: null,
                        price_point_id: 370594,
                        price_point_handle: 'uuid:7ededb80-2cdf-0137-2d21-064aab0ed7ee',
                        taxations: [],
                    },
                    {
                        id: 851684059,
                        subscription_id: 62498022,
                        type: 'Charge',
                        kind: 'quantity_based_component',
                        transaction_type: 'charge',
                        success: true,
                        amount_in_cents: 9800,
                        memo: 'Additional Enhanced Microsites: 2 domains',
                        created_at: '2023-05-15T15:20:22-05:00',
                        starting_balance_in_cents: 4900,
                        ending_balance_in_cents: 14700,
                        gateway_used: null,
                        gateway_transaction_id: null,
                        gateway_response_code: null,
                        gateway_order_id: null,
                        payment_id: 851684207,
                        product_id: 4703296,
                        tax_id: null,
                        component_id: 513894,
                        statement_id: 418960419,
                        customer_id: 23530035,
                        item_name: 'Additional Enhanced Microsites',
                        period_range_start: '2023-05-15',
                        period_range_end: '2023-06-15',
                        currency: 'USD',
                        exchange_rate: 1,
                        component_handle: 'additional-c1-domains',
                        component_price_point_id: 273375,
                        component_price_point_handle: 'uuid:e5e07ea0-7656-0136-cbf4-0abfe2d6609e',
                        site_gateway_setting_id: null,
                        gateway_handle: null,
                        quantity: '2.0',
                        original_amount_in_cents: 9800,
                        discount_amount_in_cents: 0,
                        taxable_amount_in_cents: null,
                        price_point_id: 273375,
                        price_point_handle: 'uuid:e5e07ea0-7656-0136-cbf4-0abfe2d6609e',
                        taxations: [],
                    },
                    {
                        id: 851684060,
                        subscription_id: 62498022,
                        type: 'Charge',
                        kind: 'quantity_based_component',
                        transaction_type: 'charge',
                        success: true,
                        amount_in_cents: 900,
                        memo: 'Parked Domains: 1 domain',
                        created_at: '2023-05-15T15:20:22-05:00',
                        starting_balance_in_cents: 14700,
                        ending_balance_in_cents: 15600,
                        gateway_used: null,
                        gateway_transaction_id: null,
                        gateway_response_code: null,
                        gateway_order_id: null,
                        payment_id: 851684207,
                        product_id: 4703296,
                        tax_id: null,
                        component_id: 513895,
                        statement_id: 418960419,
                        customer_id: 23530035,
                        item_name: 'Parked Domains',
                        period_range_start: '2023-05-15',
                        period_range_end: '2023-06-15',
                        currency: 'USD',
                        exchange_rate: 1,
                        component_handle: 'parked-domains',
                        component_price_point_id: 273376,
                        component_price_point_handle: 'uuid:0348ab20-7657-0136-df66-0a0577988370',
                        site_gateway_setting_id: null,
                        gateway_handle: null,
                        quantity: '1.0',
                        original_amount_in_cents: 900,
                        discount_amount_in_cents: 0,
                        taxable_amount_in_cents: null,
                        price_point_id: 273376,
                        price_point_handle: 'uuid:0348ab20-7657-0136-df66-0a0577988370',
                        taxations: [],
                    },
                    {
                        id: 851684062,
                        subscription_id: 62498022,
                        type: 'Charge',
                        kind: 'quantity_based_component',
                        transaction_type: 'charge',
                        success: true,
                        amount_in_cents: 1000,
                        memo: 'Parked Email Redirects: 10 email redirects',
                        created_at: '2023-05-15T15:20:22-05:00',
                        starting_balance_in_cents: 15600,
                        ending_balance_in_cents: 16600,
                        gateway_used: null,
                        gateway_transaction_id: null,
                        gateway_response_code: null,
                        gateway_order_id: null,
                        payment_id: 851684207,
                        product_id: 4703296,
                        tax_id: null,
                        component_id: 513899,
                        statement_id: 418960419,
                        customer_id: 23530035,
                        item_name: 'Parked Email Redirects',
                        period_range_start: '2023-05-15',
                        period_range_end: '2023-06-15',
                        currency: 'USD',
                        exchange_rate: 1,
                        component_handle: 'parked-emails',
                        component_price_point_id: 273380,
                        component_price_point_handle: 'uuid:678a5220-ad4b-0136-d8cd-0a1d445c66ba',
                        site_gateway_setting_id: null,
                        gateway_handle: null,
                        quantity: '10.0',
                        original_amount_in_cents: 1000,
                        discount_amount_in_cents: 0,
                        taxable_amount_in_cents: null,
                        price_point_id: 273380,
                        price_point_handle: 'uuid:678a5220-ad4b-0136-d8cd-0a1d445c66ba',
                        taxations: [],
                    },
                ],
                payments: [
                    {
                        id: 851684207,
                        subscription_id: 62498022,
                        type: 'Payment',
                        kind: null,
                        transaction_type: 'payment',
                        success: true,
                        amount_in_cents: 16600,
                        memo: 'Jean Dupont - Enhanced Microsite: Renewal payment',
                        created_at: '2023-05-15T15:20:24-05:00',
                        starting_balance_in_cents: 16600,
                        ending_balance_in_cents: 0,
                        gateway_used: 'stripe_connect',
                        gateway_transaction_id: 'pi_3N87sZGJKtzTMHnW1cCq6UZz',
                        gateway_response_code: null,
                        gateway_order_id: null,
                        payment_id: null,
                        product_id: 4703296,
                        tax_id: null,
                        component_id: null,
                        statement_id: 418960419,
                        customer_id: 23530035,
                        item_name: null,
                        period_range_start: null,
                        period_range_end: null,
                        currency: 'USD',
                        exchange_rate: 1,
                        component_handle: null,
                        component_price_point_id: null,
                        component_price_point_handle: null,
                        site_gateway_setting_id: 17153,
                        gateway_handle: null,
                        parent_id: null,
                        role: null,
                        card_number: 'XXXX-XXXX-XXXX-1111',
                        card_expiration: '07/2025',
                        card_type: 'visa',
                        refunded_amount_in_cents: 0,
                        received_on: null,
                    },
                ],
                events: [
                    {
                        id: 3141905225,
                        key: 'renewal_success',
                        message:
                            "Successful renewal for Jean Dupont's subscription to Enhanced Microsite",
                        subscription_id: 62498022,
                        customer_id: 23530035,
                        created_at: '2023-05-15T15:20:24-05:00',
                        event_specific_data: {
                            product_id: 4703296,
                            account_transaction_id: 851684207,
                        },
                    },
                    {
                        id: 3141905228,
                        key: 'payment_success',
                        message:
                            "Successful payment of $166.00 for Jean Dupont's subscription to Enhanced Microsite",
                        subscription_id: 62498022,
                        customer_id: 23530035,
                        created_at: '2023-05-15T15:20:24-05:00',
                        event_specific_data: {
                            product_id: 4703296,
                            account_transaction_id: 851684207,
                        },
                    },
                ],
            },
            {
                id: 410455935,
                customer_facing_id: 3,
                customer_facing_created: '2023-04-15',
                amount: 166.0,
                status: 'settled',
                charges: [
                    {
                        id: 835519192,
                        subscription_id: 62498022,
                        type: 'Charge',
                        kind: 'baseline',
                        transaction_type: 'charge',
                        success: true,
                        amount_in_cents: 4900,
                        memo: 'Enhanced Microsite (04/15/2023 - 05/15/2023)',
                        created_at: '2023-04-15T15:21:58-05:00',
                        starting_balance_in_cents: 0,
                        ending_balance_in_cents: 4900,
                        gateway_used: null,
                        gateway_transaction_id: null,
                        gateway_response_code: null,
                        gateway_order_id: null,
                        payment_id: 835519238,
                        product_id: 4703296,
                        tax_id: null,
                        component_id: null,
                        statement_id: 410455935,
                        customer_id: 23530035,
                        item_name: 'Enhanced Microsite',
                        period_range_start: '2023-04-15',
                        period_range_end: '2023-05-15',
                        currency: 'USD',
                        exchange_rate: 1,
                        component_handle: null,
                        component_price_point_id: null,
                        component_price_point_handle: null,
                        site_gateway_setting_id: null,
                        gateway_handle: null,
                        quantity: '1.0',
                        original_amount_in_cents: 4900,
                        discount_amount_in_cents: 0,
                        taxable_amount_in_cents: null,
                        price_point_id: 370594,
                        price_point_handle: 'uuid:7ededb80-2cdf-0137-2d21-064aab0ed7ee',
                        taxations: [],
                    },
                    {
                        id: 835519193,
                        subscription_id: 62498022,
                        type: 'Charge',
                        kind: 'quantity_based_component',
                        transaction_type: 'charge',
                        success: true,
                        amount_in_cents: 9800,
                        memo: 'Additional Enhanced Microsites: 2 domains',
                        created_at: '2023-04-15T15:21:58-05:00',
                        starting_balance_in_cents: 4900,
                        ending_balance_in_cents: 14700,
                        gateway_used: null,
                        gateway_transaction_id: null,
                        gateway_response_code: null,
                        gateway_order_id: null,
                        payment_id: 835519238,
                        product_id: 4703296,
                        tax_id: null,
                        component_id: 513894,
                        statement_id: 410455935,
                        customer_id: 23530035,
                        item_name: 'Additional Enhanced Microsites',
                        period_range_start: '2023-04-15',
                        period_range_end: '2023-05-15',
                        currency: 'USD',
                        exchange_rate: 1,
                        component_handle: 'additional-c1-domains',
                        component_price_point_id: 273375,
                        component_price_point_handle: 'uuid:e5e07ea0-7656-0136-cbf4-0abfe2d6609e',
                        site_gateway_setting_id: null,
                        gateway_handle: null,
                        quantity: '2.0',
                        original_amount_in_cents: 9800,
                        discount_amount_in_cents: 0,
                        taxable_amount_in_cents: null,
                        price_point_id: 273375,
                        price_point_handle: 'uuid:e5e07ea0-7656-0136-cbf4-0abfe2d6609e',
                        taxations: [],
                    },
                    {
                        id: 835519194,
                        subscription_id: 62498022,
                        type: 'Charge',
                        kind: 'quantity_based_component',
                        transaction_type: 'charge',
                        success: true,
                        amount_in_cents: 900,
                        memo: 'Parked Domains: 1 domain',
                        created_at: '2023-04-15T15:21:58-05:00',
                        starting_balance_in_cents: 14700,
                        ending_balance_in_cents: 15600,
                        gateway_used: null,
                        gateway_transaction_id: null,
                        gateway_response_code: null,
                        gateway_order_id: null,
                        payment_id: 835519238,
                        product_id: 4703296,
                        tax_id: null,
                        component_id: 513895,
                        statement_id: 410455935,
                        customer_id: 23530035,
                        item_name: 'Parked Domains',
                        period_range_start: '2023-04-15',
                        period_range_end: '2023-05-15',
                        currency: 'USD',
                        exchange_rate: 1,
                        component_handle: 'parked-domains',
                        component_price_point_id: 273376,
                        component_price_point_handle: 'uuid:0348ab20-7657-0136-df66-0a0577988370',
                        site_gateway_setting_id: null,
                        gateway_handle: null,
                        quantity: '1.0',
                        original_amount_in_cents: 900,
                        discount_amount_in_cents: 0,
                        taxable_amount_in_cents: null,
                        price_point_id: 273376,
                        price_point_handle: 'uuid:0348ab20-7657-0136-df66-0a0577988370',
                        taxations: [],
                    },
                    {
                        id: 835519195,
                        subscription_id: 62498022,
                        type: 'Charge',
                        kind: 'quantity_based_component',
                        transaction_type: 'charge',
                        success: true,
                        amount_in_cents: 1000,
                        memo: 'Parked Email Redirects: 10 email redirects',
                        created_at: '2023-04-15T15:21:58-05:00',
                        starting_balance_in_cents: 15600,
                        ending_balance_in_cents: 16600,
                        gateway_used: null,
                        gateway_transaction_id: null,
                        gateway_response_code: null,
                        gateway_order_id: null,
                        payment_id: 835519238,
                        product_id: 4703296,
                        tax_id: null,
                        component_id: 513899,
                        statement_id: 410455935,
                        customer_id: 23530035,
                        item_name: 'Parked Email Redirects',
                        period_range_start: '2023-04-15',
                        period_range_end: '2023-05-15',
                        currency: 'USD',
                        exchange_rate: 1,
                        component_handle: 'parked-emails',
                        component_price_point_id: 273380,
                        component_price_point_handle: 'uuid:678a5220-ad4b-0136-d8cd-0a1d445c66ba',
                        site_gateway_setting_id: null,
                        gateway_handle: null,
                        quantity: '10.0',
                        original_amount_in_cents: 1000,
                        discount_amount_in_cents: 0,
                        taxable_amount_in_cents: null,
                        price_point_id: 273380,
                        price_point_handle: 'uuid:678a5220-ad4b-0136-d8cd-0a1d445c66ba',
                        taxations: [],
                    },
                ],
                payments: [
                    {
                        id: 835519238,
                        subscription_id: 62498022,
                        type: 'Payment',
                        kind: null,
                        transaction_type: 'payment',
                        success: true,
                        amount_in_cents: 16600,
                        memo: 'Jean Dupont - Enhanced Microsite: Renewal payment',
                        created_at: '2023-04-15T15:21:59-05:00',
                        starting_balance_in_cents: 16600,
                        ending_balance_in_cents: 0,
                        gateway_used: 'stripe_connect',
                        gateway_transaction_id: 'pi_3MxFbeGJKtzTMHnW13DUJFA0',
                        gateway_response_code: null,
                        gateway_order_id: null,
                        payment_id: null,
                        product_id: 4703296,
                        tax_id: null,
                        component_id: null,
                        statement_id: 410455935,
                        customer_id: 23530035,
                        item_name: null,
                        period_range_start: null,
                        period_range_end: null,
                        currency: 'USD',
                        exchange_rate: 1,
                        component_handle: null,
                        component_price_point_id: null,
                        component_price_point_handle: null,
                        site_gateway_setting_id: 17153,
                        gateway_handle: null,
                        parent_id: null,
                        role: null,
                        card_number: 'XXXX-XXXX-XXXX-1111',
                        card_expiration: '07/2025',
                        card_type: 'visa',
                        refunded_amount_in_cents: 0,
                        received_on: null,
                    },
                ],
                events: [
                    {
                        id: 3054967006,
                        key: 'renewal_success',
                        message:
                            "Successful renewal for Jean Dupont's subscription to Enhanced Microsite",
                        subscription_id: 62498022,
                        customer_id: 23530035,
                        created_at: '2023-04-15T15:21:59-05:00',
                        event_specific_data: {
                            product_id: 4703296,
                            account_transaction_id: 835519238,
                        },
                    },
                    {
                        id: 3054967010,
                        key: 'payment_success',
                        message:
                            "Successful payment of $166.00 for Jean Dupont's subscription to Enhanced Microsite",
                        subscription_id: 62498022,
                        customer_id: 23530035,
                        created_at: '2023-04-15T15:21:59-05:00',
                        event_specific_data: {
                            product_id: 4703296,
                            account_transaction_id: 835519238,
                        },
                    },
                ],
            },
            {
                id: 392844846,
                customer_facing_id: 2,
                customer_facing_created: '2023-03-15',
                amount: 214.99,
                status: 'settled',
                charges: [
                    {
                        id: 803085026,
                        subscription_id: 62498022,
                        type: 'Charge',
                        kind: 'quantity_based_component',
                        transaction_type: 'charge',
                        success: true,
                        amount_in_cents: 4899,
                        memo: 'Additional Enhanced Microsites: 1 to 2 domains',
                        created_at: '2023-02-15T14:16:06-06:00',
                        starting_balance_in_cents: 0,
                        ending_balance_in_cents: 4899,
                        gateway_used: null,
                        gateway_transaction_id: null,
                        gateway_response_code: null,
                        gateway_order_id: null,
                        payment_id: 819158446,
                        product_id: 4703296,
                        tax_id: null,
                        component_id: 513894,
                        statement_id: 392844846,
                        customer_id: 23530035,
                        item_name: 'Additional Enhanced Microsites',
                        period_range_start: '2023-02-15',
                        period_range_end: '2023-03-15',
                        currency: 'USD',
                        exchange_rate: 1,
                        component_handle: 'additional-c1-domains',
                        component_price_point_id: 273375,
                        component_price_point_handle: 'uuid:e5e07ea0-7656-0136-cbf4-0abfe2d6609e',
                        site_gateway_setting_id: null,
                        gateway_handle: null,
                        quantity: '2.0',
                        original_amount_in_cents: 4899,
                        discount_amount_in_cents: 0,
                        taxable_amount_in_cents: null,
                        price_point_id: 273375,
                        price_point_handle: 'uuid:e5e07ea0-7656-0136-cbf4-0abfe2d6609e',
                        taxations: [],
                    },
                    {
                        id: 819158351,
                        subscription_id: 62498022,
                        type: 'Charge',
                        kind: 'baseline',
                        transaction_type: 'charge',
                        success: true,
                        amount_in_cents: 4900,
                        memo: 'Enhanced Microsite (03/15/2023 - 04/15/2023)',
                        created_at: '2023-03-15T15:22:08-05:00',
                        starting_balance_in_cents: 4899,
                        ending_balance_in_cents: 9799,
                        gateway_used: null,
                        gateway_transaction_id: null,
                        gateway_response_code: null,
                        gateway_order_id: null,
                        payment_id: 819158446,
                        product_id: 4703296,
                        tax_id: null,
                        component_id: null,
                        statement_id: 392844846,
                        customer_id: 23530035,
                        item_name: 'Enhanced Microsite',
                        period_range_start: '2023-03-15',
                        period_range_end: '2023-04-15',
                        currency: 'USD',
                        exchange_rate: 1,
                        component_handle: null,
                        component_price_point_id: null,
                        component_price_point_handle: null,
                        site_gateway_setting_id: null,
                        gateway_handle: null,
                        quantity: '1.0',
                        original_amount_in_cents: 4900,
                        discount_amount_in_cents: 0,
                        taxable_amount_in_cents: null,
                        price_point_id: 370594,
                        price_point_handle: 'uuid:7ededb80-2cdf-0137-2d21-064aab0ed7ee',
                        taxations: [],
                    },
                    {
                        id: 819158353,
                        subscription_id: 62498022,
                        type: 'Charge',
                        kind: 'quantity_based_component',
                        transaction_type: 'charge',
                        success: true,
                        amount_in_cents: 9800,
                        memo: 'Additional Enhanced Microsites: 2 domains',
                        created_at: '2023-03-15T15:22:08-05:00',
                        starting_balance_in_cents: 9799,
                        ending_balance_in_cents: 19599,
                        gateway_used: null,
                        gateway_transaction_id: null,
                        gateway_response_code: null,
                        gateway_order_id: null,
                        payment_id: 819158446,
                        product_id: 4703296,
                        tax_id: null,
                        component_id: 513894,
                        statement_id: 392844846,
                        customer_id: 23530035,
                        item_name: 'Additional Enhanced Microsites',
                        period_range_start: '2023-03-15',
                        period_range_end: '2023-04-15',
                        currency: 'USD',
                        exchange_rate: 1,
                        component_handle: 'additional-c1-domains',
                        component_price_point_id: 273375,
                        component_price_point_handle: 'uuid:e5e07ea0-7656-0136-cbf4-0abfe2d6609e',
                        site_gateway_setting_id: null,
                        gateway_handle: null,
                        quantity: '2.0',
                        original_amount_in_cents: 9800,
                        discount_amount_in_cents: 0,
                        taxable_amount_in_cents: null,
                        price_point_id: 273375,
                        price_point_handle: 'uuid:e5e07ea0-7656-0136-cbf4-0abfe2d6609e',
                        taxations: [],
                    },
                    {
                        id: 819158354,
                        subscription_id: 62498022,
                        type: 'Charge',
                        kind: 'quantity_based_component',
                        transaction_type: 'charge',
                        success: true,
                        amount_in_cents: 900,
                        memo: 'Parked Domains: 1 domain',
                        created_at: '2023-03-15T15:22:08-05:00',
                        starting_balance_in_cents: 19599,
                        ending_balance_in_cents: 20499,
                        gateway_used: null,
                        gateway_transaction_id: null,
                        gateway_response_code: null,
                        gateway_order_id: null,
                        payment_id: 819158446,
                        product_id: 4703296,
                        tax_id: null,
                        component_id: 513895,
                        statement_id: 392844846,
                        customer_id: 23530035,
                        item_name: 'Parked Domains',
                        period_range_start: '2023-03-15',
                        period_range_end: '2023-04-15',
                        currency: 'USD',
                        exchange_rate: 1,
                        component_handle: 'parked-domains',
                        component_price_point_id: 273376,
                        component_price_point_handle: 'uuid:0348ab20-7657-0136-df66-0a0577988370',
                        site_gateway_setting_id: null,
                        gateway_handle: null,
                        quantity: '1.0',
                        original_amount_in_cents: 900,
                        discount_amount_in_cents: 0,
                        taxable_amount_in_cents: null,
                        price_point_id: 273376,
                        price_point_handle: 'uuid:0348ab20-7657-0136-df66-0a0577988370',
                        taxations: [],
                    },
                    {
                        id: 819158355,
                        subscription_id: 62498022,
                        type: 'Charge',
                        kind: 'quantity_based_component',
                        transaction_type: 'charge',
                        success: true,
                        amount_in_cents: 1000,
                        memo: 'Parked Email Redirects: 10 email redirects',
                        created_at: '2023-03-15T15:22:08-05:00',
                        starting_balance_in_cents: 20499,
                        ending_balance_in_cents: 21499,
                        gateway_used: null,
                        gateway_transaction_id: null,
                        gateway_response_code: null,
                        gateway_order_id: null,
                        payment_id: 819158446,
                        product_id: 4703296,
                        tax_id: null,
                        component_id: 513899,
                        statement_id: 392844846,
                        customer_id: 23530035,
                        item_name: 'Parked Email Redirects',
                        period_range_start: '2023-03-15',
                        period_range_end: '2023-04-15',
                        currency: 'USD',
                        exchange_rate: 1,
                        component_handle: 'parked-emails',
                        component_price_point_id: 273380,
                        component_price_point_handle: 'uuid:678a5220-ad4b-0136-d8cd-0a1d445c66ba',
                        site_gateway_setting_id: null,
                        gateway_handle: null,
                        quantity: '10.0',
                        original_amount_in_cents: 1000,
                        discount_amount_in_cents: 0,
                        taxable_amount_in_cents: null,
                        price_point_id: 273380,
                        price_point_handle: 'uuid:678a5220-ad4b-0136-d8cd-0a1d445c66ba',
                        taxations: [],
                    },
                ],
                payments: [
                    {
                        id: 819158446,
                        subscription_id: 62498022,
                        type: 'Payment',
                        kind: null,
                        transaction_type: 'payment',
                        success: true,
                        amount_in_cents: 21499,
                        memo: 'Jean Dupont - Enhanced Microsite: Renewal payment',
                        created_at: '2023-03-15T15:22:10-05:00',
                        starting_balance_in_cents: 21499,
                        ending_balance_in_cents: 0,
                        gateway_used: 'stripe_connect',
                        gateway_transaction_id: 'pi_3Mm0poGJKtzTMHnW1SLtin8N',
                        gateway_response_code: null,
                        gateway_order_id: null,
                        payment_id: null,
                        product_id: 4703296,
                        tax_id: null,
                        component_id: null,
                        statement_id: 392844846,
                        customer_id: 23530035,
                        item_name: null,
                        period_range_start: null,
                        period_range_end: null,
                        currency: 'USD',
                        exchange_rate: 1,
                        component_handle: null,
                        component_price_point_id: null,
                        component_price_point_handle: null,
                        site_gateway_setting_id: 17153,
                        gateway_handle: null,
                        parent_id: null,
                        role: null,
                        card_number: 'XXXX-XXXX-XXXX-1111',
                        card_expiration: '07/2025',
                        card_type: 'visa',
                        refunded_amount_in_cents: 0,
                        received_on: null,
                    },
                ],
                events: [
                    {
                        id: 2964400280,
                        key: 'renewal_success',
                        message:
                            "Successful renewal for Jean Dupont's subscription to Enhanced Microsite",
                        subscription_id: 62498022,
                        customer_id: 23530035,
                        created_at: '2023-03-15T15:22:10-05:00',
                        event_specific_data: {
                            product_id: 4703296,
                            account_transaction_id: 819158446,
                        },
                    },
                    {
                        id: 2964400285,
                        key: 'payment_success',
                        message:
                            "Successful payment of $214.99 for Jean Dupont's subscription to Enhanced Microsite",
                        subscription_id: 62498022,
                        customer_id: 23530035,
                        created_at: '2023-03-15T15:22:10-05:00',
                        event_specific_data: {
                            product_id: 4703296,
                            account_transaction_id: 819158446,
                        },
                    },
                ],
            },
            {
                id: 392844549,
                customer_facing_id: 1,
                customer_facing_created: '2023-02-15',
                amount: 110.0,
                status: 'settled',
                charges: [
                    {
                        id: 803084342,
                        subscription_id: 62498022,
                        type: 'Charge',
                        kind: 'baseline',
                        transaction_type: 'charge',
                        success: true,
                        amount_in_cents: 4900,
                        memo: 'Enhanced Microsite (02/15/2023 - 03/15/2023)',
                        created_at: '2023-02-15T14:05:52-06:00',
                        starting_balance_in_cents: 0,
                        ending_balance_in_cents: 4900,
                        gateway_used: null,
                        gateway_transaction_id: null,
                        gateway_response_code: null,
                        gateway_order_id: null,
                        payment_id: 803084347,
                        product_id: 4703296,
                        tax_id: null,
                        component_id: null,
                        statement_id: 392844549,
                        customer_id: 23530035,
                        item_name: 'Enhanced Microsite',
                        period_range_start: '2023-02-15',
                        period_range_end: '2023-03-15',
                        currency: 'USD',
                        exchange_rate: 1,
                        component_handle: null,
                        component_price_point_id: null,
                        component_price_point_handle: null,
                        site_gateway_setting_id: null,
                        gateway_handle: null,
                        quantity: '1.0',
                        original_amount_in_cents: 4900,
                        discount_amount_in_cents: 0,
                        taxable_amount_in_cents: null,
                        price_point_id: 370594,
                        price_point_handle: 'uuid:7ededb80-2cdf-0137-2d21-064aab0ed7ee',
                        taxations: [],
                    },
                    {
                        id: 803084343,
                        subscription_id: 62498022,
                        type: 'Charge',
                        kind: 'quantity_based_component',
                        transaction_type: 'charge',
                        success: true,
                        amount_in_cents: 4900,
                        memo: 'Additional Enhanced Microsites: 1 domain',
                        created_at: '2023-02-15T14:05:52-06:00',
                        starting_balance_in_cents: 4900,
                        ending_balance_in_cents: 9800,
                        gateway_used: null,
                        gateway_transaction_id: null,
                        gateway_response_code: null,
                        gateway_order_id: null,
                        payment_id: 803084347,
                        product_id: 4703296,
                        tax_id: null,
                        component_id: 513894,
                        statement_id: 392844549,
                        customer_id: 23530035,
                        item_name: 'Additional Enhanced Microsites',
                        period_range_start: '2023-02-15',
                        period_range_end: '2023-03-15',
                        currency: 'USD',
                        exchange_rate: 1,
                        component_handle: 'additional-c1-domains',
                        component_price_point_id: 273375,
                        component_price_point_handle: 'uuid:e5e07ea0-7656-0136-cbf4-0abfe2d6609e',
                        site_gateway_setting_id: null,
                        gateway_handle: null,
                        quantity: '1.0',
                        original_amount_in_cents: 4900,
                        discount_amount_in_cents: 0,
                        taxable_amount_in_cents: null,
                        price_point_id: 273375,
                        price_point_handle: 'uuid:e5e07ea0-7656-0136-cbf4-0abfe2d6609e',
                        taxations: [],
                    },
                    {
                        id: 803084344,
                        subscription_id: 62498022,
                        type: 'Charge',
                        kind: 'quantity_based_component',
                        transaction_type: 'charge',
                        success: true,
                        amount_in_cents: 900,
                        memo: 'Parked Domains: 1 domain',
                        created_at: '2023-02-15T14:05:52-06:00',
                        starting_balance_in_cents: 9800,
                        ending_balance_in_cents: 10700,
                        gateway_used: null,
                        gateway_transaction_id: null,
                        gateway_response_code: null,
                        gateway_order_id: null,
                        payment_id: 803084347,
                        product_id: 4703296,
                        tax_id: null,
                        component_id: 513895,
                        statement_id: 392844549,
                        customer_id: 23530035,
                        item_name: 'Parked Domains',
                        period_range_start: '2023-02-15',
                        period_range_end: '2023-03-15',
                        currency: 'USD',
                        exchange_rate: 1,
                        component_handle: 'parked-domains',
                        component_price_point_id: 273376,
                        component_price_point_handle: 'uuid:0348ab20-7657-0136-df66-0a0577988370',
                        site_gateway_setting_id: null,
                        gateway_handle: null,
                        quantity: '1.0',
                        original_amount_in_cents: 900,
                        discount_amount_in_cents: 0,
                        taxable_amount_in_cents: null,
                        price_point_id: 273376,
                        price_point_handle: 'uuid:0348ab20-7657-0136-df66-0a0577988370',
                        taxations: [],
                    },
                    {
                        id: 803084345,
                        subscription_id: 62498022,
                        type: 'Charge',
                        kind: 'quantity_based_component',
                        transaction_type: 'charge',
                        success: true,
                        amount_in_cents: 300,
                        memo: 'Parked Email Redirects: 3 email redirects',
                        created_at: '2023-02-15T14:05:52-06:00',
                        starting_balance_in_cents: 10700,
                        ending_balance_in_cents: 11000,
                        gateway_used: null,
                        gateway_transaction_id: null,
                        gateway_response_code: null,
                        gateway_order_id: null,
                        payment_id: 803084347,
                        product_id: 4703296,
                        tax_id: null,
                        component_id: 513899,
                        statement_id: 392844549,
                        customer_id: 23530035,
                        item_name: 'Parked Email Redirects',
                        period_range_start: '2023-02-15',
                        period_range_end: '2023-03-15',
                        currency: 'USD',
                        exchange_rate: 1,
                        component_handle: 'parked-emails',
                        component_price_point_id: 273380,
                        component_price_point_handle: 'uuid:678a5220-ad4b-0136-d8cd-0a1d445c66ba',
                        site_gateway_setting_id: null,
                        gateway_handle: null,
                        quantity: '3.0',
                        original_amount_in_cents: 300,
                        discount_amount_in_cents: 0,
                        taxable_amount_in_cents: null,
                        price_point_id: 273380,
                        price_point_handle: 'uuid:678a5220-ad4b-0136-d8cd-0a1d445c66ba',
                        taxations: [],
                    },
                ],
                payments: [
                    {
                        id: 803084347,
                        subscription_id: 62498022,
                        type: 'Payment',
                        kind: null,
                        transaction_type: 'payment',
                        success: true,
                        amount_in_cents: 11000,
                        memo: 'Jean Dupont - Enhanced Microsite: Signup payment',
                        created_at: '2023-02-15T14:05:54-06:00',
                        starting_balance_in_cents: 11000,
                        ending_balance_in_cents: 0,
                        gateway_used: 'stripe_connect',
                        gateway_transaction_id: 'pi_3MbrEjGJKtzTMHnW0xz97FLy',
                        gateway_response_code: null,
                        gateway_order_id: null,
                        payment_id: null,
                        product_id: 4703296,
                        tax_id: null,
                        component_id: null,
                        statement_id: 392844549,
                        customer_id: 23530035,
                        item_name: null,
                        period_range_start: null,
                        period_range_end: null,
                        currency: 'USD',
                        exchange_rate: 1,
                        component_handle: null,
                        component_price_point_id: null,
                        component_price_point_handle: null,
                        site_gateway_setting_id: 17153,
                        gateway_handle: null,
                        parent_id: null,
                        role: null,
                        card_number: 'XXXX-XXXX-XXXX-1111',
                        card_expiration: '07/2025',
                        card_type: 'visa',
                        refunded_amount_in_cents: 0,
                        received_on: null,
                    },
                ],
                events: [
                    {
                        id: 2878794843,
                        key: 'payment_success',
                        message:
                            "Successful payment of $110.00 for Jean Dupont's subscription to Enhanced Microsite",
                        subscription_id: 62498022,
                        customer_id: 23530035,
                        created_at: '2023-02-15T14:05:54-06:00',
                        event_specific_data: {
                            product_id: 4703296,
                            account_transaction_id: 803084347,
                        },
                    },
                    {
                        id: 2878794846,
                        key: 'signup_success',
                        message:
                            "Successful signup for Jean Dupont's subscription to Enhanced Microsite",
                        subscription_id: 62498022,
                        customer_id: 23530035,
                        created_at: '2023-02-15T14:05:54-06:00',
                        event_specific_data: {
                            product_id: 4703296,
                            account_transaction_id: 803084347,
                        },
                    },
                ],
            },
        ],
    },
};
