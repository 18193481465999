<template>
    <b-modal
        :visible="show"
        centered
        size="lg"
        hide-header
        hide-footer
        ok-title="Confirm"
        @hide.prevent="onHide"
    >
        <h3>Session Expired</h3>
        <p>Looks like your session has expired!</p>
        <a href="/login/">Click here to log back in</a>
    </b-modal>
</template>
<script>
export default {
    name: 'LogoutModal',
    props: {
        show: Boolean,
    },
    methods: {
        onHide() {
            return;
        },
    },
};
</script>