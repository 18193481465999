<template>
    <div class="paper page_main_col col-12 offset-lg-1 col-lg-10 mb-2">
        <div class="row">
            <div class="col-xs-12 col-sm-6">
                <h3 class="text-center">Select Account</h3>
                <input
                    :disabled="final_countdown"
                    :value="filter_string"
                    aria-describedby="basic-addon2"
                    aria-label="Search Accounts"
                    class="form-control"
                    placeholder="Search Accounts"
                    type="text"
                    @input="onFilter"
                    @keyup.enter="onReturn" />
                <div v-if="!available_accounts" class="my-2 text-center">
                    <Icon i="spinner" spin />loading available accounts
                </div>
                <div v-if="final_countdown" class="my-2">
                    <Icon i="spinner" spin />logging into selected account, one moment!
                </div>
                <div class="list-group" id="select-account-list">
                    <button
                        v-for="account in filtered_list"
                        :class="[
                            'list-group-item',
                            'list-group-item-action',
                            { disabled: final_countdown },
                        ]"
                        :key="account.number"
                        @click="onSet(account.number)">
                        <div class="row">
                            <span class="col">{{ account.full_name }}</span>
                            <span class="col"
                                >{{ account.municipality }}, {{ account.province }}</span
                            >
                            <span class="col text-right"
                                >{{ account.agent_code }} ({{ account.agency_type }})</span
                            >
                        </div>
                    </button>
                    <div
                        v-if="no_accounts"
                        class="list-group-item list-group-item--action disabled">
                        <i>no accounts to select</i>
                    </div>
                </div>
            </div>
            <div class="col-xs-12 col-sm-6">
                <h3 class="text-center">Recent Accounts</h3>
                <div v-if="!available_accounts" class="my-2 text-center">
                    <Icon i="spinner" spin />waiting for available accounts
                </div>
                <div class="list-group d-flex justify-content-between">
                    <button
                        v-for="account in recent_list"
                        :class="[
                            'list-group-item',
                            'list-group-item-action',
                            { disabled: final_countdown },
                        ]"
                        :key="account.number"
                        @click="onSet(account.number)">
                        <div class="row">
                            <span class="col">{{ account.full_name }}</span>
                            <span class="col"
                                >{{ account.municipality }}, {{ account.province }}</span
                            >
                            <span class="col text-right"
                                >{{ account.agent_code }} ({{ account.agency_type }})</span
                            >
                        </div>
                    </button>
                    <div v-if="no_recent" class="list-group-item list-group-item--action disabled">
                        <i>no recently selected accounts</i>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios';
import _ from 'lodash';
import localstore from 'store2';
export default {
    name: 'AccountSelector',
    components: {},
    data: function () {
        return {
            loading: true,
            available_accounts: null,
            final_countdown: false,
            filter_string: '',
        };
    },
    computed: {
        filtered_list() {
            if (this.filter_string === '') {
                return _(this.available_accounts).slice(0, 15).sortBy('full_name').value();
            } else if (this.available_accounts) {
                const parts = this.filter_string.split(' ');
                return _(this.available_accounts)
                    .filter((item) => {
                        return _.some(parts, (part) => {
                            part = part.toLowerCase();
                            const agent_code = item.agent_code ? item.agent_code : '';
                            if (
                                String(item.number).indexOf(part) != -1 ||
                                item.full_name.toLowerCase().indexOf(part) != -1 ||
                                item.legal_full_name.toLowerCase().indexOf(part) != -1 ||
                                agent_code.toLowerCase().indexOf(part) != -1 ||
                                item.domain_names.join(', ').toLowerCase().indexOf(part) != -1
                            ) {
                                return true;
                            } else {
                                return false;
                            }
                        });
                    })
                    .slice(0, 15)
                    .sortBy('full_name')
                    .value();
            }
            return [];
        },
        no_accounts() {
            return this.loading === false && !this.available_accounts;
        },
        no_recent() {
            const history = localstore.get('account_history', []);
            return (
                this.loading === false && (!this.available_accounts || !history || !history.length)
            );
        },
        recent_list() {
            if (!this.available_accounts) {
                return null;
            }
            const history = localstore.get('account_history', []);
            return _(history)
                .map((number) => {
                    return _.find(this.available_accounts, (account) => account.number === number);
                })
                .filter((item) => Boolean(item))
                .value();
        },
        more() {
            return (
                (this.available_accounts.length || []).length > (this.filtered_list || []).length
            );
        },
    },
    mounted() {
        axios.get(`/backend/api/frontend/users/`).then((resp) => {
            this.loading = false;
            this.available_accounts = resp.data;
        });
    },
    methods: {
        onFilter(event) {
            if (this.final_countdown) {
                return;
            }
            this.filter_string = event.target.value;
        },
        onSet(number) {
            this.add_history(number);
            this.final_countdown = true;

            axios.put(`/backend/api/frontend/users/${number}/`).then((resp) => {
                this.$store.dispatch('get_user_bundle');
            });

            if (this.$route.path !== '/') {
                this.$router.push('/');
            }
        },
        onReturn() {
            if (this.filtered_list) {
                this.onSet(this.filtered_list[0].number);
            }
        },
        add_history(number) {
            let history = localstore.get('account_history', []);
            history = history.filter((item) => {
                return item !== number;
            });
            history.unshift(number);
            history = history.splice(0, 15);
            localstore.set('account_history', history);
        },
    },
};
</script>
<style lang="scss" scoped>
.page_main_col {
    padding: 15px;
    text-align: left;
}
</style>
