<template>
    <div>
        <div class="paper text-left p-3 col-12 offset-lg-1 col-lg-10">
            <div class="d-flex justify-content-between flex-wrap">
                <h3>Transfer a domain</h3>
                <button
                    class="btn btn-outline-secondary"
                    v-if="stage === 'config'"
                    variant="outline-secondary"
                    @click="reset"
                >
                    <Icon i="redo" />&nbsp;Start Over
                </button>
            </div>
            <hr />
            <transition name="fade" mode="out-in">
                <div v-if="stage == 'select'">
                    <h5>Select your domain name</h5>
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text">https://www.</span>
                        </div>
                        <input
                            @input="onDomainInput"
                            type="text"
                            :class="['form-control', { 'is-invalid': domain_name_input_error }]"
                            placeholder="example.ca"
                            aria-label="Domain name"
                            aria-describedby="basic-addon2"
                            :value="domain_name_input"
                            @keyup.enter="onSelect"
                        />
                        <div class="input-group-append">
                            <b-button
                                variant="primary"
                                type="button"
                                :disabled="disableSearch"
                                @click="onSelect"
                            >
                                <Icon i="search" />&nbsp;Select
                            </b-button>
                        </div>
                        <div class="invalid-feedback">{{ domain_name_input_error }}</div>
                    </div>

                    <div
                        v-if="selected_domain_name && !domain_info_bundle"
                        class="text-center mt-3"
                    >
                        <Icon i="spinner" spin />&nbsp;Loading...
                    </div>

                    <div
                        v-else-if="
                            selected_domain_name &&
                            domain_info_bundle &&
                            domain_info_bundle.found_match
                        "
                        class="mt-3"
                    >
                        <b-alert v-if="domain_info_bundle.error" show variant="danger">{{
                            domain_info_bundle.error
                        }}</b-alert>

                        <h5>Domain Info</h5>

                        <div class="row">
                            <div class="col-xs-12 col-sm-4">
                                <div class="py-3">
                                    <b>Registrar:</b>
                                    <p>
                                        {{ domain_info_bundle.registrar_name }}
                                        <br />
                                        {{ domain_info_bundle.registrar_email }}
                                        <br />
                                        {{ domain_info_bundle.registrar_phone }}
                                    </p>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-6 offset-sm-2">
                                <b>Checklist:</b>
                                <hr />
                                <b>Lock Status</b>
                                <div v-if="domain_info_bundle.domain_locked" class="text-danger">
                                    <Icon i="lock" />&nbsp;Locked
                                </div>
                                <div v-else><Icon i="lock-open" />&nbsp;Unlocked</div>
                                <p>
                                    The domain must be unlocked at the Registrar before the transfer
                                    can begin
                                </p>
                                <hr />

                                <b>Admin Email</b>
                                <div>
                                    <b>{{ domain_info_bundle.domain_admin_email }}</b>
                                </div>
                                <p>Do you have access to this email address?</p>
                                <hr />

                                <b>Auth Code</b>
                                <div class="form-group">
                                    <input
                                        type="text"
                                        class="form-control"
                                        id="exampleFormControlInput2"
                                        placeholder="Enter the auth code"
                                        v-model="auth_code"
                                    />
                                </div>
                                <p>
                                    The domain must be unlocked at the Registrar before the transfer
                                    can begin
                                </p>
                            </div>
                        </div>
                        <div class="d-flex justify-content-end">
                            <b-button-group>
                                <b-button
                                    variant="primary"
                                    @click="onContinue"
                                    :disabled="!infoReady || domain_info_bundle.error"
                                    >Continue</b-button
                                >
                                <b-button variant="primary" @click="onRefresh">Refresh</b-button>
                            </b-button-group>
                        </div>
                    </div>
                    <div
                        v-else-if="
                            selected_domain_name &&
                            domain_info_bundle &&
                            !domain_info_bundle.found_match
                        "
                        class="mt-3"
                    >
                        <div>No registered domain found.</div>
                    </div>
                </div>
                <div v-else-if="stage === 'config'">
                    <h5>
                        Select Domain Type for
                        <b>{{ selected_domain_name }}</b>
                    </h5>
                    <div class="row domain-option-selector">
                        <div class="col-xs-12 col-sm-6 domain-option text-center mb-4">
                            <h4>Enhanced Microsite</h4>
                            <p>$34/month</p>

                            <button
                                v-if="domain_option === 'c1'"
                                class="btn btn-outline-primary"
                                @click="onDomainOption('c1')"
                            >
                                <Icon i="check-circle" />&nbsp;Enhanced Microsite Selected
                            </button>
                            <button v-else class="btn btn-primary" @click="onDomainOption('c1')">
                                <Icon i="circle" />&nbsp;Select Enhanced Microsite
                            </button>
                            <h5>Enhanced Microsite benefits:</h5>
                            <ul class="text-left mt-2">
                                <li>
                                    Fresh, clean look that sets your agency apart as dedicated to
                                    your local community.
                                </li>
                                <li>
                                    Better SEO performance for local Search Engine Result Pages
                                    (SERPs)
                                </li>
                                <li>
                                    Faster load times of the EM means fewer users "bouncing" away
                                    from your site.
                                </li>
                                <li>
                                    Personalize the look and feel of your website with the new EM
                                    editor!
                                </li>
                            </ul>
                        </div>

                        <div class="col-xs-12 col-sm-6 domain-option text-center">
                            <h4>Parked Domain</h4>
                            <p>$9/month</p>
                            <button
                                v-if="domain_option === 'parked'"
                                class="btn btn-outline-primary"
                                @click="onDomainOption('parked')"
                            >
                                <Icon i="check-circle" />&nbsp;Parked Selected
                            </button>
                            <button
                                v-else
                                class="btn btn-primary"
                                @click="onDomainOption('parked')"
                                :disabled="!can_park"
                            >
                                <Icon i="circle" />&nbsp;Select Parked
                            </button>
                            <h5>Why get a Parked Domain?</h5>
                            <ul class="text-left mt-2">
                                <li v-if="!can_park" class="text-danger">
                                    Requires at least one Enhanced Microsite
                                </li>
                                <li>
                                    Gracefully transition away from an old domain name to a new one
                                    without losing customer traffic.
                                </li>
                                <li>
                                    Hold on to a domain which you have future plans for, but are not
                                    actively using at the moment.
                                </li>
                                <li>Use exact-match domain names in your online marketing.</li>
                            </ul>
                        </div>
                    </div>
                    <h5>Review your payment</h5>
                    <div
                        v-if="card === false"
                        class="d-flex justify-content-between align-items-baseline"
                    >
                        <p><Icon i="spinner" spin />&nbsp;Loading payment methods...</p>
                    </div>
                    <div
                        v-else-if="card === null"
                        class="d-flex justify-content-between align-items-baseline"
                    >
                        <p>Payment Method: no payment method on file!</p>
                        <b-button
                            v-if="show_payment_options"
                            variant="outline-secondary"
                            @click="onTogglePaymentOptions"
                            >Done Configuring Payment Method</b-button
                        >
                        <b-button v-else variant="outline-secondary" @click="onTogglePaymentOptions"
                            >Configure Payment Method</b-button
                        >
                    </div>
                    <div v-else class="d-flex justify-content-between align-items-baseline">
                        <p>Payment Method: {{ card.masked_card_number }}</p>
                        <b-button
                            v-if="show_payment_options"
                            variant="outline-secondary"
                            @click="onTogglePaymentOptions"
                            >Done Configuring Payment Method</b-button
                        >
                        <b-button v-else variant="outline-secondary" @click="onTogglePaymentOptions"
                            >Configure Payment Method</b-button
                        >
                    </div>
                    <b-collapse id="payment-method-configurator" :visible="show_payment_options">
                        <EmbeddedCCList @selected="onCard" />
                        <div class="d-flex justify-content-end">
                            <b-button variant="outline-secondary" @click="onTogglePaymentOptions"
                                >Close</b-button
                            >
                        </div>
                    </b-collapse>
                    <hr />
                    <div class="d-flex justify-content-around mt-4">
                        <b-button variant="primary" :disabled="!can_purchase" @click="onConfirm"
                            >Begin Transfer</b-button
                        >
                    </div>
                </div>
                <div v-else-if="stage === 'done'">
                    <h5>
                        Transfer of
                        <b>{{ selected_domain_name }}</b> successfully initiated!
                    </h5>
                    <div>
                        You can view or configure it over on your
                        <router-link to="/domains">domains page</router-link>
                    </div>
                </div>
            </transition>
        </div>
        <BillingChange
            :action="billing_action"
            :open="show_finalizing_modal"
            @confirm="onTransfer"
            @close="onHide"
        />
        <b-modal
            :visible="finalizing || purchase_error"
            centered
            hide-header
            hide-footer
            ok-title="Confirm"
            @close="hideError"
        >
            <div v-if="purchase_error">
                <p class="text-danger">
                    We encountered an error while attempting to purchase
                    <b>{{ selected_domain_name }}</b
                    >. Please try again later!
                </p>
                <b-button variant="primary" @click="reloadPage">Ok</b-button>
            </div>
            <div v-else-if="finalizing">
                <Icon i="spinner" spin />&nbsp;Processing your order...
            </div>
        </b-modal>
    </div>
</template>
<script>
import axios from 'axios';
import { parse, format } from 'date-fns';
import { mapGetters } from 'vuex';

import { DOMAIN } from '@/utils/regexes.js';

import EmbeddedCCList from '@/components/EmbeddedCCList.vue';
import BillingChange from '@/components/BillingChange.vue';

export default {
    name: 'TransferDomain',
    components: {
        EmbeddedCCList,
        BillingChange,
    },
    data: function () {
        return {
            stage: 'select',
            selected_domain_name: null,
            domain_name_input: '',
            domain_name_input_error: null,

            domain_info_bundle: null,
            auth_code: null,

            domain_option: 'c1',
            show_payment_options: false,

            show_finalizing_modal: false,
            finalizing: false,
            purchase_error: false,

            card: false,
        };
    },
    computed: {
        ...mapGetters(['account']),
        disableSearch() {
            return !this.domain_name_input || this.domain_name_input.trim().length == 0;
        },
        infoReady() {
            return (
                this.domain_info_bundle &&
                this.auth_code &&
                this.domain_info_bundle.domain_locked == false
            );
        },
        has_payment_method() {
            return Boolean(this.card);
        },
        can_park() {
            const domains = this.$store.getters.domains;
            return _.some(domains, (domain) => {
                console.log(domain.domain_type);
                return domain.domain_type == 'c1';
            });
        },
        can_purchase() {
            return (
                Boolean(this.selected_domain_name) &&
                Boolean(this.domain_option) &&
                this.has_payment_method
            );
        },
        billing_action() {
            if (this.domain_option == 'c1') {
                return 'buy_full';
            }
            return 'buy_parked';
        },
    },
    methods: {
        reset() {
            this.selected_domain_name = null;
            this.domain_info_bundle = null;
            this.domain_name_input = '';
            this.auth_code = null;
            this.domain_option = null;
            this.show_payment_options = false;
            this.stage = 'select';
        },
        validDomain(text) {
            if (!text) {
                this.domain_name_input_error = 'Please enter a domain name to search';
                return false;
            }
            if (!text.match(DOMAIN)) {
                this.domain_name_input_error = 'Please enter a valid domain name ';
                return false;
            }
            return true;
        },
        onDomainInput(event) {
            this.domain_name_input = event.target.value;
            this.domain_name_input_error = null;
            // this.validDomain(this.domain_name_input);
        },
        getDomainTransferDetails() {
            if (!this.validDomain(this.selected_domain_name)) {
                return;
            }

            axios({
                method: 'get',
                url: `/backend/api/frontend/accounts/${this.account.account_number}/domains/transfer_details/`,
                params: { name: this.selected_domain_name },
            })
                .then((resp) => {
                    if (resp.data.found_match) {
                        this.domain_info_bundle = resp.data;
                    } else {
                        this.selected_domain_name = null;
                        this.domain_name_input_error =
                            'This domain is not registered and cannot be transferred.';
                    }
                })
                .catch((err) => {
                    this.$store.commit('add_alert', {
                        message: `there was a problem getting info on the domain "${this.selected_domain_name}"`,
                        varient: 'danger',
                    });
                });
        },
        onSelect() {
            this.selected_domain_name = this.domain_name_input;
            this.domain_info_bundle = null;
            this.getDomainTransferDetails();
        },
        onRefresh() {
            this.domain_info_bundle = null;
            this.getDomainTransferDetails();
        },
        onContinue() {
            if (this.infoReady) {
                this.stage = 'config';
            }
        },

        onDomainOption(option) {
            this.domain_option = this.domain_option == option ? null : option;
        },
        onTogglePaymentOptions() {
            this.show_payment_options = !this.show_payment_options;
        },
        onConfirm() {
            this.show_finalizing_modal = true;

            setTimeout(() => {
                this.billing_details = {
                    monthly_rate: '15.00',
                    monthly_inclease: '1.00',
                    next_billing_day: '2018-10-10',
                    prorated_amount: '2.00',
                };
            }, 1000);
        },
        onHide(evt) {
            this.show_finalizing_modal = false;
            this.billing_details = null;
        },
        hideError() {
            if (this.finalizing || this.purchase_error) {
                return evt.preventDefault();
            }
        },
        onTransfer(evt) {
            this.finalizing = true;

            axios({
                method: 'post',
                url: `/backend/api/frontend/accounts/${this.account.account_number}/domains/`,
                data: {
                    name: this.selected_domain_name,
                    domain_type: this.domain_option,
                    is_transfer: true,
                    transfer_auth_code: this.auth_code,
                },
            })
                .then(() => {
                    return this.$store.dispatch('invalidate_domains');
                })
                .then((resp) => {
                    this.finalizing = false;
                    this.billing_details = null;
                    this.show_finalizing_modal = false;
                    this.stage = 'done';
                })
                .catch((err) => {
                    this.purchase_error = true;
                });
        },
        reloadPage() {
            window.location.reload();
        },

        onCard(card) {
            this.card = card;
        },
    },
};
</script>
<style lang="scss" scoped>
.info-segment {
    max-width: 300px;
}
</style>
