<template>
    <div>
        <div class="paper text-left p-3 col-12 offset-lg-1 col-lg-10 pb-4">
            <div class="d-flex justify-content-between flex-wrap">
                <h3>Purchase a domain</h3>
                <button
                    class="btn btn-outline-secondary"
                    v-if="stage === 'config'"
                    variant="outline-secondary"
                    @click="reset"
                >
                    <Icon i="redo" />&nbsp;Start Over
                </button>
            </div>
            <hr />
            <transition name="fade" mode="out-in">
                <div v-if="stage == 'search'">
                    <h5>Enter a domain name</h5>
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text">https://www.</span>
                        </div>
                        <input
                            :class="[
                                'form-control',
                                { 'is-invalid': Boolean(domain_name_input_error) },
                            ]"
                            aria-label="Domain name"
                            aria-describedby="basic-addon2"
                            placeholder="example.ca"
                            type="text"
                            @keyup.enter="onSearch"
                            @input="onDomainInput"
                            :value="domain_name_input"
                        />
                        <div class="input-group-append">
                            <b-button
                                :disabled="disableSearch"
                                id="submit-search-button"
                                variant="primary"
                                type="button"
                                @click="onSearch"
                            >
                                <Icon i="search" />&nbsp;Search
                            </b-button>
                        </div>
                        <div class="invalid-feedback">{{ domain_name_input_error }}</div>
                    </div>
                    <div v-if="searching" key="loading" class="mt-4 text-center">
                        <Icon i="spinner" spin />&nbsp;searching...
                    </div>
                    <div v-else-if="domain_results" class="mt-4" key="done">
                        <b-alert v-if="domain_results.error" show variant="danger">{{
                            domain_results.error
                        }}</b-alert>

                        <h5 class="text-center">Results</h5>
                        <ul class="list-group" id="search-results">
                            <li class="list-group-item">Exact Match</li>
                            <li
                                v-if="domain_results.exact"
                                class="list-group-item domain-list-item"
                                :class="{
                                    'struck-through-the-heart': !domain_results.exact.available,
                                }"
                            >
                                <b>{{ domain_results.exact.name }}</b>
                                <span v-if="!domain_results.exact.available"
                                    >{{ domain_results.exact.name }} not available.</span
                                >
                                <b-button
                                    variant="primary"
                                    :disabled="!domain_results.exact.available"
                                    @click="onDomainSelect(domain_results.exact.name)"
                                    >Select</b-button
                                >
                            </li>
                            <li class="list-group-item">Similar Domains</li>
                            <li
                                v-for="domain in domain_results.suggestions"
                                :key="domain.name"
                                :class="{ 'struck-through-the-heart': !domain.available }"
                                class="list-group-item domain-list-item"
                            >
                                <b>{{ domain.name }}</b>
                                <span v-if="!domain.available"
                                    >{{ domain.name }} not available.</span
                                >
                                <b-button
                                    variant="primary"
                                    :disabled="!domain.available"
                                    @click="onDomainSelect(domain.name)"
                                    >Select</b-button
                                >
                            </li>
                        </ul>
                    </div>
                </div>
                <div v-else-if="stage === 'config'">
                    <h5>
                        Select Domain Type for
                        <b>{{ selected_domain_name }}</b>
                    </h5>
                    <div class="row domain-option-selector">
                        <div class="col-xs-12 col-sm-6 domain-option text-center mb-4">
                            <h4>Enhanced Microsite</h4>
                            <p>$34/month</p>
                            <button
                                v-if="domain_option === 'c1'"
                                class="btn btn-outline-primary"
                                @click="onDomainOption('c1')"
                            >
                                <Icon i="check-circle" />&nbsp;Enhanced Microsite Selected
                            </button>
                            <button v-else class="btn btn-primary" @click="onDomainOption('c1')">
                                <Icon i="circle" />&nbsp;Select Enhanced Microsite
                            </button>
                            <h5>Enhanced Microsite benefits:</h5>
                            <ul class="text-left mt-2">
                                <li>
                                    Fresh, clean look that sets your agency apart as dedicated to
                                    your local community.
                                </li>
                                <li>
                                    Better SEO performance for local Search Engine Result Pages
                                    (SERPs)
                                </li>
                                <li>
                                    Faster load times of the EM means fewer users "bouncing" away
                                    from your site.
                                </li>
                                <li>
                                    Personalize the look and feel of your website with the new EM
                                    editor!
                                </li>
                            </ul>
                        </div>

                        <div class="col-xs-12 col-sm-6 domain-option text-center">
                            <h4>Parked Domain</h4>
                            <p>$9/month</p>
                            <button
                                v-if="domain_option === 'parked'"
                                class="btn btn-outline-primary"
                                @click="onDomainOption('parked')"
                            >
                                <Icon i="check-circle" />&nbsp;Parked Selected
                            </button>
                            <button
                                v-else
                                class="btn btn-primary"
                                :disabled="!can_park"
                                @click="onDomainOption('parked')"
                            >
                                <Icon i="circle" />&nbsp;Select Parked
                            </button>
                            <h5>Why get a Parked Domain?</h5>
                            <ul class="text-left mt-2">
                                <li v-if="!can_park" class="text-danger">
                                    Requires at least one Enhanced Microsite
                                </li>
                                <li>
                                    Gracefully transition away from an old domain name to a new one
                                    without losing customer traffic.
                                </li>
                                <li>
                                    Hold on to a domain which you have future plans for, but are not
                                    actively using at the moment.
                                </li>
                                <li>Use exact-match domain names in your online marketing.</li>
                            </ul>
                        </div>
                    </div>
                    <h5>Review your payment</h5>
                    <div
                        v-if="card === false"
                        class="d-flex justify-content-between align-items-baseline"
                    >
                        <p><Icon i="spinner" spin />&nbsp;Loading payment methods...</p>
                    </div>
                    <div
                        v-else-if="card === null"
                        class="d-flex justify-content-between align-items-baseline"
                    >
                        <p>Payment Method: no payment method on file!</p>
                        <b-button
                            v-if="show_payment_options"
                            variant="outline-secondary"
                            @click="onTogglePaymentOptions"
                            >Done Configuring Payment Method</b-button
                        >
                        <b-button v-else variant="outline-secondary" @click="onTogglePaymentOptions"
                            >Configure Payment Method</b-button
                        >
                    </div>
                    <div v-else class="d-flex justify-content-between align-items-baseline">
                        <p>Payment Method: {{ card.masked_card_number }}</p>
                        <b-button
                            v-if="show_payment_options"
                            variant="outline-secondary"
                            @click="onTogglePaymentOptions"
                            >Done Configuring Payment Method</b-button
                        >
                        <b-button v-else variant="outline-secondary" @click="onTogglePaymentOptions"
                            >Configure Payment Method</b-button
                        >
                    </div>
                    <b-collapse id="payment-method-configurator" :visible="show_payment_options">
                        <EmbeddedCCList @selected="onCard" />
                        <div class="d-flex justify-content-end">
                            <b-button variant="outline-secondary" @click="onTogglePaymentOptions"
                                >Close</b-button
                            >
                        </div>
                    </b-collapse>
                    <hr />
                    <div class="d-flex justify-content-around mt-4">
                        <b-button variant="primary" :disabled="!can_purchase" @click="onConfirm"
                            >Purchase</b-button
                        >
                    </div>
                </div>
                <div v-else-if="stage === 'done'">
                    <h5>
                        Successfully purchased
                        <b>{{ selected_domain_name }}</b
                        >!
                    </h5>
                    <div>
                        You can view or configure it over on your
                        <router-link to="/domains">domains page</router-link>
                    </div>
                </div>
            </transition>
        </div>
        <BillingChange
            :action="billing_action"
            :open="show_finalizing_modal"
            @confirm="onBuy"
            @close="onHide"
        />
        <b-modal
            :visible="purchase_error || finalizing"
            centered
            hide-header
            hide-footer
            ok-title="Confirm"
            @hide="hideError"
        >
            <div v-if="purchase_error">
                <p class="text-danger">
                    We encountered an error while attempting to purchase
                    <b>{{ selected_domain_name }}</b
                    >. Please try again later!
                </p>
                <b-button variant="primary" @click="reloadPage">Ok</b-button>
            </div>
            <div v-else-if="finalizing">
                <Icon i="spinner" spin />&nbsp;Processing your order...
            </div>
        </b-modal>
    </div>
</template>
<script>
import axios from 'axios';
import _ from 'lodash';
import { parse, format } from 'date-fns';
import { mapGetters } from 'vuex';

import { DOMAIN } from '@/utils/regexes.js';
import EmbeddedCCList from '@/components/EmbeddedCCList.vue';
import BillingChange from '@/components/BillingChange.vue';

export default {
    name: 'PurchaseDomain',
    components: {
        EmbeddedCCList,
        BillingChange,
    },
    data: function () {
        return {
            stage: 'search',
            selected_domain_name: null,
            domain_name_input: '',
            domain_name_input_error: false,
            domain_results: null,
            searching: false,

            domain_option: 'c1',
            show_payment_options: false,

            show_finalizing_modal: false,
            billing_details: null,
            finalizing: false,
            purchase_error: false,

            card: false,
        };
    },
    computed: {
        ...mapGetters(['account']),
        disableSearch() {
            return Boolean(this.domain_name_input_error);
        },
        has_payment_method() {
            return Boolean(this.card);
        },
        can_park() {
            const domains = this.$store.getters.domains;
            return _.some(domains, (domain) => {
                console.log(domain.domain_type);
                return domain.domain_type == 'c1';
            });
        },
        can_purchase() {
            return (
                Boolean(this.selected_domain_name) &&
                Boolean(this.domain_option) &&
                this.has_payment_method
            );
        },
        next_billing_day() {
            if (this.billing_details) {
                return format(
                    parse(this.billing_details.next_billing_day, 'yyyy-MM-dd', new Date()),
                    'MMMM dd, yyyy',
                );
            }
            return '';
        },
        billing_action() {
            if (this.domain_option == 'c1') {
                return 'buy_full';
            }
            return 'buy_parked';
        },
    },
    methods: {
        reset() {
            this.selected_domain_name = null;
            this.domain_results = null;
            this.domain_name_input = '';
            this.domain_option = null;
            this.show_payment_options = false;
            this.stage = 'search';
            this.searching = false;
        },
        validDomain() {
            if (!this.domain_name_input) {
                this.domain_name_input_error = 'Please enter a domain name to search';
                return false;
            }
            if (!this.domain_name_input.match(DOMAIN)) {
                this.domain_name_input_error = 'Please enter a valid domain name ';
                return false;
            }
            if (this.domain_name_input.toLowerCase().startsWith('www.')) {
                this.domain_name_input_error = `Please remove the "www." part of your search `;
                return false;
            }
            return true;
        },
        onDomainInput(event) {
            this.domain_name_input = event.target.value;
            this.domain_name_input_error = null;

            // don't throw errors while they are typing. most distracting wot wot.
            // this.validDomain();
        },
        async onSearch() {
            this.selected_domain_name = this.domain_name_input;
            if (!this.validDomain()) {
                this.selected_domain_name = null;
                return;
            }
            this.searching = true;
            try {
                const resp = await axios({
                    method: 'get',
                    url: `/backend/api/frontend/accounts/${this.account.account_number}/domains/availability/`,
                    params: { name: this.selected_domain_name },
                });
                this.searching = false;
                this.domain_results = resp.data;
            } catch (err) {
                this.$store.commit('add_alert', {
                    message: `there was a problem getting info on the domain "${this.selected_domain_name}"`,
                    varient: 'danger',
                });
                this.reset();
            }
        },
        onDomainSelect(name) {
            this.selected_domain_name = name;
            this.stage = 'config';
        },
        onDomainOption(option) {
            this.domain_option = this.domain_option == option ? null : option;
        },
        onTogglePaymentOptions() {
            this.show_payment_options = !this.show_payment_options;
        },
        onConfirm() {
            this.show_finalizing_modal = true;
        },
        onHide(evt) {
            this.show_finalizing_modal = false;
        },
        hideError(evt) {
            if (this.finalizing || this.purchase_error) {
                evt.preventDefault();
            }
        },
        onBuy(evt) {
            this.show_finalizing_modal = false;
            this.finalizing = true;

            axios({
                method: 'post',
                url: `/backend/api/frontend/accounts/${this.account.account_number}/domains/`,
                data: {
                    name: this.selected_domain_name,
                    domain_type: this.domain_option,
                    is_transfer: false,
                },
            })
                .then(() => {
                    return this.$store.dispatch('invalidate_domains');
                })
                .then((resp) => {
                    this.finalizing = false;
                    this.billing_details = null;
                    this.stage = 'done';
                })
                .catch((err) => {
                    console.log('error', err);
                    this.purchase_error = true;
                });
        },
        reloadPage() {
            window.location.reload();
        },
        onCard(card) {
            this.card = card;
        },
    },
};
</script>
<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
}

.struck-through-the-heart b {
    text-decoration: line-through;
    &:hover {
        text-decoration: line-through;
    }
}
.domain-list-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.domain-option-selector {
}

.domain-option {
    // padding-top: 15px;
}
</style>
