<template>
    <b-form class="card mx-auto p-3" @submit.stop.prevent="onSave" novalidate>
        <!--        <h2 class="header pb-3">{{ isNew ? 'Add New' : 'Edit' }} Card</h2>-->

        <b-form-group label="Card Details" label-size="lg">
            <b-row>
                <b-col xs="12" md="8" lg="4">
                    <label for="full_number">Card Number</label>
                    <b-form-input
                        class="form-input"
                        type="text"
                        :state="validateState('full_number')"
                        id="full_number"
                        name="full_number"
                        v-model="card.full_number"
                        data-vv-delay="750"
                        v-validate="{ required_if: isNew, credit_card: true }"
                        maxlength="16"
                        :placeholder="card.masked_card_number || 'XXXX-XXXX-XXXX-XXXX'"
                        :disabled="saving" />
                    <div v-show="veeErrors.has('full_number')" class="invalid-feedback">
                        {{ veeErrors.first('full_number') }}
                    </div>
                </b-col>
                <b-col xs="12" md="4" lg="2">
                    <label for="cvv">CVV</label>
                    <b-form-input
                        class="form-input"
                        :state="validateState('cvv')"
                        id="cvv"
                        name="cvv"
                        type="number"
                        v-validate="{ required_if: isNew, numeric: true }"
                        v-model="card.cvv"
                        maxlength="4"
                        placeholder="CVV"
                        :disabled="saving" />
                    <div v-show="veeErrors.has('cvv')" class="invalid-feedback">
                        {{ veeErrors.first('cvv') }}
                    </div>
                </b-col>
                <b-col sm="6" md="4" lg="3">
                    <label for="expiration_month">Expiration Month</label>
                    <b-form-input
                        class="form-input"
                        :state="validateState('expiration_month')"
                        id="expiration_month"
                        name="expiration_month"
                        type="number"
                        min="1"
                        max="12"
                        v-validate="`required|month:expiration_year`"
                        v-model.trim="card.expiration_month"
                        placeholder="00"
                        :disabled="saving" />
                    <div v-show="veeErrors.has('expiration_month')" class="invalid-feedback">
                        {{ veeErrors.first('expiration_month') }}
                    </div>
                </b-col>
                <b-col sm="6" md="4" lg="3">
                    <label for="expiration_year">Expiration Year</label>
                    <b-form-input
                        class="form-input"
                        :state="validateState('expiration_year')"
                        id="expiration_year"
                        name="expiration_year"
                        ref="expiration_year"
                        type="number"
                        v-validate="`required|min_value:${new Date().getFullYear()}`"
                        :min="new Date().getFullYear()"
                        v-model.trim="card.expiration_year"
                        placeholder="0000"
                        :disabled="saving" />
                    <div v-show="veeErrors.has('expiration_year')" class="invalid-feedback">
                        {{ veeErrors.first('expiration_year') }}
                    </div>
                </b-col>
            </b-row>
            <b-row>
                <b-col sm="12" md="6">
                    <label for="first_name">First Name</label>
                    <b-form-input
                        class="form-input"
                        type="text"
                        :state="validateState('first_name')"
                        id="first_name"
                        name="first_name"
                        v-validate="'required'"
                        v-model="card.first_name"
                        maxlength="18"
                        placeholder="Enter First Name"
                        :disabled="saving" />
                    <div v-show="veeErrors.has('first_name')" class="invalid-feedback">
                        {{ veeErrors.first('first_name') }}
                    </div>
                </b-col>
                <b-col sm="12" md="6">
                    <label for="last_name">Last Name</label>
                    <b-form-input
                        class="form-input"
                        type="text"
                        :state="validateState('last_name')"
                        id="last_name"
                        name="last_name"
                        v-validate="'required'"
                        v-model="card.last_name"
                        maxlength="18"
                        placeholder="Enter Last Name"
                        :disabled="saving" />
                    <div v-show="veeErrors.has('last_name')" class="invalid-feedback">
                        {{ veeErrors.first('last_name') }}
                    </div>
                </b-col>
            </b-row>
        </b-form-group>
        <b-form-group label="Billing Address" label-size="lg">
            <b-row>
                <b-col sm="12">
                    <label for="billing_address">Street Address</label>
                    <b-form-input
                        class="form-input"
                        id="billing_address"
                        name="billing_address"
                        type="text"
                        v-validate="{ required: true }"
                        :state="validateState('billing_address')"
                        v-model="card.billing_address"
                        maxlength="40"
                        placeholder="1234 East St."
                        :disabled="saving" />
                    <div v-show="this.veeErrors.has('billing_address')" class="invalid-feedback">
                        {{ this.veeErrors.first('billing_address') }}
                    </div>
                </b-col>
                <b-col sm="12">
                    <label for="billing_address_2">Unit / Suite / Apartment</label>
                    <b-form-input
                        class="form-input"
                        type="text"
                        :state="validateState('billing_address_2')"
                        id="billing_address_2"
                        name="billing_address_2"
                        v-model="card.billing_address_2"
                        maxlength="25"
                        placeholder="Suite 123"
                        :disabled="saving" />
                    <div v-show="veeErrors.has('billing_address_2')" class="invalid-feedback">
                        {{ veeErrors.first('billing_address_2') }}
                    </div>
                </b-col>

                <b-col xs="12" sm="4">
                    <label for="billing_city">City</label>
                    <b-form-input
                        class="form-input"
                        type="text"
                        :state="validateState('billing_city')"
                        v-validate="{ required: true, min: 3 }"
                        id="billing_city"
                        name="billing_city"
                        v-model="card.billing_city"
                        maxlength="20"
                        placeholder="Smallville"
                        :disabled="saving" />
                    <div v-show="veeErrors.has('billing_city')" class="invalid-feedback">
                        {{ veeErrors.first('billing_city') }}
                    </div>
                </b-col>
                <b-col xs="12" sm="4" class="provinceWrapper">
                    <label class="label" for="billing_state">Province</label>
                    <b-form-input
                        class="form-input"
                        type="text"
                        :state="validateState('billing_state')"
                        id="billing_state"
                        name="billing_state"
                        v-validate="'required'"
                        v-model="card.billing_state"
                        maxlength="25"
                        placeholder="Ontario"
                        :disabled="saving" />
                    <div v-show="veeErrors.has('billing_state')" class="invalid-feedback">
                        {{ veeErrors.first('billing_state') }}
                    </div>
                </b-col>
                <b-col xs="12" sm="4">
                    <label style="white-space: nowrap" for="billing_zip">Postal Code</label>
                    <b-form-input
                        class="form-input"
                        type="text"
                        :state="validateState('billing_zip')"
                        id="billing_zip"
                        name="billing_zip"
                        v-validate="'required'"
                        v-model="card.billing_zip"
                        maxlength="7"
                        placeholder="H3Z 2Y7"
                        :disabled="saving" />
                    <div v-show="veeErrors.has('billing_zip')" class="invalid-feedback">
                        {{ veeErrors.first('billing_zip') }}
                    </div>
                </b-col>
            </b-row>
        </b-form-group>

        <b-alert v-if="backend_error" show variant="danger">{{ backend_error }}</b-alert>

        <div v-if="saving" class="d-flex justify-content-end align-items-center">
            <Icon i="spinner" spin />Saving...
        </div>
        <div v-else class="btn-group d-flex justify-content-end py-3">
            <b-button variant="outline-secondary" @click="useOfficeAddress"
                >Use My Office Address</b-button
            >
            <b-button variant="primary" type="submit" :disabled="saving || veeErrors.any()"
                >Save</b-button
            >
            <b-button @click="$emit('edit', null)" variant="outline-secondary" :disabled="saving"
                >Cancel</b-button
            >
        </div>
    </b-form>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import { Validator } from 'vee-validate';

export default {
    name: 'CreditCardForm',

    props: {
        cards: { type: Array, required: true },
        id: String,
        onCancel: Function,
        disableClose: Boolean,
        loading: Boolean,
        isNew: Boolean,
        onClose: {
            type: Function,
            required: false,
            default: null,
        },
        reload: Function,
    },
    data() {
        return {
            saving: false,
            backend_error: null,
            pristine: true,
            card: {
                billing_address: '',
                billing_address_2: '',
                billing_city: '',
                billing_state: '',
                billing_zip: '',
                first_name: '',
                last_name: '',
                expiration_month: '',
                expiration_year: '',
            },
        };
    },
    computed: {
        ...mapGetters(['account']),
        office_address() {
            return this.account.agency.office.address;
        },
    },
    mounted() {
        this.card = {
            ...this.card,
            ...(this.cards || []).find((card) => +card.id === +this.id),
        };
        this.$emit('updateHeading', {
            showHeading: true,
            heading: `${this.isNew ? 'Add' : 'Edit'} Card`,
        });
        //This provides nicer formatting for the messages, which use the field names by default
        const dictionary = {
            custom: {
                billing_address: {
                    required: 'Street address is required',
                },
                billing_city: {
                    required: 'City is required',
                },
                billing_state: {
                    required: 'Province is required',
                },
                billing_zip: {
                    required: 'Postal Code is required',
                },
                full_number: {
                    required: 'Card number is required',
                    credit_card: 'Card number is not valid',
                },
                cvv: {
                    required: 'CVV (security code) is required',
                },
                first_name: {
                    required: () => 'First name is required',
                },
                last_name: {
                    required: () => 'Last name is required',
                },
                expiration_month: {
                    required: () => 'Expiration month is required',
                },
                expiration_year: {
                    required: () => 'Expiration year is required',
                    min_value: () => 'Year is already expired',
                },
            },
        };
        this.$validator.localize('en', dictionary);

        //this allows us to check if the month is expired based on the year
        //Otherwise we could just use between:1,12
        const monthValidator = {
            getMessage(field, params, message) {
                // will be added to default locale messages.
                // Returns a message.
                return message;
            },
            validate: (value, [year]) => {
                const { expiration_year, expiration_month } = this.card;
                const thisYear = new Date().getFullYear();
                const thisMonth = new Date().getMonth() + 1; //months are zero-based

                //This will be the only validation if no year is provided yet
                const validMonth = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].includes(+value);

                //Includes the current month because they probably don't want to use a card that close to expiration
                const currentMonth = year && !(+year === thisYear && +value <= thisMonth);

                return new Promise((resolve) => {
                    resolve({
                        valid: validMonth && (year ? currentMonth : true),
                        data: validMonth
                            ? currentMonth
                                ? undefined
                                : 'Month is already expired!'
                            : 'Please provide a valid month (1-12)',
                    });
                });
            },
        };
        this.$validator.extend('month', monthValidator, { hasTarget: true });
    },
    destroyed() {
        this.$emit('updateHeading', {
            showHeading: false,
            heading: ``,
        });
    },
    methods: {
        validateState(ref) {
            let valid = null;
            if (
                !this.pristine ||
                (this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].touched))
            ) {
                valid = !this.veeErrors.has(ref);
            }
            return valid;
        },
        useOfficeAddress() {
            this.card.billing_address = this.office_address.address_street;
            this.card.billing_address_2 = this.office_address.address_street2;
            this.card.billing_city = this.office_address.address_municipality;
            this.card.billing_state = this.office_address.address_province;
            this.card.billing_zip = this.office_address.address_postal_code;
        },
        onSave() {
            this.backend_error = null;
            this.pristine = false;
            this.$validator.validate().then((result) => {
                if (!result) {
                    return;
                }
                const account_number = this.$store.state.current_account.account_number;
                this.saving = true;
                axios({
                    method: this.isNew ? 'post' : 'put',
                    url: `/backend/api/frontend/accounts/${account_number}/payment/${
                        this.isNew ? '' : `${this.card.id}/`
                    }`,
                    data: { card: { ...this.card } },
                })
                    .then((resp) => {
                        if (!resp.data.success) {
                            this.backend_error = resp.data.reason;
                        } else {
                            this.reload().then(() => {
                                this.$emit('edit', null);
                            });
                        }
                    })
                    .catch((err) => {
                        this.backend_error =
                            'Encountered an error while saving your information. Please try again later';
                    })
                    .finally(() => {
                        this.saving = false;
                        if (!this.backend_error) {
                            this.$router.push(`/payment`);
                        }
                    });
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.loading-spinner {
    width: 100%;
    margin-top: 30px;
}
.card {
    width: 100%;
    background-color: $background-base;
    border: none;
    vertical-align: top;
    label {
        margin-top: 5px;
        margin-bottom: 0;
    }
}
.expiration {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: flex-end;
}
.provinceWrapper {
    display: flex;
    flex-direction: column;
    align-items: stretch;
}
.dropbtn {
    display: block;
    width: 100%;
    height: calc(2.25rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #8e8c91;
    border-radius: 0.25rem;
}
.dropdown {
    position: relative;
    display: inline-block;
}
.dropbtn a:focus {
    background-color: $white;
}
.dropdown-content {
    display: none;
    position: absolute;
    background-color: $white;
    min-width: 80px;
    max-height: 200px;
    overflow: scroll;
    z-index: 1;
}
.dropdown-content a {
    color: $black;
    padding: 12px 16px;
    display: block;
}
.dropdown-content a:hover {
    background-color: $primary;
}
.dropdown:hover .dropdown-content {
    display: block;
}
.label {
    white-space: nowrap;
    overflow: hidden;
}

.form-group {
    & + & {
        margin-top: 30px;
    }
}

.form-input {
    color: $gray-dark;
    font-weight: 500;
    &::placeholder {
        color: $gray-400;
        font-size: 0.9em;
        font-weight: 300;
    }
}
</style>
