<template>
    <div>
        <div class="paper text-left p-3 col-12 offset-lg-1 col-lg-10">
            <h1>
                Payment Settings
                <span v-show="showHeading" class="subheading">- {{ heading }}</span>
            </h1>
            <div
                v-if="loading"
                class="d-flex justify-content-center align-items-center loading-spinner"
            >
                <Icon i="spinner" spin />&nbsp; Loading Payment Info...
            </div>
            <router-view
                v-else
                :cards="cards"
                :loading="loading"
                :reload="fetchCardList"
                @edit="onEdit"
                @updateHeading="updateHeading"
            />
        </div>
    </div>
</template>
<script>
import axios from 'axios';

import PageContainer from '@/components/PageContainer.vue';
import PaymentList from '@/components/PaymentList.vue';
export default {
    name: 'PaymentSettings',
    components: {
        PageContainer,
        PaymentEditor: PaymentList,
    },
    data: function() {
        return {
            loading: false,
            cards: [],
            bread: [{ text: 'Dashboard', to: '/' }, { text: 'Payment Settings', to: '/payment' }],
            showHeading: false,
            heading: '',
        };
    },
    mounted() {
        this.fetchCardList();
    },
    methods: {
        updateHeading(update) {
            this.showHeading = update.showHeading;
            this.heading = update.heading;
        },
        onData() {
            let good_card =
                this.cards.find((card) => {
                    return card.is_default && !card.expired;
                }) || null;
            this.loading = false;
            this.$emit('selected', good_card);
        },
        onEdit(card_id) {
            if (!card_id) {
                this.$router.push('/payment');
            } else if (card_id === 'new') {
                this.$router.push('/payment/new');
            } else {
                this.$router.push(`/payment/edit/${card_id}`);
            }
        },
        async fetchCardList() {
            this.loading = true;
            const { account_number } = this.$store.state.current_account;
            try {
                const { data } = await axios.get(
                    `/backend/api/frontend/accounts/${account_number}/payment/`,
                );

                this.cards = data;
                this.onData();
            } catch (error) {
                console.error(error);
            }
        },
    },
};
</script>
<style lang="scss" scoped>
.subheading {
    color: $gray-400;
}
</style>
