<template>
    <div>
        <div class="mx-auto">
            <Loading />
        </div>
    </div>
</template>
<script>
import Loading from '@/components/elements/Loading.vue';

export default {
    name: 'Redirector',
    components: {
        Loading,
    },
    data: function() {
        return {};
    },
    created: function() {
        window.localStorage.destination_url = '';
        let dest = decodeURIComponent(
            window.location.search
                .split('&')
                .find((s) => s.includes('destination'))
                .split('=')[1],
        );

        if (dest) {
            window.localStorage.destination_url = dest;
            this.$router.push('/login');
        } else {
            this.$router.push('/');
        }
    },
};
</script>
<style lang="scss" scoped></style>
