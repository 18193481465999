<template>
    <div id="alert-boxes">
        <div v-for="(alert, index) in alerts" v-bind:key="index">
            <b-alert
                fade
                dismissible
                :variant="alert.varient"
                :show="alert.show"
                v-on:dismissed="updateAlert(index)"
            >
                {{ alert.message }}
            </b-alert>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Alerts',
    data() {
        return {
            alerts: this.$store.state.alerts,
        };
    },
    methods: {
        alert(message, varient = 'info') {
            this.alerts.splice(0, 0, { message, varient, show: true });
        },
        updateAlert(index) {
            setTimeout(() => {
                this.$store.commit('remove_alert', index);
            }, 1000);

            return function() {};
        },
    },
};
</script>

<style lang="scss" scoped>
#alert-boxes {
    margin: 3% 5%;
    position: fixed;
    right: 0px;
    z-index: 9999;

    .alert {
        border-radius: 0;
    }
}
</style>
