<template>
    <span>
        <slot />
        <a
            href="javascript:void(0)"
            @click.stop="open = !open"
            v-b-tooltip.hover="{ customClass: 'tooltip-fix' }"
            title="Click Here for Glossary Definition"
            class="pl-1"
        >
            <Icon i="info-circle"
        /></a>
        <b-modal v-model="open" title="Glossary Definition">
            <template slot="default">
                <KeywordContent :keyword="keyword" />
            </template>

            <template slot="modal-footer">
                <b-button variant="outline-secondary" to="/glossary">View Glossary</b-button>
                <b-button variant="primary" @click="open = !open">Ok</b-button>
            </template>
        </b-modal>
    </span>
</template>
<script>
import KeywordContent from '@/components/glossary/KeywordContent';
export default {
    name: 'InfoLink',
    components: { KeywordContent },
    props: { keyword: { type: String, required: true } },
    data() {
        return {
            open: false,
        };
    },
    methods: {
        onToggle() {
            this.open = !this.open;
        },
    },
};
</script>
<style scoped>
.tooltip-fix {
    pointer-events: none;
}
</style>
