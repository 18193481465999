import store from 'store2';
import { addHours } from 'date-fns';
import _ from 'lodash';

function _clean() {
    let stash = store.get('stats_cache', {});
    _.forEach(stash, (item, key) => {
        if (new Date(item.expires) < new Date()) {
            stash[key] = undefined;
        }
    });
    store.set('stats_cache', stash);
}

function cache(key, subkey, data, ttl) {
    _clean();
    let stash = store.get('stats_cache', {});
    if (subkey) {
        key = `${key}_${subkey}`;
    }
    const expires = addHours(new Date(), ttl);
    stash[key] = { data: data, expires: expires };
    store.set('stats_cache', stash);
}

function retrieve(key, subkey) {
    _clean();
    let stash = store.get('stats_cache', {});
    if (subkey) {
        key = `${key}_${subkey}`;
    }
    return (stash[key] || { data: null }).data;
}

function clear() {
    store.remove('stats_cache');
}

export default {
    cache: cache,
    retrieve: retrieve,
    clear: clear,
};
