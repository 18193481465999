<template>
    <div class="paper p-3">
        <h3>My Desjardins Agent Domain Support</h3>
        <div class="row">
            <div class="col-xs-12 col-sm-4 pt-3">
                <b>Our support staff</b> are here to help you in any way possible. Please let us know how we can serve you better!
            </div>
            <div class="col-xs-12 col-sm-4 pt-3">
                <h5>Get in Touch</h5>
                <b>Our website: </b><a href="https://mydadomain.com">mydadomain.com</a><br />
                <b>Our email: </b><a href="mailto:support@mydadomain.com">support@mydadomain.com</a><br />
                <b>Our phone: </b><a href="tel:18777721982">1-877-772-1982</a>                
            </div>
            <div class="col-xs-12 col-sm-4 pt-3">
                <h5>Support Hours</h5>
                <b>Monday-Friday: </b>8AM-5PM Central
            </div>
        </div>
    </div>
</template>

<script>
export default {};
</script>

<style>
</style>
