<template>
    <div>
        <div
            v-if="loading"
            class="d-flex justify-content-center align-items-center loading-spinner"
        >
            <Icon i="spinner" spin />&nbsp; Loading Payment Info...
        </div>
        <CreditCardForm
            v-if="editing_id"
            :id="selected_card_id"
            :isNew="showNew"
            :cards="cards"
            :loading="loading"
            :reload="fetchCardList"
            @edit="onEdit"
        />
        <PaymentEditor
            v-else
            :cards="cards"
            :loading="loading"
            :reload="fetchCardList"
            @edit="onEdit"
        />
    </div>
</template>

<script>
import PaymentList from '@/components/PaymentList.vue';
import CreditCardForm from '@/components/CreditCardForm.vue';

import _ from 'lodash';
import axios from 'axios';

export default {
    name: 'EmbeddedCCList',
    components: {
        PaymentEditor: PaymentList,
        CreditCardForm,
    },
    data: function() {
        return {
            loading: false,
            cards: [],
            editing_id: null,
        };
    },
    mounted() {
        this.fetchCardList();
    },
    computed: {
        showNew() {
            return this.editing_id == 'new';
        },
        selected_card_id() {
            if (this.editing_id) {
                return String(this.editing_id);
            }
        },
    },
    methods: {
        onEdit(card_id) {
            this.editing_id = card_id;
        },
        onData() {
            let good_card =
                this.cards.find((card) => {
                    return card.is_default && !card.expired;
                }) || null;
            this.loading = false;
            this.$emit('selected', good_card);
        },
        async fetchCardList() {
            this.loading = true;
            const { account_number } = this.$store.state.current_account;
            try {
                const { data } = await axios.get(
                    `/backend/api/frontend/accounts/${account_number}/payment/`,
                );

                this.cards = data;
                this.onData();
            } catch (error) {
                console.error(error);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
</style>
