<template>
    <div>
        <div v-if="logged_out" class="mx-auto">
            <h1 class="mt-5 display-4 text-uppercase text-muted">Logged Out</h1>
            <p class="text-muted">you have successfully logged out!</p>
            <p><router-link to="/login/">Click here to log back in</router-link></p>
        </div>
        <div v-else class="mx-auto">
            <Loading />
        </div>
    </div>
</template>
<script>
import Loading from '@/components/elements/Loading.vue';
import axios from 'axios';
export default {
    name: 'LoggedOut',
    components: {
        Loading,
    },
    data() {
        return {
            logged_out: false,
        };
    },
    created() {
        axios.get(`/backend/logout/`).then((resp) => {
            this.logged_out = true;
        });
    },
};
</script>
<style lang="scss" scoped></style>
