<template>
    <div>
        <!-- Saving -->
        <div v-if="form.saving">
            <p><Icon i="spinner" spin /> Processing...</p>
        </div>

        <!-- Deleting -->
        <div v-else-if="form.deleting">Are you sure you want to delete {{ redirect_address }}</div>

        <!-- Editing -->
        <div v-else class="form-row">
            <div class="form-group col-md-6 col-xs-12">
                <div class="input-group">
                    <input
                        type="text"
                        class="form-control"
                        id="redirect_address_base"
                        placeholder="something"
                        v-model="form.redirect_address_base"
                    />
                    <div class="input-group-append">
                        <span class="input-group-text" id="domain_addon">@{{ domain.name }}</span>
                    </div>
                </div>
            </div>
            <div class="form-group col-md-6 col-xs-12">
                <b-form-select v-model="form.real_address" :options="valid_recipients" />
            </div>
        </div>

        <!-- Buttons -->
        <div class="d-flex justify-content-end align-items-center flex-wrap">
            <b-button-group class="">
                <b-button v-if="form.deleting" variant="danger" @click="on_delete">
                    Yes, Delete
                </b-button>
                <b-button
                    v-else
                    variant="primary"
                    @click="show_bill ? (show_bill_window = true) : on_save(create)"
                    :disabled="save_disabled"
                >
                    Save
                </b-button>
                <b-button variant="outline-secondary" @click.stop="on_complete">
                    Cancel
                </b-button>
            </b-button-group>
        </div>

        <BillingChange
            action="buy_emails"
            :emails="1"
            :open="show_bill_window"
            @confirm="on_save"
            @close="show_bill_window = false"
        />
    </div>
</template>

<script>
import BillingChange from '@/components/BillingChange.vue';
import axios from 'axios';
import { mapGetters } from 'vuex';

export default {
    name: 'EmailRedirectEditForm',
    components: { BillingChange },
    props: {
        domain: Object,
        form: Object,
        save_disabled: Boolean,
        create: Boolean,
        on_complete: Function,
        load: Function,
    },
    data() {
        return {
            show_bill_window: false,
        };
    },
    computed: {
        ...mapGetters(['account']),
        redirect_address() {
            return `${this.form.redirect_address_base}@${this.domain.name}`;
        },
        valid_recipients() {
            const account = this.account;
            if (!account) {
                return [];
            }

            const staff_emails = account.agency.team
                .filter((item) => item.active)
                .map((item) => {
                    return item.email_address;
                });

            return [account.agency.agent.email_address].concat(staff_emails);
        },
        show_bill() {
            return this.domain.domain_type == 'parked' && this.create;
        },
    },
    methods: {
        handle_err(err, message) {
            console.log(err);
            this.form.saving = false;
            this.show_bill_window = false;
            this.$store.commit('add_alert', { message, varient: 'danger' });
        },
        on_save() {
            try {
                if (!this.form.redirect_address_base || !this.form.real_address) {
                    this.$store.commit('add_alert', {
                        message: 'must fill in redirect and destination addresses',
                        varient: 'warning',
                    });
                    return;
                }
                console.log('here');
                this.form.saving = true;
                console.log(this.form);
                const account_number = this.$store.state.current_account.account_number;
                const base_api_url = `/backend/api/frontend/accounts/${account_number}/emails/`;
                const bundle = {
                    redirect_address: this.form.redirect_address_base + '@' + this.domain.name,
                    real_address: this.form.real_address,
                    domain: this.domain.name,
                    account: account_number,
                };

                axios({
                    method: this.create ? 'post' : 'put',
                    data: bundle,
                    url: this.create ? base_api_url : `${base_api_url}${this.form.pk}/`,
                })
                    .then((resp) => {
                        this.show_bill_window = false;
                        return this.load();
                    })
                    .then(() => {
                        this.form.saving = false;
                        this.show_bill_window = false;
                        this.on_complete();
                    })
                    .catch((err) => {
                        this.handle_err(err, 'there was a problem saving the email redirect');
                    });
            } catch (err) {
                this.handle_err(err, 'there was a problem saving the email redirect');
            }
        },
        on_delete() {
            try {
                this.form.saving = true;
                const account_number = this.$store.state.current_account.account_number;
                const url = `/backend/api/frontend/accounts/${account_number}/emails/${this.form.pk}/`;

                axios({
                    method: 'delete',
                    url: url,
                })
                    .then(() => {
                        this.load();
                    })
                    .catch((err) => {
                        this.handle_err(err, 'there was a problem deleting the email redirect');
                    });
            } catch (err) {
                this.handle_err(err, 'there was a problem deleting the email redirect');
            }
        },
    },
};
</script>

<style></style>
