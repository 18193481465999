export default {
    get: [
        {
            mvt_id: 'ABC0001',
            full_name: 'Jennifer Pigeon',
            email: 'jennifer.m.pigeon.i5vm@statefarm.com',
            enabled: true,
        },
        {
            mvt_id: 'ABC0002',
            full_name: 'Raymond Monteiro',
            email: 'ray.monteiro.eo7n@statefarm.com',
            enabled: false,
        },
        {
            mvt_id: 'ABC0003',
            full_name: 'Sandra McKnight',
            email: 'sandy.mcknight.qpaa@statefarm.com',
            enabled: false,
        },
        {
            mvt_id: 'ABC0004',
            full_name: 'John Richard',
            email: 'john.richard.i35k@statefarm.com',
            enabled: false,
        },
    ],
};
