<template>
    <div class="paper text-left p-3 col-12 offset-lg-1 col-lg-10 mb-4">
        <div v-if="bundle" class="d-flex justify-content-between align-items-center">
            <div>
                Billing Status:
                <b>{{current_status}}</b>
            </div>
            <div>
                Next Bill:
                <b>{{next_billing}}</b>
            </div>
            <div>
                Current Rate:
                <b>${{current_rate}}</b>
            </div>

            <b-button
                :disabled="!allow_retry || retry_status==='loading'"
                @click="retry"
                v-b-tooltip.hover
                title="text describing what a retry does here"
                variant="primary"
            >
                <span v-if="retry_status=='loading'">
                    <Icon i="spinner" spin/>&nbsp;Retrying...
                </span>
                <span v-else>Retry</span>
            </b-button>

            <b-button
                center
                variant="outline-secondary"
                @click="show_transactions = !show_transactions"
            >{{ show_transactions ? "Hide" : "Show" }} Recent Transactions</b-button>
        </div>
        <div v-else>
            <Icon i="spinner" spin/>&nbsp;Loading...
        </div>

        <div v-if="bundle">
            <b-collapse v-model="show_transactions" id="transaction_collapse" class="mt-3">
                <b-list-group>
                    <b-list-group-item
                        v-for="t in transactions"
                        :key="t.id"
                        class="d-flex justify-content-between align-items-center"
                    >
                        Transaction of ${{t.amount}} on {{t.card_type}} card ending in {{t.last_four}} on {{t.created_at}}
                        <b-badge
                            :variant="t.success ? 'success' : 'danger'"
                            class="p-2"
                        >{{ t.success ? 'Succeeded' : 'Failed' }}</b-badge>
                    </b-list-group-item>
                </b-list-group>
            </b-collapse>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
export default {
    name: 'BillingStatus',
    data() {
        return {
            bundle: null,
            bundle_error: null,
            retry_status: null,
            show_transactions: false,
        };
    },
    mounted() {
        const account_number = this.$store.getters.account_number;
        axios
            .get(`/backend/api/frontend/accounts/${account_number}/statements/status/`)
            .then((resp) => this.bundle = resp.data.subscription);
    },
    computed: {
        next_billing() {
            if (this.bundle && this.bundle.next_billing) {
                return this.bundle.next_billing.slice(0, 10);
            }
            return null;
        },
        current_rate() {
            if (!this.bundle) {
                return null;
            }
            return Number(this.bundle.current_rate).toFixed(2);
        },
        allow_retry() {
            return this.bundle.status === 'Past Due';
        },
        transactions() {
            if (!this.bundle) {
                return null;
            }
            return this.bundle.recent_transactions.map((item) => {
                return {
                    card_type: item.card_type,
                    created_at: item.created_at.slice(0, 10),
                    amount: Number(item.amount).toFixed(2),
                    last_four: item.last_four,
                    success: item.success,
                    id: item.created_at,
                };
            });
        },
        current_status() {
            if (!this.bundle) {
                return null;
            }

            return this.bundle.status || this.bundle.error;
        }
    },
    methods: {
        retry() {
            const account_number = this.$store.getters.account_number;
            this.retry_status = 'loading';
            axios
                .post(`/backend/api/frontend/accounts/${account_number}/statements/retry/`)
                .then((resp) => {
                    if (resp.data.success) {
                        this.retry_status = 'success';
                    } else {
                        this.retry_status = 'failure';
                    }
                })
                .catch((err) => {
                    this.$store.commit('add_alert', {
                        message: 'there was a problem while retrying failed payment',
                        varient: 'danger',
                    });
                    console.log(err);
                });
        },
    },
};
</script>

<style>
</style>
