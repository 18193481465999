<template>
    <b-card
        class="card paper"
        header="Personal Notifications"
    >
        <div v-for="(alert, index) in alerts" v-bind:key="index">
            <b-alert
                :variant="alert.varient"
                :show="true"
            >
                {{ alert.message }}
            </b-alert>
        </div>
        <b-card-footer>
            <b-button variant="link"> see more </b-button>
        </b-card-footer>
    </b-card>
</template>

<script>
export default {
    data() {
        return {
            alerts: this.$store.state.alerts,
        };
    },
};
</script>

<style lang="scss" scoped>
.card {
    /* margin: 7% 2%; */
    position: fixed;
    top: 60px;
    right: 10px;
    z-index: 9999;

    .card-body {
        padding: 0px;

        .alert {
            border-radius: 0;
            margin-bottom: 4px;
        }
    }

    .card-footer {
        padding: 0px;
    }
}
</style>
