var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"paper page_main_col col-12 offset-lg-1 col-lg-10 mb-2"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xs-12 col-sm-6"},[_c('h3',{staticClass:"text-center"},[_vm._v("Select Account")]),_c('input',{staticClass:"form-control",attrs:{"disabled":_vm.final_countdown,"aria-describedby":"basic-addon2","aria-label":"Search Accounts","placeholder":"Search Accounts","type":"text"},domProps:{"value":_vm.filter_string},on:{"input":_vm.onFilter,"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.onReturn.apply(null, arguments)}}}),(!_vm.available_accounts)?_c('div',{staticClass:"my-2 text-center"},[_c('Icon',{attrs:{"i":"spinner","spin":""}}),_vm._v("loading available accounts ")],1):_vm._e(),(_vm.final_countdown)?_c('div',{staticClass:"my-2"},[_c('Icon',{attrs:{"i":"spinner","spin":""}}),_vm._v("logging into selected account, one moment! ")],1):_vm._e(),_c('div',{staticClass:"list-group",attrs:{"id":"select-account-list"}},[_vm._l((_vm.filtered_list),function(account){return _c('button',{key:account.number,class:[
                        'list-group-item',
                        'list-group-item-action',
                        { disabled: _vm.final_countdown },
                    ],on:{"click":function($event){return _vm.onSet(account.number)}}},[_c('div',{staticClass:"row"},[_c('span',{staticClass:"col"},[_vm._v(_vm._s(account.full_name))]),_c('span',{staticClass:"col"},[_vm._v(_vm._s(account.municipality)+", "+_vm._s(account.province))]),_c('span',{staticClass:"col text-right"},[_vm._v(_vm._s(account.agent_code)+" ("+_vm._s(account.agency_type)+")")])])])}),(_vm.no_accounts)?_c('div',{staticClass:"list-group-item list-group-item--action disabled"},[_c('i',[_vm._v("no accounts to select")])]):_vm._e()],2)]),_c('div',{staticClass:"col-xs-12 col-sm-6"},[_c('h3',{staticClass:"text-center"},[_vm._v("Recent Accounts")]),(!_vm.available_accounts)?_c('div',{staticClass:"my-2 text-center"},[_c('Icon',{attrs:{"i":"spinner","spin":""}}),_vm._v("waiting for available accounts ")],1):_vm._e(),_c('div',{staticClass:"list-group d-flex justify-content-between"},[_vm._l((_vm.recent_list),function(account){return _c('button',{key:account.number,class:[
                        'list-group-item',
                        'list-group-item-action',
                        { disabled: _vm.final_countdown },
                    ],on:{"click":function($event){return _vm.onSet(account.number)}}},[_c('div',{staticClass:"row"},[_c('span',{staticClass:"col"},[_vm._v(_vm._s(account.full_name))]),_c('span',{staticClass:"col"},[_vm._v(_vm._s(account.municipality)+", "+_vm._s(account.province))]),_c('span',{staticClass:"col text-right"},[_vm._v(_vm._s(account.agent_code)+" ("+_vm._s(account.agency_type)+")")])])])}),(_vm.no_recent)?_c('div',{staticClass:"list-group-item list-group-item--action disabled"},[_c('i',[_vm._v("no recently selected accounts")])]):_vm._e()],2)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }