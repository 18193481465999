<template>
    <div>
        <div class="paper page_main_col col-12 offset-lg-1 col-lg-10">
            <h3>Create an Agency Intern</h3>
            <b-alert show variant="light" class="text-left">
                Agency Interns are agencies that have not yet appeared in the the AMIT feed, but the
                to-be agents wish to begin setting up their services with us. The intern agency will
                automatically be converted into a real one once one with the same mvt_id appears in
                the AMIT feed.
            </b-alert>
            <div class="regex-edit-form">
                <!-- Agent full Name  -->
                <b-form-group
                    label-cols-lg="3"
                    label="Name"
                    label-size="lg"
                    label-class="font-weight-bold pt-0"
                    class="mb-0"
                >
                    <b-form-group
                        label-cols-sm="3"
                        label="First:"
                        label-align-sm="right"
                        label-for="intern-first-name"
                    >
                        <b-form-input id="intern-first-name" v-model="form.name.first" required />
                    </b-form-group>

                    <b-form-group
                        label-cols-sm="3"
                        label="Middle:"
                        label-align-sm="right"
                        label-for="intern-middle-name"
                        description="(can be initial, not required)"
                    >
                        <b-form-input id="intern-first-name" v-model="form.name.middle" />
                    </b-form-group>

                    <b-form-group
                        label-cols-sm="3"
                        label="Last:"
                        label-align-sm="right"
                        label-for="last-name"
                    >
                        <b-form-input id="last-name" v-model="form.name.last" required />
                    </b-form-group>
                </b-form-group>
                <hr />

                <!-- Office location -->
                <b-form-group
                    label-cols-lg="3"
                    label="Office Address"
                    label-size="lg"
                    label-class="font-weight-bold pt-0"
                    class="mb-0"
                >
                    <b-form-group
                        label-cols-sm="3"
                        label="Street:"
                        label-align-sm="right"
                        label-for="intern-street1"
                    >
                        <b-form-input id="intern-street1" v-model="form.address.street1" required />
                    </b-form-group>

                    <b-form-group
                        label-cols-sm="3"
                        label="Street 2:"
                        label-align-sm="right"
                        label-for="intern-street2"
                        description="(not require)"
                    >
                        <b-form-input id="intern-street2" v-model="form.address.street2" />
                    </b-form-group>

                    <b-form-group
                        label-cols-sm="3"
                        label="City:"
                        label-align-sm="right"
                        label-for="intern-city"
                    >
                        <b-form-input id="intern-city" v-model="form.address.city" required />
                    </b-form-group>

                    <b-form-group
                        label-cols-sm="3"
                        label="province:"
                        label-align-sm="right"
                        label-for="intern-province"
                        description="the Canadian postal abbreviations for provinces and territories in all caps. For example 'ON' for ontario"
                    >
                        <b-form-input
                            id="intern-province"
                            v-model="form.address.province"
                            required
                        />
                    </b-form-group>

                    <b-form-group
                        label-cols-sm="3"
                        label="Municipality/City:"
                        label-align-sm="right"
                        label-for="intern-municipality"
                        description="examples: Dundas, Windsor, Toronto"
                    >
                        <b-form-input
                            id="intern-municipality"
                            v-model="form.address.municipality"
                            required
                        />
                    </b-form-group>

                    <b-form-group
                        label-cols-sm="3"
                        label="Postal Code:"
                        label-align-sm="right"
                        label-for="intern-postal-code"
                    >
                        <b-form-input
                            id="intern-postal-code"
                            v-model="form.address.postal_code"
                            required
                        />
                    </b-form-group>
                </b-form-group>

                <hr />
                <b-form-group id="inputGroup2" label="Email:" label-for="intern-email">
                    <b-form-input id="intern-email" type="text" v-model="form.email" required />
                </b-form-group>

                <b-form-group
                    id="inputGroup2"
                    label="Phone Number:"
                    label-for="intern-phone-number"
                    description="please use dashes. For example '123-456-7890'"
                >
                    <b-form-input
                        id="intern-phone-number"
                        type="text"
                        v-model="form.phone"
                        required
                    />
                </b-form-group>

                <b-form-group id="inputGroup2" label="MVT ID:" label-for="intern-mvt-id">
                    <b-form-input id="intern-mvt-id" type="text" v-model="form.mvt_id" required />
                </b-form-group>

                <b-button
                    :disabled="saving || !formComplete"
                    type="submit"
                    variant="primary"
                    @click="submit"
                >
                    {{ saving ? 'Saving...' : 'Submit' }}
                </b-button>
                <b-button :disabled="saving" type="reset" variant="danger" @click="resetForm">
                    Reset
                </b-button>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import { cloneDeep, get, isNil } from 'lodash';

export default {
    name: 'AgencyIntern',
    data() {
        return {
            bread: [
                { text: 'Dashboard', to: '/' },
                { text: 'Admin', active: true },
                { text: 'Agency Interns Form', to: '/admin/agencyinterns', active: true },
            ],
            emptyForm: {
                name: { firt: null, middle: null, last: null },
                address: {
                    street1: null,
                    street2: null,
                    city: null,
                    province: null,
                    municipality: null,
                    postal_code: null,
                },
                email: null,
                phone: null,
                mvt_id: null,
            },
            form: null,
            saving: false,
        };
    },
    computed: {
        formComplete() {
            return (
                !isNil(get(this.form, 'name.first')) &&
                !isNil(get(this.form, 'name.last')) &&
                !isNil(get(this.form, 'address.street1')) &&
                !isNil(get(this.form, 'address.city')) &&
                !isNil(get(this.form, 'address.province')) &&
                !isNil(get(this.form, 'address.municipality')) &&
                !isNil(get(this.form, 'address.postal_code')) &&
                !isNil(get(this.form, 'email')) &&
                !isNil(get(this.form, 'phone')) &&
                !isNil(get(this.form, 'mvt_id'))
            );
        },
    },
    methods: {
        async submit() {
            try {
                this.saving = true;
                await axios.post('/backend/api/frontend/admin/agencyintern/', this.form);
                this.$store.commit('add_alert', {
                    message: 'agent intern created!',
                    varient: 'success',
                });
                this.resetForm();
            } catch (error) {
                let message = 'there was a problem creating the agent intern';
                if (error.response) {
                    message = error.response.data;
                }

                this.$store.commit('add_alert', {
                    message,
                    varient: 'danger',
                });
                console.log(error);
            }
            this.saving = false;
        },
        resetForm() {
            this.form = cloneDeep(this.emptyForm);
        },
    },
    created() {
        this.resetForm();
    },
};
</script>

<style lang="scss" scoped>
h3 {
    text-align: left;
    padding: 15px;
}

.regex-edit-form {
    text-align: left;
    padding: 10px;
    margin-bottom: 10px;
}
</style>
