import { render, staticRenderFns } from "./FourOhFour.vue?vue&type=template&id=0ea3dd26&scoped=true&"
import script from "./FourOhFour.vue?vue&type=script&lang=js&"
export * from "./FourOhFour.vue?vue&type=script&lang=js&"
import style0 from "./FourOhFour.vue?vue&type=style&index=0&id=0ea3dd26&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0ea3dd26",
  null
  
)

export default component.exports