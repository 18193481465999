import { register } from 'register-service-worker';
import EventEmitter from 'events';
const POLLING_FREQUENCY = 1000 * 60 * 5;

class NewVersionChecker extends EventEmitter {
    constructor() {
        super();
        this.waitingWorker = undefined;
    }
    update() {
        if (this.waitingWorker) {
            this.waitingWorker.postMessage({
                type: 'skip_waiting',
            });
            this.waitingWorker = undefined;
            location.reload();
        }
    }
}

const newVersionChecker = new NewVersionChecker();

if (process.env.NODE_ENV !== 'development' || process.env.NODE_ENV === 'CI-TEST') {
    register('/service-worker.js', {
        registrationOptions: { scope: '/' },
        ready() {
            newVersionChecker.emit('ready');
        },
        registered() {
            newVersionChecker.emit('registered');
            setInterval(() => {
                const controller = navigator.serviceWorker.controller;
                controller && controller.postMessage({ type: 'check_for_updates' });
            }, POLLING_FREQUENCY);
        },
        cached() {
            newVersionChecker.emit('cached');
        },
        updatefound() {
            newVersionChecker.emit('update_found');
        },
        updated(registration) {
            newVersionChecker.waitingWorker = registration.waiting;
            newVersionChecker.emit('updated');
        },
        offline() {
            newVersionChecker.emit('offline');
        },
        error(error) {
            newVersionChecker.emit('error', error);
        },
    });
}

export default newVersionChecker;
